import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Alert, Avatar, Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from '@mui/material';
import { useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/authentication.service';
import Appbar from '../appbar';
import Footer from '../footer';


export default function ConfirmPasswordRecovery() {
  const navigate = useNavigate();
  const location =useLocation();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [codeErrorMessage, setCodeErrorMessage] = useState('');
  const [newCodeErrorMessage, setNewCodeErrorMessage] = useState('');

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const isValidDigit = (digit) => {
    return /\b\d{5}\b/g.test(digit);
  };
  //<input onChange=(event => handleChange(event))>
  const handleChange = (e) => {
    setErrorMessage('');
    setCodeValue(e.target.value);
  };

  const reSendCode =()=>{
    setErrorMessage('');
    setNewCodeErrorMessage("Le code est expiré. Veuillez générer un autre.");
    setCodeErrorMessage('')
  }

  const handleConfirmation = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setNewCodeErrorMessage('');
    setErrorMessage('');
    //validation
    if (!isValidDigit(codeValue)) {
      setCodeErrorMessage("Code à cinq chiffres est requis");

      return;
    }
    setLoading(true);
    AuthenticationService.passwordConfirmation(codeValue)
      .then(response => {
        //set user in session.
    
        navigate("/passwordRecover",{
          state:{
           email:response?.data
          }
         });
      }).catch((error) => {
        if (error?.response?.status === 404) {
          setErrorMessage("Il n'y a pas de compte associé à ce code.");
        } 
        setLoading(false);
      });
  };

  const handleGenerateNewCode = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setErrorMessage('');
    setNewCodeErrorMessage('');
    //validation

    setLoading(true);
    AuthenticationService.generateNewCode(location.state.id,'PasswordRecovery')
      .then(() => {
        //set user in session.
        setNewCodeErrorMessage('');
        setSubmitted(false);
        setLoading(false);
        setCodeValue('');
      
      })
      .catch((error) => {
        navigate("/404");
        setLoading(false);
      });
  };
  return (
    <>
      <Appbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mb: 40,
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOpenIcon />
          </Avatar>
          <Typography align="center" component="h1" variant="h5">
          Validation du Mot de Passe
          </Typography>
          {!newCodeErrorMessage && 
          <Typography mt={1} align="center"  variant="body2">
          Entrez le code qui vous a été envoyé à votre email {location.state.id}
          </Typography>}
          {newCodeErrorMessage && 
          <Typography mt={2} mb={2} align="center"  variant="body2">
          Le nouveau code vous sera envoyé à votre email {location.state.id}
          </Typography>}
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      
          <Box
            component="form"
            noValidate
           
            className={submitted ? 'was-validated' : ''}
            sx={{ mt: 3 }}
          >
            {!newCodeErrorMessage && 
            <Grid container spacing={2}>
            
              <Grid item xs={12}>
                <TextField
                  error={!isValidDigit(codeValue) ? true : false}
                  required
                  fullWidth
                  id="email"
                  value={codeValue}
                  label="Code"
                  name="code"
                
                  onChange={(e) => handleChange(e)}
                  helperText={!isValidDigit(codeValue) && codeErrorMessage}
                />
              </Grid>
              <Grid item xs={12}>
              <Button
               onClick={(event) => handleConfirmation(event)}
              fullWidth
              variant="contained"
             disabled={loading}
              sx={{  borderRadius: '10px' }}
              color="primary"
            >
              Confirmer
            </Button>
              </Grid>
              <Grid item xs={12}>
              <Link component="button" variant="body1" onClick={reSendCode}>
              Je n'ai pas reçu de code
                </Link>
              
              </Grid>
          
            </Grid>}
            {newCodeErrorMessage && 
       
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <Button
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{  borderRadius: '10px' }}
              color="primary"
              onClick={(event) => handleGenerateNewCode(event)}
            >
              Renvoyer le Code
            </Button>
              </Grid>
            </Grid>}
            
          </Box>
        
        </Box>
      </Container>

      <Footer />
    </>
  );
}
