import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../footer';
import Appbar from '../appbar';
import Person from '../../models/person';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/authentication.service';
import Alert from '@mui/material/Alert';
import { setCurrentPerson } from '../../store/actions/personAction';
import { toast } from "react-toastify";


export default function Login() {
  const [person, setPerson] = useState(new Person('', ''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passErrorMessage, setPassErrorMessage] = useState('');
  const currentPerson = useSelector((state) => state.person);



  const navigate = useNavigate();
  const dispatch = useDispatch();

  //mounted
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if (currentPerson?.id) {
      navigate('/profile');
    }
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  //<input onChange=(event => handleChange(event))>
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPerson((prevState) => {
      //E.g: prevState ({user: abc, pass: abc}) + newKeyValue ({user: abcd}) => ({user: abcd, pass: abc})
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setSubmitted(true);
    //validation
    if (!isValidEmail(person.email) || !person.password) {
      setEmailErrorMessage("L'addresse email est requis");
      setPassErrorMessage('Le mot de passe est requis');
      return;
    }
    setLoading(true);
    AuthenticationService.login(person)
      .then((response) => {
        //set user in session.
        dispatch(setCurrentPerson(response.data));
          navigate('/profile')
      })
      .catch((error) => {
      
        if (error?.response?.status === 401) {
          setErrorMessage("L'addresse email ou le mot de passe n'est pas valide.");
        } else if (error?.response?.status === 423) {
          toast.error("Ce compte n'a été pas validé. Veuillez le recréer.", {
            position: "top-right", autoClose:5000
          });
          navigate("/register")
        } else if (error?.response?.status === 404) {
          setErrorMessage("Il n'y a pas de compte associé à cette email.");
        } else {
          navigate('/400');
        }
        setLoading(false);
      });
  };

  return (
    <>
      <Appbar />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mb: 40,
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{m: 1, bgcolor:'secondary.main'}}>
            <LockOpenIcon />
          </Avatar>
          <Typography align="center" component="h1" variant="h5">
            Connectéz Vous à 237usa
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Box
            component="form"
            noValidate
            onSubmit={(event) => handleLogin(event)}
            className={submitted ? 'was-validated' : ''}
            sx={{mt: 3}}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={!isValidEmail(person.email) ? true : false}
                  required
                  fullWidth
                  id="email"
                  value={person.email}
                  label="Adresse E-mail"
                  name="email"
                  autoComplete="email"
                  //autoFocus
                  onChange={(e) => handleChange(e)}
                  helperText={!isValidEmail(person.email) && emailErrorMessage}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error={!person.password ? true : false}
                  required
                  fullWidth
                  name="password"
                  value={person.password}
                  label="Mot de Passe"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => handleChange(e)}
                  helperText={!person.password && passErrorMessage}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2, borderRadius: '10px' }}
              color="primary"
            >
              Se Connecter
            </Button>

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={6}>
                <NavLink variant="body1" to="/passwordRecovery">
                  Mot de passe oublié?
                </NavLink>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NavLink variant="body1" to="/register">
                  Nouveau client? S'inscrire
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Footer />
    </>
  );
}
