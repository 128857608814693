import { styled } from '@mui/material/styles';

import {
  Box,
  Paper,
  Stack,
} from '@mui/material';
import { Colors } from '../theme';
import '@fontsource/aladin';

export const HomePanelContainer = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  padding: '0px 0px',
}));

export const HomePanelSubContainer = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignContent:"center",
  width: '90%',
  height: '90%',

  [theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '95%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent:"center",
    
  },
  [theme.breakpoints.down('md')]: {
    width: '95%',
    height: '95%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent:"center",
  },

}));
export const HomePanelContainerInner = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
  alignContent:"center",
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  padding: '10px 10px',

  [theme.breakpoints.down('md')]: {
    padding: '10px 0px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '10px 0px',
  },
}));


export const HomePanelCardImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  height: "350px",

  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: "250px",
  },

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "250px",
  },
}));

