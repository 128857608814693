import { styled } from '@mui/material/styles';
import {
  Box,
  Chip,
 
  CardHeader,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Colors } from '../theme';

import '@fontsource/aladin';

export const InnerMenuContainer = styled(Box)(({ matches, theme }) => ({
 display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
alignContent: 'center',
alignItems:"center",
  background: Colors.white,
  width: '100%',
  height: '100%',
  padding: '0px 0px',

  marginTop: '20px',


}));
export const CarouselContainer = styled(Box)(({ matches, theme }) => ({

   justifyContent: 'center',
   flexDirection: 'column',
 alignContent: 'center',
 alignItems:"center",
   background: Colors.white,
   width: '100%',
   height: '100%',
   padding: '0px 0px',

  // marginTop: '20px',
 
 
 }));










export const CategoryImage = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  borderRadius: '10%',
 width: '95%',
  height: '220px',

  [theme.breakpoints.down('md')]: {

    width: '80%',
    height: '80px',
    //flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '130px',
  },

}));
export const NewImage = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  width: '100%',
  height: '150px',
  cursor:'pointer',
  [theme.breakpoints.down('md')]: {
   // width: '160px',
   width: '100%',
    height: '160px',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  [theme.breakpoints.down('sm')]: {
   width: '100%',
    height: '120px',
    
  },
}));



export const InnerMenuContent2 = styled(Box)(({ theme }) => ({
 display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '10px',
  
}));



export const InnerMenuContent5 = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '60%',
 // height: '150px',
  height: '60%',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '150px',
    height: '150px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '110px',
    height: '110px',
  },
}));





export const CategoryTitle = styled(Typography)(({ matches, theme }) => ({
fontSize: '20px',
//lineHeight: 1.5,
 //fontFamily: '"Aladin", "cursive"',

 [theme.breakpoints.down('sm')]: {
  fontSize: '15px',    
},
 
}));
export const CategoryCenterTitle = styled(Typography)(({ matches, theme }) => ({
  fontSize: '20px',
  //lineHeight: 1.5,
   //fontFamily: '"Aladin", "cursive"',
  
   [theme.breakpoints.down('sm')]: {
    fontSize: '15px',    
  },
   
  }));



export const InnerMenuDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: '3em',
  [theme.breakpoints.down('md')]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: '1.5em',
  },
}));
export const InnerMenuDescription2 = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: '1em',
  //marginTop: '1em',
  marginLeft: '1em',
  //alignItems: "center",
  //justifyContent: "center",
  [theme.breakpoints.down('md')]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    //marginBottom: "1.5em",
  },
}));
export const InnerMenuDescription3 = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  //marginBottom: '1em',
  //marginTop: '1em',
  // marginRight: "1em",

  alignItems: 'right',
  justifyContent: 'right',
  [theme.breakpoints.down('md')]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: '1.5em',
  },
}));

export const InnerMenuChip = styled(Chip)(({ matches, theme }) => ({
  lineHeight: 1,
  fontSize: '25px',
  fontFamily: '"Aladin", "cursive"',
  background: Colors.primary,
  width: '300px',
  padding: 10,
  component: 'a',
  href: '#basic-chip',
  variant: 'outlined',
  // label:"primary",
  color: Colors.white,
  // justifyContent: 'center',
  marginTop: '1px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '15px',
    // marginBottom: '1px',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '25px',
    marginBottom: '1px',
  },
}));

export const InnerMenuContainerCard = styled(Card)(({ matches, theme }) => ({
  flexDirection: 'column',
  justifyContent: 'center',
}));

export const InnerMenuCardActions = styled(CardActions)(
  ({ matches, theme }) => ({})
);

export const InnerMenuCardContent = styled(CardContent)(
  ({ matches, theme }) => ({})
);

export const InnerMenuCardMedia = styled(CardMedia)(({ src, theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '280px',
  },
  [theme.breakpoints.down('md')]: {
    height: '140px',
  },
}));

export const InnerMenuCardHeader = styled(CardHeader)(
  ({ matches, ex }) => ({})
);
