import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from '../footer';
import Appbar from '../appbar';
import Person from '../../models/person';
import { useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../../services/authentication.service';
import Alert from '@mui/material/Alert';


export default function Register() {
  const [person, setPerson] = useState(new Person('', '', '', '','','',''));
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [fNameErrorMessage, setFNameErrorMessage] = useState('');
  const [lNameErrorMessage, setLNameErrorMessage] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passErrorMessage, setPassErrorMessage] = useState('');
 // const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const currentPerson = useSelector((state) => state.person);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  //mounted
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    if (currentPerson?.id) {
      navigate('/profile');
    }
  }, [currentPerson,navigate]);

  const isValidEmail =(email) =>{
    return /\S+@\S+\.\S+/.test(email);
  }
/*
  function isValidPassword(password) {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    return strongRegex.test(password);
  }
  */

  function isValidPassword(password) {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})'
    );
    return strongRegex.test(password);
  }

  const passwordbBulletPointMsg =() =>{
    return (
      <ul>
        <li>6 à 100 caractères</li>
        <li>Lettres majuscules et minuscules</li>
        <li>Au moins un chiffre</li>
      </ul>
    );
  }

  const handleChangeNewsLetter = (e) => {
    //check if user checked the promo box
    setChecked(e.target.checked);

  }

  const handleChange = (e) => {
    //get user input data
    const { name, value } = e.target;
    setPerson((prevState) => {
      //E.g: prevState ({user: abc, pass: abc}) + newKeyValue ({user: abcd}) => ({user: abcd, pass: abc})
      return {
        ...prevState,
        [name]: value,
     
      };
    });
  };

  //sumbit the request to server via axios POST
  const handleRegister = (e) => {
    e.preventDefault();
    setSubmitted(true);
    person.role="ROLE_USER"
    person.newsLetter=checked;

    //validation
    if (
      !person.lastName ||
      !person.firstName ||
      !isValidEmail(person.email) ||
      !isValidPassword(person.password)
    ) {
      setLNameErrorMessage('Le nom est requis');
      setFNameErrorMessage('Le prénom est requis');
      
      setPassErrorMessage(passwordbBulletPointMsg());
      setEmailErrorMessage("L'addresse email est requis");
      return;
    }
 
    setLoading(true);

   //code here
   AuthenticationService.register(person).then((_) => {
    navigate("/confirmRegistration",{
      state:{
       id:person.email
      }
     });
  })
  .catch((error) => {
   
    if (error?.response?.status === 409) {
      setErrorMessage("Cette adresse email existe déja.");
    } else {
      navigate('/404');
    }
    setLoading(false);
  });
  };

  return (
    <>
      <Appbar />

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mb: 40,
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <HowToRegIcon />
          </Avatar>
          <Typography align='center' component="h1" variant="h5">
          Créez Votre Compte 237usa
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Box
            component="form"
            noValidate
            onSubmit={(event) => handleRegister(event)}
            className={submitted ? 'was-validated' : ''}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  error={!person.lastName ? true : false}
                  required
                  fullWidth
                  id="lastName"
                  value={person.lastName}
                  label="Nom"
                  name="lastName"
                  autoComplete="family-name"
                  //autoFocus
                  onChange={(e) => handleChange(e)}
                  helperText={!person.lastName && lNameErrorMessage}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  error={!person.firstName ? true : false}
                  autoComplete="given-name"
                  name="firstName"
                  value={person.firstName}
                  required
                  fullWidth
                  id="firstName"
                  label="Prénom"
                  onChange={(e) => handleChange(e)}
                  helperText={!person.firstName && fNameErrorMessage}
                />
              </Grid>
            
              <Grid item xs={12}>
                <TextField
                  error={!isValidEmail(person.email) ? true : false}
                  required
                  fullWidth
                  id="email"
                  value={person.email}
                  label="Adresse E-mail"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => handleChange(e)}
                  helperText={!isValidEmail(person.email) && emailErrorMessage}
                />
              </Grid>
         

              <Grid item xs={12}>
                <TextField
                  error={!isValidPassword(person.password) ? true : false}
                  required
                  fullWidth
                  name="password"
                  value={person.password}
                  label="Mot de Passe"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  onChange={(e) => handleChange(e)}
                  helperText={
                    !isValidPassword(person.password) && passErrorMessage
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox id="isNewsLetter" name="isNewsLetter" onChange={(e) => handleChangeNewsLetter(e)}     color="primary" />
                  }
                  label="Je souhaite recevoir de l'inspiration, des promotions marketing et des mises à jour par e-mail."
                />
           
              </Grid>
              
            </Grid>

            
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2, borderRadius: '10px' }}
              color="primary"
            >
              Créer mon compte
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link  variant="body1" component={RouterLink} to="/login">
                  Vous avez déjà un compte? S'identifier
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>

      <Footer />
    </>
  );
}
