import { Box, FormControl, Container, CssBaseline,NativeSelect, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import Appbar from '../appbar';

import Footer from '../footer';
import Products from '../products';

import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../services/product.service';

export default function DisplayProduct() {
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);
  const[msg,setMsg] = useState();
  const[testMsg,setTestMsg] = useState();
 
  const theme = useTheme();

  const {state} = useLocation();
  const {cat,sub,subSub,nameProd,title,toutCate,unique} = state; 


  const onTop = () => {
    window.scrollTo(0, 0);
  }
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [selected, setSelected] = useState('');

  useEffect(() => {
    onTop();
  if(nameProd){
    ProductService.listProductCatAndSubAndName(cat,sub,nameProd).then(response => {
      setProductOutput(response?.data);
    })
    .catch((error) => {
      navigate("/404")
      
    });
   
  }else if(toutCate){
    if(toutCate==="bien-etre"){
      setMsg("none")
      setTestMsg('Cette page est en cour de construction.')
    }else{
      setMsg('')
setTestMsg('')
      ProductService.listProductCategory(toutCate).then(response => {
      setProductOutput(response?.data);
    })
    .catch((error) => {
      navigate("/404")
    });
  }
  }else if(unique){

  }else if(cat && sub){
   if(cat==="bien-etre"){
setMsg("none")
setTestMsg('Cette page est en cour de construction.')
   }else{
    setMsg('')
    setTestMsg('')
    ProductService.listProductCatAndSub(cat,sub).then(response => {
      setProductOutput(response?.data);
    })
    .catch((error) => {
      navigate("/404")
    });
   }
   
  }else if(cat && sub && subSub){
    setMsg('')
    setTestMsg('')
    ProductService.listProductCatAndSubAndSubSub(cat,sub,subSub).then(response => {
      setProductOutput(response?.data);
    })
    .catch(() => {
      navigate("/404")
    });
  }
  if(selected==='10'){
 productOutput.sort((a, b) => {
  let fa = a.productName.toLowerCase(),
      fb = b.productName.toLowerCase();

  if (fa < fb) {
      return -1;
  }
  if (fa > fb) {
      return 1;
  }
  return 0;
});

setProduct(productOutput);

  } else if (selected==='20'){
productOutput.sort((a, b) => (a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1));

setProduct(productOutput);

}  else if (selected==='30'){
  
productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

setProduct(productOutput);


} else if(selected==='40'){

productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

setProduct(productOutput);


} 
   
  }, [state,selected]);

  return (
    <>
      <Appbar />
      <Box display='flex' mt={3}  mb={3}  justifyContent='center' alignItems='center' >
        <Typography align='center' fontWeight={600} variant='h5'>{title}  </Typography>
      </Box>
    
      {!msg &&
       <Container component="main" maxWidth="xs">
       <CssBaseline />
      <Box  display='flex' mt={4}  mb={5}  justifyContent='center' alignItems='center' >        
      <FormControl fullWidth>
  <NativeSelect
value={selected}
onChange={(event) => setSelected(event.target.value)}
   
  >
    <option   value=''>Trier Par: Fonctionnalités</option>
    <option  value={10}>Marque : A à Z</option  >
    <option  value={20}>Marque : Z à A</option  >
    <option   value={30}>Prix : Du plus bas au plus élevé</option  >
    <option   value={40}>Prix : Du plus élevé au plus bas</option  >
  </NativeSelect>
</FormControl>

                  </Box>
                  </Container>}
                
    
   {msg && <Box display='flex' mt={5}  mb={5}  justifyContent='center' alignItems='center' >
        <Typography align='center' fontWeight={600} variant='h5'>{testMsg}</Typography>
      </Box>}
      {!selected &&  <Products products={productOutput} />}
      {selected &&  <Products products={product} />}


   
      {!msg &&  <Footer  /> }
 
    </>
  );
}
