
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import useDialogModal from '../../hooks/useDialogModal';
import {
  Product,
  ProductImage
} from '../../styles/product';
import ProductDetail from '../productDetail';
import ProductMeta from './ProductMeta';

import {  getTotals } from '../../slices/cartSlice';

import { useDispatch, useSelector } from 'react-redux';




export default function SingleProductDesktop({ product, matches }) {
  const dispatch = useDispatch();

  const [showOptions, setShowOptions] = useState(false);
  const cart = useSelector((state) => state.cart);

 

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);





  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(ProductDetail);

  const handleMouseEnter = () => {
    setShowOptions(true);
  };
  const handleMouseLeave = () => {
    setShowOptions(false);
  };


  return (
    <>
      <Box>
        <Product
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ProductImage
            src={'/images/products/' + product.productImageUrl}
           
            onClick={() => showProductDetailDialog()}
            alt={product.productImageUrl}
          />
        </Product>
        <ProductMeta product={product} />
      </Box>
      <ProductDetailDialog product={product} />
    </>
  );
}
