import styled from "@emotion/styled";
import { Button, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { slideInBottom, slideInRight } from "../../animation";
import { Colors } from "../theme";

export const Product = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",


 

  [theme.breakpoints.up("md")]: {
    position: "relative",
  },
  [theme.breakpoints.up("sm")]: {
    position: "relative",
  },
  
}));

export const ProductImageDetail = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "50%",
}));

export const ProductImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,

  height: 350, 
  width: "100%", 

  [theme.breakpoints.down("md")]: {
    width: "100%", 
   // padding: '24px',
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%", 
   height: '100%', 
   //padding: '1px',
  },
}));

export const ProductImageUnique = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  width: "100%",
  height: '100%', 
 // borderRadius: '10px',
  //background: Colors.light_gray,
 // borderRadius: '10px', 
 // padding: '10px',
  [theme.breakpoints.down("md")]: {
    width: "100%",
    height: '100%', 
   // padding: '24px',
    
  },
  [theme.breakpoints.down("sm")]: {
   //background: Colors.light_gray,
   width: '100%', 
   height: '100%', 
   //padding: '1px',
  },


}));

export const ProductImageMobile = styled("img")(({ src }) => ({
  src: `url(${src})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "280px",
  height: "320px"
}));

export const ProductActionButton = styled(IconButton)(() => ({
  background: Colors.white,
  margin: 4,
}));

export const ProductFavButton = styled(ProductActionButton)(({ isfav, theme }) => ({
  color: isfav ? Colors.primary : Colors.light,  
  [theme.breakpoints.up("md")]: {
    position: "absolute",
    right: 0,
    top: 0,
  },
}));

export const ProductAddToCart = styled(Button, {
  shouldForwardProp: (prop) => prop !== "show",
})(({ show, theme }) => ({
  width: "120px",
  fontSize: "12px",
  marginBottom:"1em",
  [theme.breakpoints.up("md")]: {
    position: "absolute",    
    bottom: "2%",
    width: "300px",
    borderRadius: '10px',
    padding: "10px 5px",
    
    animation:
      show &&
      `${slideInBottom} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  },

  background: Colors.primary,
  opacity: 0.9,
}));

export const ProductMetaWrapper = styled(Box)(({theme}) => ({
 // width: "98%",
 
  padding: 4,
  display: "flex",
  flexDirection: "column",
  //alignItems: "center",

}));

export const ProductActionsWrapper = styled(Box)(({ show, theme }) => ({ 
  [theme.breakpoints.up("md")]: {
    display: show ? 'visible' : 'none',
    position: "absolute",
    right: 0,
  
    top: '20%',
    animation: show && `${slideInRight} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both`,
  }
}));
