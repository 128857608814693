import { CLEAR_CURRENT_PERSON_PASSWORD,SET_CURRENT_PERSON_PASSWORD } from './action-types/password-recover-action'

export const setCurrentPersonPassword = (personPassword) => {
    return {
        type: SET_CURRENT_PERSON_PASSWORD,
        payload: personPassword,
    };
};
export const clearCurrentPersonPassword = () => {
    return {
        type: CLEAR_CURRENT_PERSON_PASSWORD,
    };
};