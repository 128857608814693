import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';


const BASE_URL = BASE_API_URL + '/api/product';

const API_CAT_SUB_PRODUCT = BASE_URL + '/api/find-cate-sub?';
const API_CAT_SUB_SUBSUB_PRODUCT = BASE_URL + '/api/find-cate-sub-subSub?';
const API_CAT_SUB_NAME_PRODUCT = BASE_URL + '/api/find-cate-sub-name?';
const API_CAT_PRODUCT = BASE_URL + '/api/find-cate?';
const API_PRODUCT_ID = BASE_URL + '/api/find-product-id?';
const API_PRODUCT_NAME = BASE_URL + '/api/find-product-by-name?productName=';
const API_PRODUCT_SUB_SUB_CATEGORY = BASE_URL + '/api/find-product-as-subSubCategory?subSubCategory=';

const API_CAT_SUB_AS_SALE_PRODUCT = BASE_URL +'/api/find-cate-sub-as-sale?';

const API_CAT_AS_SALE_PRODUCT = BASE_URL +'/api/find-cate-as-sale?';



class ProductService {

   

    listProduct() {
        return axios.get(BASE_URL + '/api/all-products-unique');
    }
    listProductSale() {
        return axios.get(BASE_URL + '/api/find-product-by-sale');
    }

    listProductAsNewItem() {
    return axios.get(BASE_URL + '/api/all-products-new-item');
    }

    listProductCatAndSub(category,subCategory){
        return axios.get(API_CAT_SUB_PRODUCT+'category='+category+'&subCategory='+subCategory);
    }
    listProductCatAndSubAsSale(category,subCategory){
        return axios.get(API_CAT_SUB_AS_SALE_PRODUCT+'category='+category+'&subCategory='+subCategory);
    }
    listProductCatAndSubAndSubSub(category,subCategory,subSubCategory){
        return axios.get(API_CAT_SUB_SUBSUB_PRODUCT+'category='+category+'&subCategory='+subCategory+'&subSubCategory='+subSubCategory);
    }

    listProductCatAndSubAndName(category,subCategory,nameProd){
        return axios.get(API_CAT_SUB_NAME_PRODUCT+'category='+category+'&subCategory='+subCategory+'&name='+nameProd);
    }

    listProductCategory(category){
        return axios.get(API_CAT_PRODUCT+'category='+category);
    }
    listProductCategoryAsSale(category){
        return axios.get(API_CAT_AS_SALE_PRODUCT+'category='+category);
    }
   
    getProductById(productId){
        return axios.get(API_PRODUCT_ID+'productId='+productId);
    }

    listProductByName(productName){
        return axios.get(API_PRODUCT_NAME+productName);
    }

    listProductBySubSubCategory(productSubSubCategory){
        return axios.get(API_PRODUCT_SUB_SUB_CATEGORY+productSubSubCategory);
    }

   


    

}
export default new ProductService();