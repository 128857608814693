import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Stack,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Typography,
} from '@mui/material';
import { Colors } from '../theme';
import '@fontsource/aladin';


export const TitleSeperatorContainer = styled(Box)(({ matches, theme }) => ({
display: 'flex',
justifyContent:"space-between",

[theme.breakpoints.down('md')]: {
  
  flexDirection: 'column',
 
},


}));


export const TitleSeperatorLeftContainer = styled(Box)(({ }) => ({
  //display:'flex', 
  //alignItems: 'flex-start',
}));

export const TitleSeperatorRightContainer = styled(Box)(({}) => ({
 // display:'flex', 
  //alignItems: 'flex-end',
}));