import { CLEAR_CURRENT_PERSON, SET_CURRENT_PERSON } from '../actions/action-types/person-action';


const personReducer = (state = {}, action) => {
    switch (action?.type) {
        case SET_CURRENT_PERSON:
            localStorage.setItem('currentPerson', JSON.stringify(action?.payload));
            return action?.payload;
        case CLEAR_CURRENT_PERSON:
            localStorage.removeItem('currentPerson');
            return null;
        default:
            return JSON.parse(localStorage.getItem('currentPerson'));
    };
};

export default personReducer;
