import { useEffect } from 'react';
import { Button  } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Footer from '../footer';
import Appbar from '../appbar';
import {
  NotFoundContainer,
  NotFoundInnerContainer,
  NotFoundContent,
  NotFoundDescription,
  NotFoundImage,
} from '../../styles/notFound';

export default function NotFound() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  return (
    <>
      <Appbar />
      <NotFoundContainer sx={{marginBottom: 30,
  marginTop: 10}}>
        <NotFoundInnerContainer>
          <NotFoundImage src="/images/banner/404.png"/>
          <NotFoundContent>
            <NotFoundDescription variant="subtitle">
              Nous sommes désolés,la page n'est pas disponible.
            </NotFoundDescription>
            <Button  component={RouterLink} to="/">
            Retour à la page d'accueil
            </Button>
          </NotFoundContent>
        </NotFoundInnerContainer>
      </NotFoundContainer>
      <Footer />
    </>
  );
}
