import { Box, Grid, Link, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { Colors } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import useDialogModal from '../../hooks/useDialogModal';
import ProductDetail from '../productDetail';
import ProductService from '../../services/product.service';

import {
  CategoryTitle,
  InnerMenuContainer,
  InnerMenuContent2,
  InnerMenuContent5,
  NewImage,
} from '../../styles/innerMenu';

export default function InnerMenu() {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState([]);
  const theme = useTheme();
  const navigate = useNavigate();

  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(ProductDetail);
  useEffect(() => {
    ProductService.listProductAsNewItem()
      .then((response) => {
        setProducts(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });
  }, []);
  // const matches = useMediaQuery(theme.breakpoints.down('md'));



  const handleMarque = (productName) => {
 if(productName==="Suslo Couture"){
  navigate("/brand-one")
  return
 }
 
 if(productName==="Dockers"){
  navigate("/brand-two")
  return
 }

 if(productName==="Levi"){
  navigate("/brand-three")
  return
 }

 if(productName==="U.S. Polo Assn"){
  navigate("/brand-four")
  return
 }
 
  };

 

  return (
    <>
      <InnerMenuContainer>
        <Container component="main" maxWidth="lg">
          <CssBaseline />
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 2, sm: 1, md: 4 }}
          >
            <Grid
              sx={{
                borderRadius: '10px',
                boxShadow: 3,
                with: '100%',
                height: '100%',
              }}
              container
              //  rowSpacing={1}
              // columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent={'start'}
                  flexDirection="column"
                  sx={{ p: 2, mb: 2 }}
                >
                  <Typography sx={{ fontWeight: 600 }} variant="h5">
                    Nouveautés
                  </Typography>
                
                </Box>
              </Grid>
              {products.map((product) => (
                <Grid key={product.productId} xs={6}>
                  <InnerMenuContent2>
                    <InnerMenuContent5
                      sx={{
                        border: 3,
                        borderRadius: '50%',
                        borderColor: Colors.dove_gray,
                        backgroundColor: Colors.dove_gray,
                      }}
                    >
                      <NewImage
                        src={'/images/products/' + product.productNewImageUrl}
                        onClick={() => [setProduct(product),showProductDetailDialog()]}
                        alt={product.productName}
                      />
                    </InnerMenuContent5>
                    <CategoryTitle align="center" variant="h6">
                      {product.productName.toUpperCase()}
                    </CategoryTitle>
                  </InnerMenuContent2>
                </Grid>
              ))}
            </Grid>
            <ProductDetailDialog product={product} />
            <Grid
              sx={{
                borderRadius: '10px',
                boxShadow: 3,

                with: '100%',
                height: '100%',
              }}
              container
              //   rowSpacing={1}
              //  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={12}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent={'start'}
                  sx={{ p: 2, mb: 2 }}
                >
                  <Typography sx={{ fontWeight: 600 }} variant="h5">
                    Nos Marques
                  </Typography>
               
                </Box>
              </Grid>
              <Grid xs={6}>
                <InnerMenuContent2>
                  <InnerMenuContent5
                    sx={{
                      border: 3,
                      borderRadius: '50%',
                      borderColor: Colors.dove_gray,
                      backgroundColor: Colors.dove_gray,
                    }}
                  >
                    <NewImage
                      src="/images/banner/logo-SUSLO-COUTURE1.png"
                      alt="Suslo Couture"
                      onClick={() => handleMarque('Suslo Couture')}
                    />
                  </InnerMenuContent5>
                  <CategoryTitle align="center" variant="h6">
                  SUSLO COUTURE
                  </CategoryTitle>
                </InnerMenuContent2>
              </Grid>
              <Grid xs={6}>
                <InnerMenuContent2>
                  <InnerMenuContent5
                    sx={{
                      //borderRadius: '3vw 4vw 8vw 2vw',
                      border: 3,
                      borderRadius: '50%',
                      borderColor: Colors.dove_gray,
                      backgroundColor: Colors.dove_gray,
                    }}
                  >
                    <NewImage
                      src="/images/banner/logo-dockers.png"
                      alt="Dockers"
                      onClick={() => handleMarque('Dockers')}
                    />
                  </InnerMenuContent5>
                  <CategoryTitle align="center" variant="h6">
                  DOCKERS
                  </CategoryTitle>
                </InnerMenuContent2>
              </Grid>
              <Grid xs={6}>
                <InnerMenuContent2>
                  <InnerMenuContent5
                    sx={{
                      //borderRadius: '3vw 4vw 8vw 2vw',
                      border: 3,
                      borderRadius: '50%',
                      borderColor: Colors.dove_gray,
                      backgroundColor: Colors.dove_gray,
                    }}
                  >
                    <NewImage
                      src="/images/banner/logo-levis.png"
                      alt="Brand-Three"
                      onClick={() => handleMarque('Levi')}
                    />
                  </InnerMenuContent5>
                  <CategoryTitle align="center" variant="h6">
                 LEVIS
                  </CategoryTitle>
                </InnerMenuContent2>
              </Grid>
              <Grid xs={6}>
                <InnerMenuContent2>
                  <InnerMenuContent5
                    sx={{
                      border: 3,
                      borderRadius: '50%',
                      borderColor: Colors.dove_gray,
                      backgroundColor: Colors.dove_gray,
                    }}
                  >
                    <NewImage
                      src="/images/banner/U_S_Polo_Assn_logo.png"
                      alt="Brand-Four"
                      onClick={() => handleMarque('U.S. Polo Assn')}
                    />
                  </InnerMenuContent5>
                  <CategoryTitle align="center" variant="h6">
                  U.S. POLO ASSN
                  </CategoryTitle>
                </InnerMenuContent2>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </InnerMenuContainer>
      
    </>
  );
}
