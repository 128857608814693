import * as React from 'react';
import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
  useMediaQuery,
  ListItem,
} from '@mui/material';
import { Colors } from '../../../styles/theme';

import Appbar from '../../appbar';

import Footer from '../../footer';
import Products from '../../products';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../services/product.service';
import { SoldeList } from '../../../styles/solde';
import { useTheme } from '@mui/material/styles';

export default function MenSales() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  const [activeSaleMenShoes, setActiveSaleMenShoes] = useState(false);
  const handleClickSaleMenShoes = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(!activeSaleMenShoes);

    ProductService.listProductCatAndSubAsSale('homme', 'chaussure')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleMenShirt, setActiveSaleMenShirt] = useState(false);
  const handleClickSaleMenShirt = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(!activeSaleMenShirt);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(false);
    ProductService.listProductCatAndSubAsSale('homme', 't-shirt-shirt')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleMenJeans, setActiveSaleMenJeans] = useState(false);
  const handleClickSaleMenJeans = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(!activeSaleMenJeans);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(false);

    ProductService.listProductCatAndSubAsSale('homme', 'jeans-pants')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleMenBoxer, setActiveSaleMenBoxer] = useState(false);
  const handleClickSaleMenBoxer = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(!activeSaleMenBoxer);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(false);

    ProductService.listProductCatAndSubAsSale('homme', 'boxer-shorts')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleMenSoins, setActiveSaleMenSoins] = useState(false);
  const handleClickSaleMenSoins = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(!activeSaleMenSoins);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(false);

    ProductService.listProductCatAndSubAsSale('homme', 'soin')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleMenAccessoires, setActiveSaleMenAccessoires] =
    useState(false);
  const handleClickSaleMenAccessoires = () => {
    setActiveSaleMenAll(false);
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(!activeSaleMenAccessoires);
    setActiveSaleMenShoes(false);

    ProductService.listProductCatAndSubAsSale('homme', 'accessoire')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };
  const [activeSaleMenAll, setActiveSaleMenAll] = useState(false);
  const handleClickSaleMenAll = () => {
    setActiveSaleMenShirt(false);
    setActiveSaleMenJeans(false);
    setActiveSaleMenBoxer(false);
    setActiveSaleMenSoins(false);
    setActiveSaleMenAccessoires(false);
    setActiveSaleMenShoes(false);
    setActiveSaleMenAll(!activeSaleMenAll);
    ProductService.listProductCategoryAsSale('homme')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    if(value==='50'){
      ProductService.listProductCategoryAsSale('homme')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='60'){
      ProductService.listProductCatAndSubAsSale('homme', 'chaussure')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='70'){
      ProductService.listProductCatAndSubAsSale('homme', 't-shirt-shirt')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='80'){
      ProductService.listProductCatAndSubAsSale('homme', 'jeans-pants')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='90'){
      ProductService.listProductCatAndSubAsSale('homme', 'boxer-shorts')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='100'){
      ProductService.listProductCatAndSubAsSale('homme', 'soin')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='110'){
      ProductService.listProductCatAndSubAsSale('homme', 'accessoire')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
  
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    ProductService.listProductCategoryAsSale('homme')
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
      <Appbar />

      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" color="red" fontWeight={600} variant="h5">
          Soldes|Hommes
        </Typography>
      </Box>

      {matches ? (
         <Container component="main" maxWidth="xs">
         <CssBaseline />
         <Box
           display="flex"
           mt={4}
           mb={5}
           justifyContent="center"
           alignItems="center"
         >
           <FormControl fullWidth>
             <NativeSelect
             //  value={selectedSales}
             onChange={onChange} 
             >
               <option value={50}>All</option>
               <option value={60}>Chaussures</option>
               <option value={70}>T-Shirt & Shirt</option>
               <option value={80}>Jeans & Pants</option>
               <option value={90}>Boxer & Shorts</option>
               <option value={100}>Soins</option>
               <option value={110}>Accessoires</option>
               
             </NativeSelect>
           </FormControl>
         </Box>
       </Container>
      ) : (
        <>
          <Box
            justifyContent="center"
            alignItems="center"
            component="nav"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <SoldeList>
              <ListItem>
                <Box
                  onClick={() => [handleClickSaleMenShoes]}
                  style={{
                    background: activeSaleMenShoes
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenShoes}
                    style={{
                      color: activeSaleMenShoes ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Chaussures
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenShirt}
                  style={{
                    background: activeSaleMenShirt
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenShirt}
                    style={{
                      color: activeSaleMenShirt ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    T-Shirt & Shirt
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenJeans}
                  style={{
                    background: activeSaleMenJeans
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenJeans}
                    style={{
                      color: activeSaleMenJeans ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Jeans & Pants
                  </Typography>
                </Box>
              </ListItem>
            </SoldeList>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            component="nav"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <SoldeList>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenBoxer}
                  style={{
                    background: activeSaleMenBoxer
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenBoxer}
                    style={{
                      color: activeSaleMenBoxer ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Boxer & Shorts
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenSoins}
                  style={{
                    background: activeSaleMenSoins
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenSoins}
                    style={{
                      color: activeSaleMenSoins ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Soins
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenAccessoires}
                  style={{
                    background: activeSaleMenAccessoires
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenAccessoires}
                    style={{
                      color: activeSaleMenAccessoires
                        ? Colors.white
                        : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Accessoires
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleMenAll}
                  style={{
                    background: activeSaleMenAll
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 160,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleMenAll}
                    style={{
                      color: activeSaleMenAll ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    All
                  </Typography>
                </Box>
              </ListItem>
            </SoldeList>
          </Box>
        </>
      )}

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

      <Footer />
    </>
  );
}
