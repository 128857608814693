import {
  Box,
  Button
} from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import AuthenticationService from '../../services/authentication.service';
import { useNavigate,useLocation } from 'react-router-dom';
import { useState,useEffect } from 'react';
import Alert from '@mui/material/Alert';
import Person from '../../models/person';
import LockResetIcon from '@mui/icons-material/LockReset';
import Avatar from '@mui/material/Avatar';

import Footer from '../footer';
import Appbar from '../appbar';

export default function PasswordRecover(){
  const [personPassword, setPersonPassword] = useState(new Person('',''));
  const navigate = useNavigate();

  const location =useLocation();

  const [errorMessage, setErrorMessage] = useState('');
  const [passIsValidateErrorMessage, setPassIsValidateErrorMessage] = useState('');
  const [passIsValidateRepeatErrorMessage, setPassIsValidateRepeatErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');


  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  function isValidPassword(password) {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})'
    );
    return strongRegex.test(password);
  }

  const passwordBulletPointMsg =() =>{
    return (
      <ul>
        <li>6 à 100 caractères</li>
        <li>Lettres majuscules et minuscules</li>
        <li>Au moins un chiffre</li>
      </ul>
    );
  }
   //<input onChange=(event => handleChange(event))>
   const handleChange = (e) => {
    const { name, value } = e.target.value;
    setPersonPassword((prevState) => {
      //E.g: prevState ({user: abc, pass: abc}) + newKeyValue ({user: abcd}) => ({user: abcd, pass: abc})
      return {
        ...prevState,
        [name]: value,

      };
    });
  };
  const handleUpdatePassword = (e) => {
    setErrorMessage('')
    e.preventDefault();
    setSubmitted(true);
    //validation
    if ( !isValidPassword(repeatPassword) || !isValidPassword(newPassword) ) {
      setPassIsValidateErrorMessage(passwordBulletPointMsg());
      setPassIsValidateRepeatErrorMessage(passwordBulletPointMsg());
      return;
    } 
    if(newPassword !== repeatPassword){
      setErrorMessage("Les mots de passe doivent être identiques.");
      return;
    }

    personPassword.email=location.state.email;
    personPassword.password=newPassword;

    setLoading(true);
      AuthenticationService.recoverPassword(personPassword).then(response => {
      navigate('/login');
    })
    .catch((error) => {
     
        navigate('/404');
      
      setLoading(false);
    });
};
  return (
    <>
    <Appbar />
      <Box>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginBottom: 40,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockResetIcon />
          </Avatar>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Box
            component="form"
            noValidate
           
            className={submitted ? 'was-validated' : ''}
            sx={{ mt: 3 }}
          >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={!isValidPassword(newPassword) ? true : false}
              required
              fullWidth
              name="newPassword"
             // value={!newPassword}
              label="Nouveau Mot de Passe"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              //onChange={(e) => handleChange(e)}
              onChange={(e) => handleChange(setNewPassword(e.target.value)) }
              helperText={!isValidPassword(newPassword) && passIsValidateErrorMessage}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={!isValidPassword(repeatPassword) ? true : false}
              required
              fullWidth
              name="repeatPassword"
              value={repeatPassword}
              label="Confirmer Mot de Passe"
              type="password"
              id="repeatPassword"
              autoComplete="repeat-password"
              onChange={(e) => handleChange(setRepeatPassword(e.target.value))}
              helperText={!isValidPassword(repeatPassword) && passIsValidateRepeatErrorMessage}
            />
          </Grid>
       
        </Grid>
        
        <Button
               onClick={(e) => handleUpdatePassword(e)}
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2, borderRadius: '10px' }}
              color="primary"
            >
              Modifier Mot De Passe
            </Button>
            </Box>
            </Box>
        </Container>
      </Box>
      <Footer />
      </>
  );
}
