import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const initialState = {
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems'))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart(state, action) {
      const existingIndex = state.cartItems.findIndex(
        (item) =>
          item.productId === action.payload.productId &&
          item.productSizeOrder === action.payload.productSizeOrder
      );

      if (existingIndex >= 0) {
        state.cartItems[existingIndex] = {
          ...state.cartItems[existingIndex],
          cartQuantity: state.cartItems[existingIndex].cartQuantity + 1,
        };
        toast.info('Augmentation de la quantité de produit.', {
          position: 'top-right',
          autoClose: 600,
        });
      } else {
        let tempProductItem = { ...action.payload, cartQuantity: 1 };
        state.cartItems.push(tempProductItem);
        toast.success('Produit ajouté au panier.', {
          position: 'top-right',
          autoClose: 600,
        });
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) =>
          item.productId === action.payload.productId &&
          item.productSizeOrder === action.payload.productSizeOrder
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info('Diminution de la quantité de produit.', {
          position: 'top-right',
          autoClose: 600,
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) =>
            item.productId !== action.payload.productId ||
            item.productSizeOrder !== action.payload.productSizeOrder
        );

        state.cartItems = nextCartItems;

        toast.error('Produit retiré du panier.', {
          position: 'top-right',
          autoClose: 600,
        });
      }

      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
    },
    removeFromCart(state, action) {
      state.cartItems.map((cartItem) => {
        if (
          cartItem.id === action.payload.productId &&
          cartItem.productSizeOrder === action.payload.productSizeOrder
        ) {
          const nextCartItems = state.cartItems.filter(
            (item) =>
              item.productId !== cartItem.productId &&
              cartItem.productSizeOrder !== item.productSizeOrder
          );

          state.cartItems = nextCartItems;

          toast.error('Produit retiré du panier', {
            position: 'top-right',
            autoClose: 600,
          });
        }
        localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
        return state;
      });
    },
    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          const { productPrice, cartQuantity } = cartItem;
          const itemTotal = productPrice * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;

      state.cartTotalAmount = total;
    },

    clearCart(state, action) {
      state.cartItems = [];
      localStorage.setItem('cartItems', JSON.stringify(state.cartItems));
      // toast.error("Panier vidé.", { position: "bottom-left",autoClose:200 });
    },
  },
});

export const {
  addToCart,
  decreaseCart,
  removeFromCart,
  getTotals,
  clearCart,
  cartTotalQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
