import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';


const BASE_URL = BASE_API_URL + '/api/news';

class NewsLetterService {

  postNewsLetter(newLetters) {
      return axios.post(BASE_URL + '/news-lettter', newLetters);
  }
}

export default new NewsLetterService();