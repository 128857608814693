import * as React from 'react';
import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
  useMediaQuery,
  ListItem,
} from '@mui/material';
import { Colors } from '../../../styles/theme';
import Appbar from '../../appbar';
import { useTheme } from '@mui/material/styles';
import Footer from '../../footer';
import Products from '../../products';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../services/product.service';
import { SoldeList } from '../../../styles/solde';

export default function HouseSales() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  const [activeSaleHouseIn, setActiveSaleHouseIn] = useState(false);
  const handleClickSaleHouseIn = () => {
    setActiveSaleHouseCuisine(false);
    setActiveSaleHouseIn(!activeSaleHouseIn);
    setActiveSaleHouseAll(false);
    ProductService.listProductCatAndSubAsSale('maison', 'interieur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleHouseCuisine, setActiveSaleHouseCuisine] = useState(false);
  const handleClickSaleHouseCuisine = () => {
    setActiveSaleHouseCuisine(!activeSaleHouseCuisine);
    setActiveSaleHouseIn(false);
    setActiveSaleHouseAll(false);
    ProductService.listProductCatAndSubAsSale('maison', 'cuisine')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleHouseAll, setActiveSaleHouseAll] = useState(false);
  const handleClickSaleHouseAll = () => {
    setActiveSaleHouseCuisine(false);
    setActiveSaleHouseIn(false);
    setActiveSaleHouseAll(!activeSaleHouseAll);
    ProductService.listProductCategoryAsSale('maison')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    if(value==='50'){
      ProductService.listProductCategoryAsSale('maison')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='60'){
      ProductService.listProductCatAndSubAsSale('maison', 'interieur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='70'){
      ProductService.listProductCatAndSubAsSale('maison', 'cuisine')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    ProductService.listProductCategoryAsSale('maison')
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
      <Appbar />

      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" color="red" fontWeight={600} variant="h5">
          Soldes|Maison
        </Typography>
      </Box>
      {matches ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            display="flex"
            mt={4}
            mb={5}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl fullWidth>
              <NativeSelect onChange={onChange}>
                <option value={50}>All</option>
                <option value={60}>Interieur</option>
                <option value={70}>Cuisine</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Container>
      ) : (
        <Box
          justifyContent="center"
          alignItems="center"
          component="nav"
          sx={{ flexGrow: 1, display: 'flex' }}
        >
          <SoldeList>
            <ListItem>
              <Box
                onClick={() => [handleClickSaleHouseIn]}
                style={{
                  background: activeSaleHouseIn ? Colors.primary : Colors.white,
                }}
                sx={{
                  border: 3,
                  borderRadius: '16px',
                  borderColor: 'primary.main',
                  mx: 'auto',
                  width: 150,
                  m: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  onClick={handleClickSaleHouseIn}
                  style={{
                    color: activeSaleHouseIn ? Colors.white : Colors.black,
                  }}
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    fontWeight: '700',
                  }}
                >
                  Interieur
                </Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box
                onClick={handleClickSaleHouseCuisine}
                style={{
                  background: activeSaleHouseCuisine
                    ? Colors.primary
                    : Colors.white,
                }}
                sx={{
                  border: 3,
                  borderRadius: '16px',
                  borderColor: 'primary.main',
                  mx: 'auto',
                  width: 150,
                  m: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  onClick={handleClickSaleHouseCuisine}
                  style={{
                    color: activeSaleHouseCuisine ? Colors.white : Colors.black,
                  }}
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    fontWeight: '700',
                  }}
                >
                  Cuisine
                </Typography>
              </Box>
            </ListItem>
            <ListItem>
              <Box
                onClick={handleClickSaleHouseAll}
                style={{
                  background: activeSaleHouseAll
                    ? Colors.primary
                    : Colors.white,
                }}
                sx={{
                  border: 3,
                  borderRadius: '16px',
                  borderColor: 'primary.main',
                  mx: 'auto',
                  width: 150,
                  m: 1,
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              >
                <Typography
                  onClick={handleClickSaleHouseAll}
                  style={{
                    color: activeSaleHouseAll ? Colors.white : Colors.black,
                  }}
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    fontWeight: '700',
                  }}
                >
                  All
                </Typography>
              </Box>
            </ListItem>
          </SoldeList>
        </Box>
      )}

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

      <Footer />
    </>
  );
}
