import CloseIcon from '@mui/icons-material/Close';
import {
  Box, Card, CardActionArea, CardMedia, Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography, useMediaQuery
} from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  DetailPartnerAvatar, DetailPartnerContent, DetailPartnerDescription, DetailPartnerImage, DetailPartnerTitle, PresentationContent,
  PresentationDetailContent
} from '../../styles/detailPartner';
import { Colors } from '../../styles/theme';

function SlideTransition(props) {
  
  return <Slide direction="up" {...props} />;
}

export default function DetailPartner({ open, onClose, partner }) {
  function jsonFormat(desc) {
 
    return JSON.parse(desc);;
  }
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [sliderRef, setSliderRef] = useState(null);

  const sliderSettings = {
    arrows: false,

    slidesToShow: 2.2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
         
          slidesToShow: 1.03,
        },
      },
    ],
  }
  return (
    <Dialog
      TransitionComponent={SlideTransition}
      variant="permanant"
      open={open}
      fullScreen
    >
      <DialogTitle
        sx={{
          background: Colors.secondary,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          //justifyContent={"space-between"}
          justifyContent={'flex-start'}>
            
        <Typography fontSize={30} align='center' component="h1" variant="h5">
          {partner.partnerAddress}
        
          </Typography>
          </Box>
        <Box
          display="flex"
          alignItems="center"
          //justifyContent={"space-between"}
          justifyContent={'flex-end'}
        >
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent alignItems="center" justifyContent="center">
        <Container component="main"  maxWidth="lg">
          <CssBaseline />
          <Box
            sx={{
              mb: 5,
              mt: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
            }}
          >
              <PresentationContent >
              <Box display={'flex'}  justifyContent={'center'}>
                <DetailPartnerAvatar 
                  alt="polo"
                  src={'images/partner/avatar/'+partner.partnerAvatar}
                  
                />
                </Box>

              <PresentationDetailContent  >               
              <Typography mt={2} align="center" variant="h6">
                  {partner.partnerFirstName}
                </Typography>
                <Typography  align="center" variant="h6">
                  {partner.partnerLastName}
                </Typography>
                <Typography  align="center" variant="h6">
                  {partner.firstName}
                </Typography>
                <Typography  align="center" variant="h6">
                  {partner.partnerPhoneNumber}
                </Typography>
                <Typography  align="center" variant="h6">
                  {partner.dayOfWeek}
                </Typography>
                <Typography  align="center" variant="h6">
                  {partner.timeOfWeek}
                </Typography>
               
              </PresentationDetailContent>
            </PresentationContent>

            <Grid mt={4}   justifyContent ='center'  container spacing={4}>
            <DetailPartnerContent>
            <Typography variant="h6">{partner.partnerAddress}</Typography>
               
                  <DetailPartnerTitle variant="h8">
                  {partner.partnerAlias}
                  </DetailPartnerTitle>
                  {partner.partnerDescription.map((index) => (  
                  <DetailPartnerDescription variant="subtitle">
                  {index}               
                  </DetailPartnerDescription>
                  ))} 
                </DetailPartnerContent>
            </Grid>

            <Grid justifyContent ='center'  container spacing={4}>
              <Grid item sx>
                <DetailPartnerImage sx={{borderRadius: '10px'}}
                  alt={partner.partnerPhoto}
               
                  src={'/images/partner/product-partner/'+partner.partnerPhoto}
                />
              </Grid>

              <Grid item sx>
            <a                   href={partner.partnerGoogleMapLink}
target="_blank">

                <DetailPartnerImage  sx={{borderRadius: '10px'}}
                  alt={partner.partnerLocationImage}
                  src={'/images/partner/product-partner/'+partner.partnerLocationImage}

                  
                />
                </a>
              </Grid>
            </Grid>
          </Box>
          <Slider sx={{ 
            with: matches ? "100%" : 400,
            height: matches ? 250 : 600}} ref={setSliderRef} {...sliderSettings}>
        {partner.pertnerOffer.partnerOfferImages.map((card, index) => (
          <div key={index} >
            
           <Card
        sx={{
          borderRadius: '10px',
          m:0.2,
         
        }}
      >
        <CardActionArea  >
          <CardMedia
          sx={{ 
            with: matches ? "100%" : 400,
            height: matches ? 300 : 600}}
            component="img"
          
            image={'/images/partner/product-partner/'+card.partnerOfferImageName}
            alt={card.partnerOfferImageName}
          />
            
      

          
        </CardActionArea>
      </Card>
          
          
          </div>
        ))}
      </Slider>
         
        </Container>
      </DialogContent>
    </Dialog>
  );
}
