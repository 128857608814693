import * as React from 'react';
import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
  useMediaQuery,
  ListItem,
} from '@mui/material';
import { Colors } from '../../../styles/theme';

import Appbar from '../../appbar';

import Footer from '../../footer';
import Products from '../../products';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../services/product.service';
import { SoldeList } from '../../../styles/solde';
import { useTheme } from '@mui/material/styles';

export default function WomenSales() {
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  
  const navigate = useNavigate();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [activeSaleWomenShoes, setActiveSaleWomenShoes] = useState(false);
  const handleClickSaleWomenShoes = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenShoes(!activeSaleWomenShoes);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'chaussure')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleWomenBag, setActiveSaleWomenBag] = useState(false);
  const handleClickSaleWomenBag = () => {
    setActiveSaleWomenBag(!activeSaleWomenBag);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'sac')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleWomenMascara, setActiveSaleWomenMascara] = useState(false);
  const handleClickSaleWomenMascara = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(!activeSaleWomenMascara);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'mascara')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleWomenRougeLevres, setActiveSaleWomenRougeLevres] =
    useState(false);
  const handleClickSaleRougeLevres = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(!activeSaleWomenRougeLevres);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'rouge-a-levres')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleWomenVernie, setActiveSaleWomenVernie] = useState(false);
  const handleClickSaleWomenVernie = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(!activeSaleWomenVernie);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'vernie-a-ongles')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleWomenPafums, setActiveSaleWomenPafums] = useState(false);
  const handleClickSaleWomenParfums = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(!activeSaleWomenPafums);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'parfums')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };
  const [activeSaleWomenAccessoires, setActiveSaleWomenAccessoires] =
    useState(false);
  const handleClickSaleWomenAccessoires = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenAccessoires(!activeSaleWomenAccessoires);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAll(false);
    ProductService.listProductCatAndSubAsSale('femme', 'accessoire')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };
  const [activeSaleWomenAll, setActiveSaleWomenAll] = useState(false);
  const handleClickSaleWomenAll = () => {
    setActiveSaleWomenBag(false);
    setActiveSaleWomenMascara(false);
    setActiveSaleWomenRougeLevres(false);
    setActiveSaleWomenVernie(false);
    setActiveSaleWomenPafums(false);
    setActiveSaleWomenAccessoires(false);
    setActiveSaleWomenShoes(false);
    setActiveSaleWomenAll(!activeSaleWomenAll);
    ProductService.listProductCategoryAsSale('femme')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    if(value==='50'){
      ProductService.listProductCategoryAsSale('femme')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='60'){
      ProductService.listProductCatAndSubAsSale('femme', 'sac')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='70'){
      ProductService.listProductCatAndSubAsSale('femme', 'chaussure')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='80'){
      ProductService.listProductCatAndSubAsSale('femme', 'parfums')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='90'){
      ProductService.listProductCatAndSubAsSale('femme', 'mascara')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='100'){
      ProductService.listProductCatAndSubAsSale('femme', 'rouge-a-levres')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='110'){
      ProductService.listProductCatAndSubAsSale('femme', 'vernie-a-ongles')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='120'){
      ProductService.listProductCatAndSubAsSale('femme', 'accessoire')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    

  };

  useEffect(() => {
    window.scrollTo(0, 0);

    ProductService.listProductCategoryAsSale('femme')
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

     setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );
      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));
      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));
      setProduct(productOutput);
    }
      
  }, [selected, navigate]);

  return (
    <>
      <Appbar />

      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" color="red" fontWeight={600} variant="h5">
          Soldes|Femmes
        </Typography>
      </Box>

      {matches ? (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
            //  value={selectedSales}
            onChange={onChange} 
            >
              <option value={50}>All</option>
              <option value={60}>Sacs</option>
              <option value={70}>Chaussures</option>
              <option value={80}>Parfums</option>
              <option value={90}>Mascara</option>
              <option value={100}>Rouge A Levres</option>
              <option value={110}>Vernie A Ongles</option>
              <option value={120}>Accessoires</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>
      ) : (
        <> <Box
        justifyContent="center"
        alignItems="center"
        component="nav"
        sx={{ flexGrow: 1, display: 'flex' }}
      >
        <SoldeList>
          <ListItem>
            <Box
              onClick={() => [handleClickSaleWomenShoes]}
              style={{
                background: activeSaleWomenShoes
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenShoes}
                style={{
                  color: activeSaleWomenShoes ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Chaussures
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenBag}
              style={{
                background: activeSaleWomenBag ? Colors.primary : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenBag}
                style={{
                  color: activeSaleWomenBag ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Sacs
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenMascara}
              style={{
                background: activeSaleWomenMascara
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenMascara}
                style={{
                  color: activeSaleWomenMascara ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Mascara
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleRougeLevres}
              style={{
                background: activeSaleWomenRougeLevres
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleRougeLevres}
                style={{
                  color: activeSaleWomenRougeLevres
                    ? Colors.white
                    : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Rouge A Levres
              </Typography>
            </Box>
          </ListItem>
        </SoldeList>
      </Box>
      <Box
        justifyContent="center"
        alignItems="center"
        component="nav"
        sx={{ flexGrow: 1, display: 'flex' }}
      >
        <SoldeList>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenVernie}
              style={{
                background: activeSaleWomenVernie
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenVernie}
                style={{
                  color: activeSaleWomenVernie ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Vernie A Ongles
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenParfums}
              style={{
                background: activeSaleWomenPafums
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenParfums}
                style={{
                  color: activeSaleWomenPafums ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Parfums
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenAccessoires}
              style={{
                background: activeSaleWomenAccessoires
                  ? Colors.primary
                  : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenAccessoires}
                style={{
                  color: activeSaleWomenAccessoires
                    ? Colors.white
                    : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                Accessoires
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickSaleWomenAll}
              style={{
                background: activeSaleWomenAll ? Colors.primary : Colors.white,
              }}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                onClick={handleClickSaleWomenAll}
                style={{
                  color: activeSaleWomenAll ? Colors.white : Colors.black,
                }}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                }}
              >
                All
              </Typography>
            </Box>
          </ListItem>
        </SoldeList>
      </Box></>
      )}

     

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

      <Footer />
    </>
  );
}
