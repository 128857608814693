import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
} from '@mui/material';
import { Colors } from '../../styles/theme';
import Appbar from '../appbar';

import Footer from '../footer';
import SinglePageResult from '../singlePageResult';

import { useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import ProductService from '../../services/product.service';

export default function NoResult() {
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);
  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const {state} = useLocation();
  const {noFoundResult} = state; 
  useEffect(() => {
    window.scrollTo(0, 0);
   
    ProductService.listProductSale()
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
      <Appbar/>
      <Box
      sx={{
          display:"flex",
          marginBottom:'20px'
          }}></Box>
      <Box
        sx={{
          
          display:"flex",
          flexDirection:'column',
      
          borderRadius: '10px',
          boxShadow: 3,
          maxWidth: 700,
          borderColor: Colors.primary,
          background: Colors.dove_gray,
          ':hover': {
            
            boxShadow: 6,
            border: 1,
            
          },
          
          margin: 'auto'
        }}
      >
        <Typography align="center" fontWeight={600}   variant="h6">
        Nous n'avons trouvé aucune offre "{noFoundResult}" pour le moment.
        </Typography>
      </Box>
      <SinglePageResult/>

      <Footer />
    </>
  );
}
