export default class Partner{
  constructor(partnerAvatar,partnerGoogleMapLink,province,cartier,partnerDescription,partnerAddress,partnerFirstName,partnerLastName,partnerAlias,partnerPhoneNumber,partnerPhoto,partnerLocationImage,dayOfWeek,timeOfWeek,pertnerOffer){
   this.partnerAvatar=partnerAvatar;
   this.partnerGoogleMapLink=partnerGoogleMapLink;
    this.partnerDescription=partnerDescription;
    this.province=province;
    this.cartier=cartier;
    this.partnerAddress=partnerAddress;
    this.partnerAlias=partnerAlias;
    this.partnerFirstName=partnerFirstName;
    this.partnerLastName=partnerLastName;
    this.partnerPhoneNumber=partnerPhoneNumber;
    this.partnerPhoto=partnerPhoto;
    this.partnerLocationImage=partnerLocationImage;
    this.dayOfWeek=dayOfWeek;
    this.timeOfWeek=timeOfWeek;
    this.pertnerOffer=pertnerOffer;

  }

  }