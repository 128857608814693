import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import {
  Avatar,
  ListItemButton,
  ListItemIcon,
  Menu,
  AppBar,
  Toolbar,
  Button,
  Collapse,
  List,
  ListItemText,
  TextField,
  Box,
  IconButton,
} from '@mui/material';

import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { useUIContext } from '../../context/ui';
import {
  LogoImage,
  AppbarContainerSticker,
  MyList,
  MyListTop,
  MyListCenter,
} from '../../styles/appbar';
import { Colors } from '../../styles/theme';
import Actions from './actions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';


export default function AppbarDesktop({ matches }) {
  const navigate = useNavigate();
  const [openS, setOpenS] = useState(false);
  const { setShowSearchBox } = useUIContext();

  const [textMsg, setTextMsg] = useState('');
  const handleChange = (event) => {
    setTextMsg(event.target.value);
    
  };

  const handleSearch = () => {
    if (textMsg.length === 0) {
      return;
    } else {
      navigate('/searching', {
        state: {
          searchKeyCriteria: textMsg,
        },
      });
    }
    setTextMsg('');
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTextMsg(textMsg);
      if (textMsg.length === 0) {
        return;
      } else {
        navigate('/searching', {
          state: {
            searchKeyCriteria: textMsg,
          },
        });
      }
      setTextMsg('');
    }
  };

  /** Sales */
  const handleNavigationSale = (page) => {
    setAnchorWomenEl(null);
    navigate(page);
    return;
  };
  const [anchorSaleEl, setAnchorSaleEl] = React.useState(null);
  const openSale = Boolean(anchorSaleEl);
  
  const handleSale1Click = (event) => {
    navigate('/presentation');
    return;
  };
  const handleSaleClick = (event) => {
    setAnchorSaleEl(event.currentTarget);
  };
  const handleSaleClose = () => {
    setAnchorSaleEl(null);
  };

  /** Women */

  const handleNavigationWomen = (category, subCategory, title) => {
    setAnchorWomenEl(null);

    if (category === 'femme' && subCategory === 'accessoire') {
      navigate('/women-accessories');
      return;
    }
    if (category === 'femme' && subCategory === 'sac') {
      navigate('/women-bags');
      return;
    }
    if (category === 'femme' && subCategory === 'rouge-a-levres') {
      navigate('/women-lipstick');
      return;
    }
    if (category === 'femme' && subCategory === 'mascara') {
      navigate('/women-mascara');
      return;
    }
    if (category === 'femme' && subCategory === 'vernie-a-ongles') {
      navigate('/women-nail-polish');
      return;
    }
    if (category === 'femme' && subCategory === 'parfums') {
      navigate('/women-parfums');
      return;
    }
    if (category === 'femme' && subCategory === 'chaussure') {
      navigate('/women-shoes');
      return;
    }
  };
  const [anchorWomenEl, setAnchorWomenEl] = React.useState(null);
  const openWomen = Boolean(anchorWomenEl);
  const handleWomenClick = (event) => {
    setAnchorWomenEl(event.currentTarget);
  };
  const handleWomenClose = () => {
    setAnchorWomenEl(null);
  };

  const handleClickCosmetique = () => {
    setOpenS(!openS);
  };

  /** Shoes */
  const handleNavigationShoes = (category, subCategory, title) => {
    setAnchorShoesEl(null);
    if (category === 'homme' && subCategory === 'chaussure') {
      navigate('/men-shoes');
      return;
    }
    if (category === 'femme' && subCategory === 'chaussure') {
      navigate('/women-shoes');
      return;
    }
  };
  const [anchorShoesEl, setAnchorShoesEl] = React.useState(null);
  const openShoes = Boolean(anchorShoesEl);
  const handleShoesClick = (event) => {
    setAnchorShoesEl(event.currentTarget);
  };
  const handleShoesClose = () => {
    setAnchorShoesEl(null);
  };

  /** Men */
  const handleNavigationHommes = (category, subCategory, title) => {
    setAnchorMenEl(null);
    if (category === 'homme' && subCategory === 'chaussure') {
      navigate('/men-shoes');
      return;
    }
    if (category === 'homme' && subCategory === 'accessoire') {
      navigate('/men-accessories');
      return;
    }
    if (category === 'homme' && subCategory === 'soin') {
      navigate('/men-soins');
      return;
    }
    if (category === 'homme' && subCategory === 'jeans-pants') {
      navigate('/men-jeans-pants');
      return;
    }
    if (category === 'homme' && subCategory === 't-shirt-shirt') {
      navigate('/men-t-shirt-shirt');
      return;
    }
    if (category === 'homme' && subCategory === 'boxer-shorts') {
      navigate('/men-boxer-shorts');
      return;
    }
  };
  const [anchorMenEl, setAnchorMenEl] = React.useState(null);
  const openMen = Boolean(anchorMenEl);
  const handleMenClick = (event) => {
    setAnchorMenEl(event.currentTarget);
  };
  const handleMenClose = () => {
    setAnchorMenEl(null);
  };

  /** Electro */
  const handleNavigationElectro = (category, subCategory, title) => {
    setAnchorElectroEl(null);
    if (category === 'electronique' && subCategory === 'selfie') {
      navigate('/electronic-selfie');
      return;
    }
    if (category === 'electronique' && subCategory === 'telephone') {
      navigate('/electronic-phones');
      return;
    }
    if (category === 'electronique' && subCategory === 'ecouteur') {
      navigate('/electronic-headphones');
      return;
    }
    if (category === 'electronique' && subCategory === 'ordinateur') {
      navigate('/electronic-computers');
      return;
    }
    if (category === 'electronique' && subCategory === 'chargeur') {
      navigate('/electronic-chargers');
      return;
    }
  };
  const [anchorElectroEl, setAnchorElectroEl] = React.useState(null);
  const openElectro = Boolean(anchorElectroEl);
  const handleElectroClick = (event) => {
    setAnchorElectroEl(event.currentTarget);
  };
  const handleElectroClose = () => {
    setAnchorElectroEl(null);
  };

  /** House */
  const handleNavigationHouse = (category, subCategory, title) => {
    setAnchorHouseEl(null);
    if (category === 'maison' && subCategory === 'interieur') {
      navigate('/house-in');
      return;
    }
    if (category === 'maison' && subCategory === 'cuisine') {
      navigate('/house-kitchen');
      return;
    }
  };
  const [anchorHouseEl, setAnchorHouseEl] = React.useState(null);
  const openHouse = Boolean(anchorHouseEl);
  const handleHouseClick = (event) => {
    setAnchorHouseEl(event.currentTarget);
  };
  const handleHouseClose = () => {
    setAnchorHouseEl(null);
  };

  return (
    <>
      <AppbarContainerSticker>
        <AppBar position="static">
          <Toolbar sx={{ background: Colors.white }}>
            <a href="/" rel="noreferrer">
              <LogoImage
                noWrap
                sx={{ mt: '0.5px', mb: '0.5px' }}
                src="/images/banner/logo/logo192.png"
                alt="lipstick"
                component={Link}
                to="/"
              />
            </a>
            <Box
              sx={{
                display: 'flex',
                flexGrow: 40,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <TextField
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                value={textMsg}
                id="standard-bare"
                component="div"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Avatar sx={{ bgcolor: 'primary.main' }}>
                        <SearchIcon
                          sx={{ ':hover': { cursor: 'pointer' } }}
                          onClick={() => handleSearch()}
                        />
                      </Avatar>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <MyListTop type="row">
              <Actions component="div" matches={matches} />
            </MyListTop>
          </Toolbar>

          <Toolbar sx={{ background: Colors.primary }}>
            <MyList type="row" component="nav">
            <Button
                id="basic-button"
                sx={{
                  color: Colors.react_green,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                
                onClick={handleSale1Click}
              >
                Back to School
              </Button>

              <Button
                id="basic-button"
                sx={{
                  color: Colors.white,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openWomen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openWomen ? 'true' : undefined}
                onClick={handleWomenClick}
              >
                Femmes
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorWomenEl}
                open={openWomen}
                onClose={handleWomenClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationWomen(
                      'femme',
                      'chaussure',
                      'Femmes|Chaussures'
                    ),
                  ]}
                >
                  Chaussures
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationWomen('femme', 'sac', 'Femmes|Sacs'),
                  ]}
                >
                  Sacs
                </MenuItem>

                <ListItemButton onClick={handleClickCosmetique}>
                  <ListItemText>Cosmetiques</ListItemText>
                  {openS ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openS} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton
                      onClick={() => [
                        handleNavigationWomen(
                          'femme',
                          'mascara',
                          'Femmes|Mascara'
                        ),
                      ]}
                    >
                      <ListItemText primary="Mascara" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => [
                        handleNavigationWomen(
                          'femme',
                          'rouge-a-levres',
                          'Femmes|Rouge à Lèvres'
                        ),
                      ]}
                    >
                      <ListItemText primary="Rouge à Lèvres" />
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => [
                        handleNavigationWomen(
                          'femme',
                          'vernie-a-ongles',
                          'Femmes|Vernie à Ongles'
                        ),
                      ]}
                    >
                      <ListItemText primary="Vernie à Ongles" />
                    </ListItemButton>

                    <ListItemButton
                      onClick={() => [
                        handleNavigationWomen(
                          'femme',
                          'parfums',
                          'Femmes|Parfums'
                        ),
                      ]}
                    >
                      <ListItemText primary="Parfums" />
                    </ListItemButton>
                  </List>
                </Collapse>
                <MenuItem
                  onClick={() => [
                    handleNavigationWomen(
                      'femme',
                      'accessoire',
                      'Femmes|Accessoires'
                    ),
                  ]}
                >
                  Accessoires
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                sx={{
                  color: Colors.white,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openShoes ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openShoes ? 'true' : undefined}
                onClick={handleShoesClick}
              >
                Chaussures
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorShoesEl}
                open={openShoes}
                onClose={handleShoesClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationShoes(
                      'femme',
                      'chaussure',
                      'Chaussures|Femmes'
                    ),
                  ]}
                >
                  Femmes
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationShoes(
                      'homme',
                      'chaussure',
                      'Chaussures|Hommes'
                    ),
                  ]}
                >
                  Hommes
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                sx={{
                  color: Colors.white,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openMen ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMen ? 'true' : undefined}
                onClick={handleMenClick}
              >
                Hommes
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorMenEl}
                open={openMen}
                onClose={handleMenClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes(
                      'homme',
                      'chaussure',
                      'Hommes|Chaussures'
                    ),
                  ]}
                >
                  Chaussures
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes(
                      'homme',
                      't-shirt-shirt',
                      'Hommes|T-Shirt&Shirt'
                    ),
                  ]}
                >
                  T-Shirt&Shirt
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes(
                      'homme',
                      'jeans-pants',
                      'Hommes|Jeans&Pants'
                    ),
                  ]}
                >
                  Jeans&Pants
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes(
                      'homme',
                      'boxer-shorts',
                      'Hommes|Boxer&Shorts'
                    ),
                  ]}
                >
                  Boxer&Shorts
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes('homme', 'soin', 'Hommes|Soins'),
                  ]}
                >
                  Soins
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHommes(
                      'homme',
                      'accessoire',
                      'Hommes|Accessoires'
                    ),
                  ]}
                >
                  Accessoires
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                sx={{
                  color: Colors.white,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openElectro ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openElectro ? 'true' : undefined}
                onClick={handleElectroClick}
              >
                Electroniques
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorElectroEl}
                open={openElectro}
                onClose={handleElectroClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationElectro(
                      'electronique',
                      'telephone',
                      'Electroniques|Téléphones'
                    ),
                  ]}
                >
                  Téléphones
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationElectro(
                      'electronique',
                      'ecouteur',
                      'Electroniques|Écouteurs/Speaker'
                    ),
                  ]}
                >
                  Écouteurs/Speaker
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationElectro(
                      'electronique',
                      'selfie',
                      'Electroniques|Selfie'
                    ),
                  ]}
                >
                  Selfie
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationElectro(
                      'electronique',
                      'chargeur',
                      'Electroniques|Chargeurs'
                    ),
                  ]}
                >
                  Chargeurs
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationElectro(
                      'electronique',
                      'ordinateur',
                      'Electroniques|Ordinateurs'
                    ),
                  ]}
                >
                  Ordinateurs
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                sx={{
                  color: Colors.white,
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openHouse ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openHouse ? 'true' : undefined}
                onClick={handleHouseClick}
              >
                Maison
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorHouseEl}
                open={openHouse}
                onClose={handleHouseClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationHouse(
                      'maison',
                      'interieur',
                      'Maison|Intérieur'
                    ),
                  ]}
                >
                  Intérieur
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationHouse(
                      'maison',
                      'cuisine',
                      'Maison|Cuisine'
                    ),
                  ]}
                >
                  Cuisine
                </MenuItem>
              </Menu>

              <Button
                id="basic-button"
                sx={{
                  color: 'red',
                  fontSize: 18,
                  fontWeight: 600,
                  lineHeight: 2,
                }}
                aria-controls={openSale ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openSale ? 'true' : undefined}
                onClick={handleSaleClick}
              >
                Soldes
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorSaleEl}
                open={openSale}
                onClose={handleSaleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/women-sales"),
                  ]}
                >
                  Femmes
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/shoes-sales"),
                  ]}
                >
                  Chaussures
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/men-sales"),
                  ]}
                >
                  Hommes
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/electro-sales"),
                  ]}
                >
                  Electroniques
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/house-sales"),
                  ]}
                >
                  Maison
                </MenuItem>
                <MenuItem
                  onClick={() => [
                    handleNavigationSale("/solde"),
                  ]}
                >
                  All
                </MenuItem>
              </Menu>
              
            </MyList>
          </Toolbar>
        </AppBar>
      </AppbarContainerSticker>
    </>
  );
}
