import { AppbarContainer, AppbarHeader,AppbarContainerSticker, LogoImage } from "../../styles/appbar";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import Actions from "./actions";
import { IconButton } from "@mui/material";
import { useUIContext } from "../../context/ui";
import { Colors } from '../../styles/theme';
import { Link } from 'react-router-dom';


export default function AppbarMobile({ matches }) {
  const { setDrawerOpen, setShowSearchBox } = useUIContext();
  return (
   
<AppbarContainerSticker sx={{background: Colors.primary}}> 
      <AppbarContainer>
      <IconButton onClick={() => setDrawerOpen(true)}>
        <MenuIcon sx={{ color: Colors.white }} />
      </IconButton>
      <AppbarHeader textAlign={"center"} noWrap
           // sx={{textDecorationLine:0, textDecorationColor:0, textDecoration:0}} 
            component={Link}
            to="/" variant="h3">
      237usa
      </AppbarHeader>
      <IconButton onClick={() => setShowSearchBox(true)}>
        <SearchIcon sx={{ color: Colors.white }} />
      </IconButton>
      <Actions matches={matches} />
    </AppbarContainer>

    </AppbarContainerSticker>
  );
}