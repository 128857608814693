import { Typography,Box } from '@mui/material';
import { Colors } from '../../styles/theme';
import Footer from '../footer';
import Appbar from '../appbar';
import {useEffect} from 'react';
import {
  BannerContainer,  BannerContent, BannerDescription,
  BannerImage,
   BannerTitle
} from '../../styles/banner';


export default function AboutUs() {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);



  return (
    <div>
        <Appbar />
        <Box sx={{ flexDirection: "column",mb:'1em'}}> 
        <Box sx={{mt:'1em'}}>
      <BannerTitle align='center' >Salut, nous sommes 237usa</BannerTitle>
      </Box>
        <BannerContainer > 
        <BannerContent sx={{ background: Colors.pastel_purple, borderRadius:'65% 29% 9% 20% / 26% 85% 79% 91%'}}>
          <Typography variant="h6">Notre Vision</Typography>
        
          <BannerDescription variant="subtitle">
          237usa a commencé avec une vision simple où la technologie peut être combinée avec une 
          conception inspirante pour aider les gens à accéder facilement et durablement à des articles authentique et fiable.
          </BannerDescription>

        </BannerContent>
        </BannerContainer>
        <BannerContainer> 
      
        <BannerContent sx={{ background: Colors.pastel_blue, borderRadius:'15% 80% 62% 12% / 95% 73% 51% 37%'}}>
          <Typography variant="h6">Notre Mission</Typography>
        
          <BannerDescription variant="subtitle">
          237usa fournit un service centré sur la personne d'où nous nous engageons à choisir, 
          trier et mettre à votre disposition des articles de qualités au plus près de chez vous.
          </BannerDescription>
        </BannerContent>
        
        </BannerContainer>
        </Box>
       
        <Footer />
    </div>
  );
}
