import {
  Grid,
  List,
  ListItemText,
  Typography,
  Button,
  Stack,
  Alert
} from "@mui/material";
import { Box } from "@mui/system";
import { Colors } from "../../styles/theme";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SubscribeTf, FooterTitle } from "../../styles/footer";
import SendIcon from "@mui/icons-material/Send";
import {useEffect,useState} from 'react';
import { useNavigate,Link } from 'react-router-dom';
import NewsLetter from '../../models/newsLetters';
import NewsLetterService from '../../services/newsletter.service';
import { toast } from "react-toastify";



export default function Footer() {
  const [errorMessage, setErrorMessage] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [newsLetter, setNewsLetter] = useState(new NewsLetter('', ''));
  const navigate = useNavigate();
  useEffect(() => {
    // 👇️ scroll to top on page load
    //window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  const handleChange = (e) => {
    setErrorMessage('');
    setEmailValue(e.target.value);
  };

  const handleNewsLetter = (e) => {
    e.preventDefault();
 

    setErrorMessage('');
    //validation
    if (!isValidEmail(emailValue)) {
      setEmailErrorMessage("L'addresse email est requise");

      return;
    }
    newsLetter.email=emailValue;
    newsLetter.category="client";
    setLoading(true);
    NewsLetterService.postNewsLetter(newsLetter)
      .then(() => {
      
      })
      .catch((error) => {
        navigate("/404");
        setLoading(false);
      });
      setEmailValue('')
      toast.success("Merci de vous être abonné.", {
        position: "bottom-right", autoClose:1000
      });
  };
  const usa237ContactBulletPoint = () => {
    return (
      <ul>
        <li>Téléphone/Whatsapp: +237-699-97-29-41</li>
        <li>Email: info@237usa.com</li>
      </ul>
    );
  };
  return (
    <>


    <Box
 
      sx={{
        background: Colors.light_gray,
        color: Colors.black,
        p: { xs: 4, md: 10 },
        pt: 12,
        pb: 12,
        fontSize: { xs: '12px', md: '14px' }
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">À propos de nous</FooterTitle>
          <Typography sx={{lineHeight: 1.25,
             letterSpacing: 1.25,}} variant="subtitle">
          237usa a commencé avec une vision simple où la technologie peut être 
          combinée avec une conception inspirante pour aider les gens à accéder 
          facilement et durablement à des articles authentique et fiable.
          </Typography>
          <Typography variant="h6">Nous contacter:</Typography>
          <Typography variant="subtitle">{usa237ContactBulletPoint()}</Typography>
          <Box
            sx={{
              mt: 4,
              color: Colors.dove_gray,
            }}
          >
            <FacebookIcon sx={{ mr: 1 }} />
            <TwitterIcon sx={{ mr: 1 }} />
            <InstagramIcon />
          </Box>
        </Grid>
        <Grid item md={6} lg={2}>
          <FooterTitle variant="body1">information</FooterTitle>
          <List>
            <ListItemText>
              <Typography sx={{textDecorationLine:"none", textDecorationColor:"none"}} component={Link} to="/aboutUs" lineHeight={2} variant="caption2">
              À propos de nous
              </Typography>
            </ListItemText>
            
            <ListItemText>
            <Typography sx={{textDecorationLine:"none", textDecorationColor:"none"}} component={Link} to="/payment-terms" lineHeight={2} variant="caption2">
              Paiements
            </Typography>
             
            </ListItemText>
            <ListItemText>
            <Typography sx={{textDecorationLine:"none", textDecorationColor:"none"}} component={Link} to="/privacy-policy" lineHeight={2} variant="caption2">
              Confidentialité &amp; Politique
            </Typography>
            </ListItemText>
            <ListItemText>
            <Typography sx={{textDecorationLine:"none", textDecorationColor:"none"}} component={Link} to="/termes-condition" lineHeight={2} variant="caption2">
              Termes &amp; conditions
              </Typography>
            </ListItemText>
          </List>
        </Grid>
        
        <Grid item md={6} lg={2}>
          <FooterTitle variant="body1">Mon compte</FooterTitle>
          <List>
            <ListItemText >
              <Typography sx={{textDecorationLine:"none", textDecorationColor:"none"}} component={Link} to="/login" lineHeight={2} variant="caption2">
                Login
              </Typography>
            </ListItemText>
            
            <ListItemText>
              <Typography sx={{textDecorationLine:"none"}} component={Link} to="/profile" lineHeight={2} variant="caption2">
              Mon Compte
              </Typography>
            </ListItemText>
          </List>
        </Grid>

        <Grid item md={6} lg={4}>
          <FooterTitle variant="body1">newsletter</FooterTitle>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Stack>
            <SubscribeTf
              color="primary"
              label="Adresse e-mail"
              variant="standard"
              error={!isValidEmail(emailValue) ? true : false}
              value={emailValue}
              onChange={(e) => handleChange(e)}
              helperText={!isValidEmail(emailValue) && emailErrorMessage}
            />
            <Button
              startIcon={<SendIcon sx={{ color: Colors.white }} />}
              sx={{ mt: 4, mb: 4 , borderRadius: '10px'}}
              variant="contained"
              onClick={(event) => handleNewsLetter(event)}
            >
              Souscrire
            </Button>
          </Stack>
        </Grid>
      </Grid>
   
    
</Box>



{/* 👇️ scroll to top on button click */}


    </>
    

  );
}