import React from 'react'
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/system';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Cart from './components/cart';
import ConfirmPasswordRecovery from './components/confirmPasswordRecovery';
import ConfirmRegistration from './components/confirmRegistration';
import DisplayProduct from './components/displayProduct';
import AppDrawer from './components/drawer';
import Home from './components/home';
import Login from './components/login';
import NotFound from './components/notFound';
import PasswordRecover from './components/passwordRecover';
import Payment from './components/payment';
import Profile from './components/profile';
import AboutUs from './components/aboutUs';
import RecoveryPasswordRequest from './components/recoveryPasswordRequest';
import Register from './components/register';
import UpdatePassword from './components/updatePassword'
import Unauthorized from './components/unauthorized';
import { UIProvider } from "./context/ui";
import store from './store';
import theme from './styles/theme';
import PrivatePolicy from './components/privatePolicy';
import TermesCondition from './components/termesCondition';
import ThankYouPanel from './components/thankYouPanel';
import ModalityPayment from './components/modalityPayment';
import WomenCategory from './components/womenCategory'
import WomenAccessories from './components/womenAccessories'
import WomenBags from './components/womenBags'
import WomenLipstick from './components/womenLipstick'
import WomenMascara from './components/womenMascara';
import WomenNailPolish from './components/womenNailPolish';
import WomenParfums from './components/womenParfums';
import WomenShoes from './components/womenShoes';
import ShoesCategory from './components/shoesCategory';
import ShoesMen from './components/shoesMen';
import MenAccessories from './components/menAccessories';
import MenSoins from './components/menSoins';
import MenJeansPants from './components/menJeansPants';
import MenTshirtShirt from './components/menTshirtShirt';
import MenCategory from './components/menCategory';
import HouseCategory from './components/houseCategory';
import HouseCuisine from './components/HouseCuisine';
import HouseInterieur from './components/houseInterieur';
import ElectronicCategory from './components/electronicCategory';
import ElectronicSelfie from './components/electronicSelfie';
import ElectronicHeadphones from './components/electronicHeadphones';
import ElectronicComputers from './components/electronicComputers';
import ElectronicPhones from './components/electronicPhones';
import ElectronicCharges from './components/electronicChargers';
import BrandOne from './components/brandOne';
import BrandTwo from './components/brandTwo';
import BrandThree from './components/brandThree';
import BrandFour from './components/brandFour';
import ElectroPromo from './components/electroPromo';
import MenBoxerShorts from './components/menBoxerShorts';
import ProductSales from './components/productSales'
import SearchingPanel from './components/searchingPanel'
import NoResult from './components/noResult'
import SearchBox from "./components/search";
import WomenSales from './components/soldes/womenSales';
import ShoesSales from './components/soldes/shoesSales';
import MenSales from './components/soldes/menSales';
import ElectroSales from './components/soldes/electroSales';
import HouseSales from './components/soldes/houseSales';
import ProductPresa from './components/productPresa';


export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <Container
        disableGutters
        maxWidth="xxl"
        sx={{
          background: '#fff0',
        }}
      >
        <Provider store={store}>
        <UIProvider>
     
          <BrowserRouter>
            <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/401" element={<Unauthorized />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/confirmRegistration" element={<ConfirmRegistration/>} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/passwordRecovery" element={<RecoveryPasswordRequest/>} />
            <Route path="/passwordRecover" element={<PasswordRecover/>} />
            <Route path="/confirmPasswordRecovery" element={<ConfirmPasswordRecovery/>} />
            <Route path="/products" element={<DisplayProduct/>} />
            <Route path="/aboutUs" element={<AboutUs/>} />
           <Route path="/privacy-policy" element={<PrivatePolicy/>} />
            <Route path="/termes-condition" element={<TermesCondition/>} /> 
            <Route path="/thanks" element={<ThankYouPanel/>} />
            <Route path="/payment-terms" element={<ModalityPayment/>} />
            <Route path="/women-categories" element={<WomenCategory/>} />
            <Route path="/women-accessories" element={<WomenAccessories/>} />
            <Route path="/women-bags" element={<WomenBags/>} />
            <Route path="/women-lipstick" element={<WomenLipstick/>} />
            <Route path="/women-mascara" element={<WomenMascara/>} />
            <Route path="/women-nail-polish" element={<WomenNailPolish/>} />
            <Route path="/women-parfums" element={<WomenParfums/>} />
            <Route path="/women-shoes" element={<WomenShoes/>} />
            <Route path="/shoes-categories" element={<ShoesCategory/>} />
            <Route path="/men-shoes" element={<ShoesMen/>} />
            <Route path="/men-accessories" element={<MenAccessories/>} />
            <Route path="/men-soins" element={<MenSoins/>} />
            <Route path="/men-jeans-pants" element={<MenJeansPants/>} />
            <Route path="/men-t-shirt-shirt" element={<MenTshirtShirt/>} />
            <Route path="/men-boxer-shorts" element={<MenBoxerShorts/>} />
            <Route path="/men-categories" element={<MenCategory/>} />
            <Route path="/house-categories" element={<HouseCategory/>} />
            <Route path="/house-kitchen" element={<HouseCuisine/>} />
            <Route path="/house-in" element={<HouseInterieur/>} />
            <Route path="/electronic-categories" element={<ElectronicCategory/>} />
            <Route path="/electronic-selfie" element={<ElectronicSelfie/>} />
            <Route path="/electronic-headphones" element={<ElectronicHeadphones/>} />
            <Route path="/electronic-computers" element={<ElectronicComputers/>} />
            <Route path="/electronic-phones" element={<ElectronicPhones/>} />
            <Route path="/electronic-chargers" element={<ElectronicCharges/>} />
            <Route path="/brand-one" element={<BrandOne/>} />
            <Route path="/brand-two" element={<BrandTwo/>} />
            <Route path="/brand-three" element={<BrandThree/>} />
            <Route path="/brand-four" element={<BrandFour/>} />
            <Route path="/electro-promo" element={<ElectroPromo/>} />
            <Route path="/solde" element={<ProductSales/>} />
            <Route path="/searching" element={<SearchingPanel/>} />
            <Route path="/no-result" element={<NoResult/>} />
            <Route path="/women-sales" element={<WomenSales/>} />
            <Route path="/shoes-sales" element={<ShoesSales/>} />
            <Route path="/men-sales" element={<MenSales/>}/>
            <Route path="/electro-sales" element={<ElectroSales/>}/>
            <Route path="/house-sales" element={<HouseSales/>}/>
            <Route path="/presentation" element={<ProductPresa/>}/>
          

          </Routes>
            <AppDrawer />
            <Cart/>
            <UpdatePassword/>
            <SearchBox />
          </BrowserRouter>
         <ToastContainer />
        </UIProvider>
        </Provider>
        
      </Container>

    </ThemeProvider>
  );
}