export default class OrderProduct{
  constructor(productId,productQuantitySell,productTotalPrice,productSizeOrder){
  this.productId=productId;
  this.productQuantitySell=productQuantitySell;
  this.productTotalPrice=productTotalPrice;
  this.productSizeOrder=productSizeOrder;
 
  }
  }

