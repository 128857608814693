import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import {
  Collapse,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  styled
} from '@mui/material';
import { useState } from 'react';
import { Colors } from '../../styles/theme';
import {useNavigate,Link} from 'react-router-dom';
import { useUIContext } from '../../context/ui';
import { toast } from "react-toastify";


export default function AppDrawer() {
  const { drawerOpen, setDrawerOpen } = useUIContext();
  const [open, setOpen] = useState(false);
  const [openS, setOpenS] = useState(false);
  const [openH, setOpenH] = useState(false);
  const [openM, setOpenM] = useState(false);
  const [openE, setOpenE] = useState(false);
  const [openB, setOpenB] = useState(false);
  const navigate = useNavigate();
  const MiddleDivider = styled((props) => (
    <Divider variant="middle" {...props} />
  ))``;

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickShoes = () => {
    setOpenS(!openS);
  };

  const handleClickMen = () => {
    setOpenH(!openH);
  };

  const handleClickMaison = () => {
    setOpenM(!openM);
  };
  const handleClickElectro = () => {
    setOpenE(!openE);
  };

  const handleNavigation = (category,subCategory,title) => {
    if(category==="femme" && subCategory==="accessoire"){
      navigate("/women-accessories")
      return
    }
    if(category==="femme" && subCategory==="sac"){
      navigate("/women-bags")
      return
    }
    if(category==="femme" && subCategory==="rouge-a-levres"){
      navigate("/women-lipstick")
      return
    }
    if(category==="femme" && subCategory==="mascara"){
      navigate("/women-mascara")
      return
    }
    if(category==="femme" && subCategory==="vernie-a-ongles"){
      navigate("/women-nail-polish")
      return
    }
    if(category==="femme" && subCategory==="parfums"){
      navigate("/women-parfums")
      return
    }
    if(category==="femme" && subCategory==="chaussure"){
      navigate("/women-shoes")
      return
    }

    if(category==="homme" && subCategory==="chaussure"){
      navigate("/men-shoes")
      return
    }

    if(category==="homme" && subCategory==="accessoire"){
      navigate("/men-accessories")
      return
    }
    if(category==="homme" && subCategory==="soin"){
      navigate("/men-soins")
      return
    }
    if(category==="homme" && subCategory==="jeans-pants"){
      navigate("/men-jeans-pants")
      return
    }
    if(category==="homme" && subCategory==="t-shirt-shirt"){
      navigate("/men-t-shirt-shirt")
      return
    }
    if(category==="homme" && subCategory==="boxer-shorts"){
      navigate("/men-boxer-shorts")
      return
    }

    if(category==="maison" && subCategory==="interieur"){
      navigate("/house-in")
      return
    }
    if(category==="maison" && subCategory==="cuisine"){
      navigate("/house-kitchen")
      return
    }

    if(category==="electronique" && subCategory==="selfie"){
      navigate("/electronic-selfie")
      return
    }
    if(category==="electronique" && subCategory==="telephone"){
      navigate("/electronic-phones")
      return
    }
    if(category==="electronique" && subCategory==="ecouteur"){
      navigate("/electronic-headphones")
      return
    }
    if(category==="electronique" && subCategory==="ordinateur"){
      navigate("/electronic-computers")
      return
    }
    if(category==="electronique" && subCategory==="chargeur"){
      navigate("/electronic-chargers")
      return
    }

   
  };

  return (
    <>
      <Drawer
        sx={{ width: 300 }}
        open={drawerOpen}
        anchor={'left'}
        onClose={() => setDrawerOpen(false)}
      >
        <List>
          <ListItemButton  noWrap
           sx={{textDecorationLine:"none"}} component={Link} to="/" 
            >
            <ListItemText>237usa</ListItemText>
          </ListItemButton >
          <MiddleDivider />
          <ListItemButton  onClick={() => setDrawerOpen(false)} sx={{textDecorationLine:"none" ,color:Colors.bg_green}}  component={Link} to="/presentation" >
            <ListItemText >Back to School</ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton onClick={handleClick}>
            <ListItemText>Femmes</ListItemText>
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton  onClick={() => [handleNavigation('femme','chaussure','Femmes|Chaussures'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Chaussures" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('femme','sac','Femmes|Sacs'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Sacs" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('femme','mascara','Femmes|Mascara'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Mascara" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('femme','rouge-a-levres','Femmes|Rouge à Lèvres'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Rouge à Lèvres" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('femme','vernie-a-ongles','Femmes|Vernie à Ongles'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Vernie à Ongles" />
              </ListItemButton>
            
              <ListItemButton  onClick={() => [handleNavigation('femme','parfums','Femmes|Parfums'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Parfums" />
              </ListItemButton>
            
              <ListItemButton  onClick={() => [handleNavigation('femme','accessoire','Femmes|Accessoires'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Accessoires" />
              </ListItemButton>
            </List>
          </Collapse>
          <MiddleDivider />

          <ListItemButton onClick={handleClickShoes}>
            <ListItemText>Chaussures</ListItemText>
            {openS ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openS} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton sx={{ pl: 4 }}>
                <ListItemText  onClick={() => [handleNavigation('femme','chaussure','Chaussures|Femmes'),setDrawerOpen(false)]} primary="Femmes" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','chaussure','Chaussures|Hommes'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Hommes" />
              </ListItemButton>
            </List>
          </Collapse>
          <MiddleDivider />

          <ListItemButton onClick={handleClickMen}>
            <ListItemText>Hommes</ListItemText>
            {openH ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openH} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
            <ListItemButton  onClick={() => [handleNavigation('homme','chaussure','Hommes|Chaussures'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Chaussures" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','t-shirt-shirt','Hommes|T-Shirt&Shirt'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="T-Shirt&Shirt" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','jeans-pants','Hommes|Jeans&Pants'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Jeans&Pants" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','boxer-shorts','Hommes|Boxer&Shorts'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Boxer&Shorts" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','soin','Hommes|Soins'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Soins" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('homme','accessoire','Hommes|Accessoires'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Accessoires" />
              </ListItemButton>
            </List>
          </Collapse>
          <MiddleDivider />

          <ListItemButton onClick={handleClickElectro}>
            <ListItemText>Electroniques</ListItemText>
            {openE ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openE} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton  onClick={() => [handleNavigation('electronique','telephone','Electroniques|Téléphones'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Téléphones" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('electronique','ecouteur','Electroniques|Écouteurs/Speaker'),setDrawerOpen(false)]}  sx={{ pl: 4 }}>
                <ListItemText primary="Écouteurs/Speaker" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('electronique','selfie','Electroniques|Selfie'),setDrawerOpen(false)]}  sx={{ pl: 4 }}>
                <ListItemText primary="Selfie" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('electronique','chargeur','Electroniques|Chargeurs'),setDrawerOpen(false)]}  sx={{ pl: 4 }}>
                <ListItemText primary="Chargeurs" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('electronique','ordinateur','Electroniques|Ordinateur'),setDrawerOpen(false)]}  sx={{ pl: 4 }}>
                <ListItemText primary="Ordinateur" />
              </ListItemButton>
            </List>
          </Collapse>
          <MiddleDivider />

          <ListItemButton onClick={handleClickMaison}>
            <ListItemText>Maison</ListItemText>
            {openM ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openM} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton  onClick={() => [handleNavigation('maison','interieur','Maison|Intérieur'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Intérieur" />
              </ListItemButton>
              <ListItemButton  onClick={() => [handleNavigation('maison','cuisine','Maison|Cuisine'),setDrawerOpen(false)]} sx={{ pl: 4 }}>
                <ListItemText primary="Cuisine" />
              </ListItemButton>
            </List>
          </Collapse>
          <MiddleDivider />

          <ListItemButton  onClick={() => setDrawerOpen(false)} sx={{textDecorationLine:"none" ,color:'red'}}  component={Link} to="/solde" >
            <ListItemText >Soldes</ListItemText>
          </ListItemButton>
          <MiddleDivider />
          <ListItemButton onClick={() => setDrawerOpen(false)} sx={{textDecorationLine:"none"}} component={Link} to="/aboutUs" >
            <ListItemText>À Propos de Nous</ListItemText>
          </ListItemButton>
          <MiddleDivider />

       
        </List>
      </Drawer>
    </>
  );
}
