import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
} from '@mui/material';

import Appbar from '../appbar';

import Footer from '../footer';
import Products from '../products';
import { useLocation,useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';


import ProductService from '../../services/product.service';

export default function SearchingPanel() {
  const {state} = useLocation();
  const {searchKeyCriteria} = state; 
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    ProductService.listProductByName(searchKeyCriteria)
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate,state]);

  return (
    <>
      <Appbar />
      <Box
        display="flex"
        flexDirection="column"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center"  variant="h5">
        Vous avez recherché
        </Typography>
        <Typography align="center" fontWeight={600} variant="h5">
        "{searchKeyCriteria}"
        </Typography>
      </Box>


      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas au plus élevé</option>
              <option value={40}>Prix : Du plus élevé au plus bas</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>
      {productOutput.length===0? 
       navigate('/no-result', {
        state: {
          noFoundResult: searchKeyCriteria,
        },
      })
      :
      <>
      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}
      </>
      
      }




     

      <Footer />
    </>
  );
}
