import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';
import { authHeader } from './base.service';


const API_URL_SAVE_COUPON = BASE_API_URL + '/api/promo/coupons';
const API_URL_GET_COUPON = BASE_API_URL + '/api/promo/coupon?coupon=';

class DiscountService {

    saveCoupon(coupon) {
        return axios.post(API_URL_SAVE_COUPON, coupon, {headers: authHeader()});
    }

    getCoupon(couponCode) {
        return axios.get(API_URL_GET_COUPON+couponCode, {headers: authHeader()});
    }
}

export default new DiscountService();