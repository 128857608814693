import { configureStore } from "@reduxjs/toolkit";
import personReducer from './reducers/person';
import personPasswordReducer from './reducers/personPassword';
import cartReducer from "../slices/cartSlice";


const store = configureStore({
  reducer: {
    person: personReducer,
    cart: cartReducer,
   personPassword:personPasswordReducer,
  },

});
export default store;