
import { Colors } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';

import {
  ElectroPanelContainer,
  ElectroPanelInnerContainer,
  ElectroPanelContainerInner,
  ElectroPanelImagePresa,
  ElectroPanelTitle,
  ElectroPanelContainerInnerBorder,
  ElectroPanelPrice,
  ElectroPanelImageBottom,
} from '../../styles/electroPanel';

export default function ElectroPanel() {
  const navigate = useNavigate();

  const handleProduct = (category,subCategory,title) => {
    if(category==="electronique" && subCategory==="selfie"){
      navigate("/electronic-selfie")
      return
    }
    if(category==="electronique" && subCategory==="telephone"){
      navigate("/electronic-phones")
      return
    }
    if(category==="electronique" && subCategory==="ecouteur"){
      navigate("/electronic-headphones")
      return
    }
    if(category==="electronique" && subCategory==="ordinateur"){
      navigate("/electronic-computers")
      return
    }
    if(category==="electronique" && subCategory==="chargeur"){
      navigate("/electronic-chargers")
      return
    }

     
  };

  return (
    <div>
      <ElectroPanelContainer>
        <ElectroPanelInnerContainer maxWidth="lg">

        <ElectroPanelContainerInner>
          <ElectroPanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.blue_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('electronique','ecouteur','Electroniques|Écouteurs')} 
          >
            <ElectroPanelImagePresa src="/images/banner/black-man-with-headphone.jpg" alt="black-man-with-headphone"/>
            <ElectroPanelImageBottom
                  src="/images/banner/Black-headphone.png"
                  alt="Black-headphone"
                />
            <ElectroPanelTitle variant="h1">Écouteurs</ElectroPanelTitle>
            <ElectroPanelPrice variant="h6">A partir de</ElectroPanelPrice>
            <ElectroPanelPrice variant="h6">5000F</ElectroPanelPrice>
          </ElectroPanelContainerInnerBorder>

          <ElectroPanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.blue_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('electronique','telephone','Electroniques|Téléphones')} 
          >
            {' '}
            <ElectroPanelImagePresa
              src="/images/banner/beautiful-woman-phone.webp"
              alt="woman-phone."
            />
              <ElectroPanelImageBottom
                  src="/images/banner/Black-Iphone-mobile.png"
                  alt="Black-Iphone-mobile"
                />
            <ElectroPanelTitle variant="h1">Téléphones</ElectroPanelTitle>
            <ElectroPanelPrice variant="h6">A partir de</ElectroPanelPrice>
            <ElectroPanelPrice variant="h6">20000F</ElectroPanelPrice>
          </ElectroPanelContainerInnerBorder>
        </ElectroPanelContainerInner>

        <ElectroPanelContainerInner>
          <ElectroPanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.blue_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('electronique','selfie','Electroniques|Selfie')} 
          >
   
            <ElectroPanelImagePresa
                src="/images/banner/black-women-Ring-Light.jpg"
                alt="black-women-Ring-Light"
            />
               <ElectroPanelImageBottom
                src="/images/banner/selfie.png"
                alt="Ring-Light"
            />
            <ElectroPanelTitle variant="h1">Selfie</ElectroPanelTitle>
            <ElectroPanelPrice variant="h6">A partir de</ElectroPanelPrice>
            <ElectroPanelPrice variant="h6">5000F</ElectroPanelPrice>
          </ElectroPanelContainerInnerBorder>

          <ElectroPanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.blue_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('electronique','ordinateur','Electroniques|Ordinateurs')} 
          >
   
            <ElectroPanelImagePresa
             src="/images/banner/black-woman-computer.jpg"
             alt="black-woman-computer"
            />
             <ElectroPanelImageBottom
                  src="/images/banner/Laptop-mock-up-isolated.png"
                  alt="Laptop-mock-up-isolated"
                />
            <ElectroPanelTitle variant="h1">Laptop</ElectroPanelTitle>
            <ElectroPanelPrice variant="h6">A partir de</ElectroPanelPrice>
            <ElectroPanelPrice variant="h6">50000F</ElectroPanelPrice>
          </ElectroPanelContainerInnerBorder>
        </ElectroPanelContainerInner>
      
        </ElectroPanelInnerContainer>
      </ElectroPanelContainer>
    </div>
  );
}
