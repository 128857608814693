import * as React from 'react';
import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
  useMediaQuery,
  ListItem,
} from '@mui/material';
import { Colors } from '../../../styles/theme';

import Appbar from '../../appbar';

import Footer from '../../footer';
import Products from '../../products';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../../services/product.service';
import { SoldeList } from '../../../styles/solde';

export default function ElectroSales() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  const navigate = useNavigate();

  const [activeSaleElectroPhone, setActiveSaleElectroPhone] = useState(false);
  const handleClickSaleElectroPhone = () => {
    setActiveSaleElectroPhone(!activeSaleElectroPhone);
    setActiveSaleElectroSpeaker(false);
    setActiveSaleElectroSefie(false);
    setActiveSaleElectroPc(false);
    setActiveSaleElectroAll(false);
    setActiveSaleElectroCharger(false);

    ProductService.listProductCatAndSubAsSale('electronique', 'telephone')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleElectroSpeaker, setActiveSaleElectroSpeaker] =
    useState(false);
  const handleClickSaleElectroSpeaker = () => {
    setActiveSaleElectroSpeaker(!activeSaleElectroSpeaker);
    setActiveSaleElectroPhone(false);
    setActiveSaleElectroSefie(false);
    setActiveSaleElectroPc(false);
    setActiveSaleElectroAll(false);
    setActiveSaleElectroCharger(false);

    ProductService.listProductCatAndSubAsSale('electronique', 'ecouteur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleElectroSefie, setActiveSaleElectroSefie] = useState(false);
  const handleClickSaleElectroSefie = () => {
    setActiveSaleElectroSefie(!activeSaleElectroSefie);
    setActiveSaleElectroSpeaker(false);
    setActiveSaleElectroPhone(false);
    setActiveSaleElectroPc(false);
    setActiveSaleElectroAll(false);
    setActiveSaleElectroCharger(false);
    ProductService.listProductCatAndSubAsSale('electronique', 'selfie')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleElectroPc, setActiveSaleElectroPc] = useState(false);
  const handleClickSaleElectroPc = () => {
    setActiveSaleElectroPc(!activeSaleElectroPc);
    setActiveSaleElectroSefie(false);
    setActiveSaleElectroSpeaker(false);
    setActiveSaleElectroPhone(false);
    setActiveSaleElectroAll(false);
    setActiveSaleElectroCharger(false);

    ProductService.listProductCatAndSubAsSale('electronique', 'ordinateur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleElectroCharger, setActiveSaleElectroCharger] =
    useState(false);
  const handleClickSaleElectroCharger = () => {
    setActiveSaleElectroPc(false);
    setActiveSaleElectroSefie(false);
    setActiveSaleElectroSpeaker(false);
    setActiveSaleElectroPhone(false);
    setActiveSaleElectroAll(false);
    setActiveSaleElectroCharger(!activeSaleElectroCharger);

    ProductService.listProductCatAndSubAsSale('electronique', 'chargeur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const [activeSaleElectroAll, setActiveSaleElectroAll] = useState(false);
  const handleClickSaleElectroAll = () => {
    setActiveSaleElectroPc(false);
    setActiveSaleElectroSefie(false);
    setActiveSaleElectroSpeaker(false);
    setActiveSaleElectroPhone(false);
    setActiveSaleElectroCharger(false);
    setActiveSaleElectroAll(!activeSaleElectroAll);
    ProductService.listProductCategoryAsSale('electronique')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
  };

  const onChange = (event) => {
    const value = event.target.value;
    if(value==='50'){
      ProductService.listProductCategoryAsSale('electronique')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='60'){
      ProductService.listProductCatAndSubAsSale('electronique', 'telephone')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='70'){
      ProductService.listProductCatAndSubAsSale('electronique', 'ecouteur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='80'){
      ProductService.listProductCatAndSubAsSale('electronique', 'selfie')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='90'){
      ProductService.listProductCatAndSubAsSale('electronique', 'ordinateur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
    if(value==='100'){
      ProductService.listProductCatAndSubAsSale('electronique', 'chargeur')
      .then((response) => {
        setSelected('');
        {
          !selected && setProductOutput(response?.data);
        }
        {
          selected && setProduct(response?.data);
        }
      })
      .catch((error) => {
        navigate('/404');
      });
    }
 
 
  };


  useEffect(() => {
    window.scrollTo(0, 0);

    ProductService.listProductCategoryAsSale('electronique')
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
      <Appbar />

      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" color="red" fontWeight={600} variant="h5">
          Soldes|Electroniques
        </Typography>
      </Box>
      {matches ? (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            display="flex"
            mt={4}
            mb={5}
            justifyContent="center"
            alignItems="center"
          >
            <FormControl fullWidth>
              <NativeSelect
                onChange={onChange}
              >
                <option value={50}>All</option>
                <option value={60}>Téléphones</option>
                <option value={70}>Ecouters & Speaker</option>
                <option value={80}>Selfie</option>
                <option value={90}>Ordinateurs</option>
                <option value={100}>Chargeurs</option>
              </NativeSelect>
            </FormControl>
          </Box>
        </Container>
      ) : (
        <>
          <Box
            justifyContent="center"
            alignItems="center"
            component="nav"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <SoldeList>
              <ListItem>
                <Box
                  onClick={() => [handleClickSaleElectroPhone]}
                  style={{
                    background: activeSaleElectroPhone
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroPhone}
                    style={{
                      color: activeSaleElectroPhone
                        ? Colors.white
                        : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Téléphones
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleElectroSpeaker}
                  style={{
                    background: activeSaleElectroSpeaker
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroSpeaker}
                    style={{
                      color: activeSaleElectroSpeaker
                        ? Colors.white
                        : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Ecouters & Speaker
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleElectroSefie}
                  style={{
                    background: activeSaleElectroSefie
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroSefie}
                    style={{
                      color: activeSaleElectroSefie
                        ? Colors.white
                        : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Selfie
                  </Typography>
                </Box>
              </ListItem>
            </SoldeList>
          </Box>
          <Box
            justifyContent="center"
            alignItems="center"
            component="nav"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <SoldeList>
              <ListItem>
                <Box
                  onClick={handleClickSaleElectroPc}
                  style={{
                    background: activeSaleElectroPc
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroPc}
                    style={{
                      color: activeSaleElectroPc ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Ordinateurs
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleElectroCharger}
                  style={{
                    background: activeSaleElectroCharger
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroCharger}
                    style={{
                      color: activeSaleElectroCharger
                        ? Colors.white
                        : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    Chargeurs
                  </Typography>
                </Box>
              </ListItem>
              <ListItem>
                <Box
                  onClick={handleClickSaleElectroAll}
                  style={{
                    background: activeSaleElectroAll
                      ? Colors.primary
                      : Colors.white,
                  }}
                  sx={{
                    border: 3,
                    borderRadius: '16px',
                    borderColor: 'primary.main',
                    mx: 'auto',
                    width: 180,
                    m: 1,
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                >
                  <Typography
                    onClick={handleClickSaleElectroAll}
                    style={{
                      color: activeSaleElectroAll ? Colors.white : Colors.black,
                    }}
                    sx={{
                      textAlign: 'center',
                      fontSize: '1.1rem',
                      fontWeight: '700',
                    }}
                  >
                    All
                  </Typography>
                </Box>
              </ListItem>
            </SoldeList>
          </Box>
        </>
      )}

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

      <Footer />
    </>
  );
}
