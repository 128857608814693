import { styled } from '@mui/material/styles';
import { Box, Button, Link, Typography } from '@mui/material';
import { Colors } from '../theme';
import '@fontsource/aladin';


export const NotFoundContainer = styled(Box)(() => ({
  display: 'flex',
  //flexDirection: 'row',
 justifyContent: 'center',
 alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0px 0px',

}));

export const NotFoundInnerContainer = styled(Box)(({theme }) => ({
  display: 'flex',
  flexDirection: "column",
  justifyContent: "center",
  alignItems:"center",
  width: '90%',
  height: '90%',
  [theme.breakpoints.down('sm')]: {
    width: '98%',
    height: '98%',
    //flexDirection: 'row',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '98%',
    height: '98%',
    //flexDirection: 'column',
    alignItems: 'center',
  },
}));

export const NotFoundContent = styled(Box)(({theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems:"center",
  //maxWidth: 500,
  padding: "30px",
}));

export const NotFoundTitle = styled(Typography)(({ matches, theme }) => ({
  lineHeight: 1.5,
  fontSize: "90px",
  marginBottom: "20px",
  fontFamily: '"Aladin", "cursive"',
  [theme.breakpoints.down('sm')]: {
    fontSize: '42px',    
  }
}));

export const NotFoundDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: "3em",
  fontSize: "25px",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
    fontSize: "20px",
  },
}));

export const NotFoundImage = styled("img")(({ src, theme }) => ({
  src: `url(${src})`,
  marginTop: '50px',
  borderRadius: '10px',
  width: "300px",
  [theme.breakpoints.down("md")]: {
    width: "300px",
  
  },
  [theme.breakpoints.down("sm")]: {
    width: "200px",
  
  },
}));


 