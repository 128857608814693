import { Typography, Box } from '@mui/material';
import { Colors } from '../../styles/theme';
import Footer from '../footer';
import Appbar from '../appbar';
import { useEffect } from 'react';
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
} from '../../styles/banner';

export default function ModalityPayment() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

 
  const paymentPhoneNumber = () => {
    return (
      <ul>
        <li>MTN: 650-08-87-90</li>
        <li>Orange: 656-96-95-14</li>
      </ul>
    );
  };

  return (
    <div>
      <Appbar />
      <Box sx={{ flexDirection: 'column',mb:'1em' }}>
        <Box sx={{ mt: '1em' }}>
          <BannerTitle align="center" variant="h4">
          Modalités de Paiement
          </BannerTitle>
        </Box>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '88% 29% 20% 95% / 3% 9% 36% 4%',
              
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Quand doit-on payer la commande ?
            </Typography>
            <BannerDescription variant="subtitle">
            Payer après le placement de la commande or à la récupération de la commande.
             
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '14% 9% 40% 24% / 10% 10% 85% 50%'
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Avec quel numéro dois-je payer la commande?
            </Typography>
            <BannerDescription variant="subtitle">
            Payer la commande avec le numéro de téléphone avec lequel vous avez placé la commande.
            </BannerDescription>
          </BannerContent>
          
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '28% 13% 84% 37% / 20% 60% 59% 50%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            À quel numéro de telephone dois-je payé 237usa?
            </Typography>
            <BannerDescription variant="subtitle">
            Payer nous à l'un des numéros de téléphone suivant:
            {paymentPhoneNumber()}
            </BannerDescription>
          </BannerContent>
        </BannerContainer>



     
      </Box>

      <Footer />
    </div>
  );
}
