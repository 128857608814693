import { Colors } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';

import {
  PeoplePanelContainer,
  PeoplePanelInnerContainer,
  PeoplePanelContainerInner,
  PeoplePanelImagePresa,
  PeoplePanelTitle,
  PeoplePanelContainerInnerBorder,
  PeoplePanelPrice,
} from '../../styles/peoplePannel';

export default function PeoplePanel() {
  const navigate = useNavigate();

  const handleProduct = (category,subCategory,title) => {
    if(category==="homme" && subCategory==="accessoire"){
      navigate("/men-accessories")
      return
    }
    if(category==="homme" && subCategory==="soin"){
      navigate("/men-soins")
      return
    }
    if(category==="homme" && subCategory==="jeans-pants"){
      navigate("/men-jeans-pants")
      return
    }
    if(category==="homme" && subCategory==="t-shirt-shirt"){
      navigate("/men-t-shirt-shirt")
      return
    }
     
  };

  return (
    <div>
      <PeoplePanelContainer>
        <PeoplePanelInnerContainer maxWidth="lg">
        <PeoplePanelContainerInner>
          <PeoplePanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.yellow_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('homme','t-shirt-shirt','Hommes|T-Shirt&Shirt')} 
          >
            <PeoplePanelImagePresa src="/images/banner/people3.png" />
            <PeoplePanelTitle variant="h1">T-Shirt&amp;Shirt</PeoplePanelTitle>
            <PeoplePanelPrice variant="h6">A partir de</PeoplePanelPrice>
            <PeoplePanelPrice variant="h6">5000F</PeoplePanelPrice>
          </PeoplePanelContainerInnerBorder>

          <PeoplePanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.yellow_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('homme','jeans-pants','Hommes|Jeans&Pants')} 
          >
            {' '}
            <PeoplePanelImagePresa
              src="/images/banner/people4.jpg"
              alt="Jeans"
            />
            <PeoplePanelTitle variant="h1">Jeans&amp;Pants</PeoplePanelTitle>
            <PeoplePanelPrice variant="h6">A partir de</PeoplePanelPrice>
            <PeoplePanelPrice variant="h6">6000F</PeoplePanelPrice>
          </PeoplePanelContainerInnerBorder>
        </PeoplePanelContainerInner>

        <PeoplePanelContainerInner>
          <PeoplePanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.yellow_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('homme','accessoire','Hommes|Accessoires')} 
          >
            <PeoplePanelImagePresa
              src="/images/banner/man-watch.png"
              alt="Accessoires"
            />
            <PeoplePanelTitle variant="h1">Accessoires</PeoplePanelTitle>
            <PeoplePanelPrice variant="h6">A partir de</PeoplePanelPrice>
            <PeoplePanelPrice variant="h6">4000F</PeoplePanelPrice>
          </PeoplePanelContainerInnerBorder>

          <PeoplePanelContainerInnerBorder
            sx={{
              borderRadius: '10px',
              boxShadow: 3,
              background: Colors.yellow_react,
              ':hover': {
                cursor:'pointer',
                boxShadow: 6,
                border: 1,
                borderColor: Colors.primary,
              },
            }}
            onClick={() => handleProduct('homme','soin','Hommes|Soins')} 
          >
            <PeoplePanelImagePresa
              src="/images/banner/black-Men-whal.jpg"
              alt="RASOIR ELECTRIQUE"
            />
            <PeoplePanelTitle variant="h1">Soins</PeoplePanelTitle>
            <PeoplePanelPrice variant="h6">A partir de</PeoplePanelPrice>
            <PeoplePanelPrice variant="h6">5000F</PeoplePanelPrice>
          </PeoplePanelContainerInnerBorder>
        </PeoplePanelContainerInner>

        </PeoplePanelInnerContainer>
      </PeoplePanelContainer>
    </div>
  );
}
