import { Colors } from '../../styles/theme';
import { useNavigate } from 'react-router-dom';
import {
  BeautyPanelContainer,
  BeautyPanelContainerInner, BeautyPanelContainerInnerBorder, BeautyPanelImagePresa, BeautyPanelInnerContainer, BeautyPanelPrice, BeautyPanelTitle
} from '../../styles/beautyPanel';

export default function BeautyPanel() {
  const navigate = useNavigate();

  const handleProduct = (category,subCategory,title) => {
    if(category==="femme" && subCategory==="rouge-a-levres"){
      navigate("/women-lipstick")
      return
    }
    if(category==="femme" && subCategory==="mascara"){
      navigate("/women-mascara")
      return
    }
    if(category==="femme" && subCategory==="vernie-a-ongles"){
      navigate("/women-nail-polish")
      return
    }
    if(category==="femme" && subCategory==="parfums"){
      navigate("/women-parfums")
      return
    }
     
  };

  return (
    <div>
      <BeautyPanelContainer>
        <BeautyPanelInnerContainer maxWidth="lg" >

      
        <BeautyPanelContainerInner>
          <BeautyPanelContainerInnerBorder>
            <BeautyPanelContainerInnerBorder
              sx={{
                borderRadius: '50%',
                boxShadow: 3,
                background: Colors.pastel_pink,
                ':hover': {
                  cursor:'pointer',
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
              onClick={() => handleProduct('femme','mascara','Femmes|Mascara')} 
            >
              <BeautyPanelImagePresa 
               src="/images/banner/Eye-shadows-and-lipstick.png"
               alt="lipstick"/>
            </BeautyPanelContainerInnerBorder>

            <BeautyPanelTitle variant="h1">Mascara</BeautyPanelTitle>
            <BeautyPanelPrice variant="h6">A partir de</BeautyPanelPrice>
            <BeautyPanelPrice variant="h6">3000F</BeautyPanelPrice>
          </BeautyPanelContainerInnerBorder>

          <BeautyPanelContainerInnerBorder>
            <BeautyPanelContainerInnerBorder
              sx={{
                borderRadius: '50%',
                boxShadow: 3,
                background: Colors.pastel_pink,
                ':hover': {
                  cursor:'pointer',
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
              onClick={() => handleProduct('femme','parfums','Femmes|Parfums')} 
            >
              <BeautyPanelImagePresa
               src="/images/banner/parfums.png"
               alt="parfums"
                
              />
            </BeautyPanelContainerInnerBorder>
            <BeautyPanelTitle variant="h1">Parfums</BeautyPanelTitle>
            <BeautyPanelPrice variant="h6">A partir de</BeautyPanelPrice>
            <BeautyPanelPrice variant="h6">5000F</BeautyPanelPrice>
          </BeautyPanelContainerInnerBorder>
        </BeautyPanelContainerInner>

        <BeautyPanelContainerInner>
          <BeautyPanelContainerInnerBorder>
            <BeautyPanelContainerInnerBorder
              sx={{
                borderRadius: '50%',
                boxShadow: 3,
                background: Colors.pastel_pink,
                ':hover': {
                  cursor:'pointer',
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
              onClick={() => handleProduct('femme','rouge-a-levres','Femmes|Rouge à Lèvres')} 
            >
              <BeautyPanelImagePresa
                 src="/images/banner/Lipstick-isolated.png"
                 alt="Lipstick-isolated"
              />
            </BeautyPanelContainerInnerBorder>
            <BeautyPanelTitle align='center' variant="h1">Rouge à Lèvres</BeautyPanelTitle>
            <BeautyPanelPrice variant="h6">A partir de</BeautyPanelPrice>
            <BeautyPanelPrice variant="h6">2000F</BeautyPanelPrice>
          </BeautyPanelContainerInnerBorder>

          <BeautyPanelContainerInnerBorder>
            <BeautyPanelContainerInnerBorder
              sx={{
                borderRadius: '50%',
                boxShadow: 3,
                justifyContent:'center',
                background: Colors.pastel_pink,
                ':hover': {
                  cursor:'pointer',
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
              onClick={() => handleProduct('femme','vernie-a-ongles','Femmes|Vernie à Ongles')} 
            >
              <BeautyPanelImagePresa
                src="/images/banner/Colorful-splash-nail-polish.png"
                alt="Vernie à ongle"
              />
            </BeautyPanelContainerInnerBorder>

            <BeautyPanelTitle align='center' variant="h1">Vernie à Ongles</BeautyPanelTitle>
            
            <BeautyPanelPrice variant="h6">A partir de</BeautyPanelPrice>
            <BeautyPanelPrice variant="h6">1000F</BeautyPanelPrice>
          </BeautyPanelContainerInnerBorder>
        </BeautyPanelContainerInner>
        </BeautyPanelInnerContainer>
      </BeautyPanelContainer>
    </div>
  );
}
