import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';


const BASE_URL = BASE_API_URL + '/api/authentication';


class AuthenticationService {

    login(person) {
        return axios.post(BASE_URL + '/sign-in', person);
    }

    register(person) {
        return axios.post(BASE_URL + '/sign-up', person);
    }

    updatePassword(person) {
        return axios.post(BASE_URL + '/update-pass', person);
    }

    passwordRecover(email) {
        return axios.get(BASE_URL + '/request-password-up?email='+email);
    }

    recoverPassword(person) {
        return axios.post(BASE_URL + '/recover-password-up', person);
    }

    registrationConfirmation(codeRegistration){
        return axios.get(BASE_URL + '/confirmRegistration?codeRegistration='+codeRegistration); 
    }

    passwordConfirmation(codePassword){
        return axios.get(BASE_URL + '/confirmRecovery?codePassword='+codePassword); 
    }

    generateNewCode(emailNewCode,typeCode){
        return axios.get(BASE_URL + '/generate-new-code?emailNewCode='+emailNewCode+'&typeCode='+typeCode); 
    }

}

export default new AuthenticationService();