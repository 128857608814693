import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
  List,
  ListItem,
  Button
} from '@mui/material';
import { Colors } from '../../styles/theme';

import Appbar from '../appbar';

import Footer from '../footer';
import Products from '../products';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../services/product.service';
import {SoldeList } from '../../styles/solde';

export default function SinglePageResult() {
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);

  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const [activeFemmes, setActiveFemmes] = useState(false);
  const handleClickFemmes = () => {
    setActiveShoes(false);
    setActiveMen(false);
    setActiveElectro(false);
    setActiveHouse(false);
    setActiveFemmes(!activeFemmes);
    navigate('/women-sales');
  };
  const [activeShoes, setActiveShoes] = useState(false);
  const handleClickShoes = () => {
    setActiveShoes(!activeShoes);
    setActiveMen(false);
    setActiveElectro(false);
    setActiveHouse(false);
    setActiveFemmes(false);
    navigate('/shoes-sales');
  };
  const [activeMen, setActiveMen] = useState(false);
  const handleClickMen = () => {
    setActiveShoes(false);
    setActiveMen(!activeMen);
    setActiveElectro(false);
    setActiveHouse(false);
    setActiveFemmes(false);
    navigate('/men-sales');
  };
  const [activeElectro, setActiveElectro] = useState(false);
  const handleClickElectro = () => {
    setActiveShoes(false);
    setActiveMen(false);
    setActiveElectro(!activeElectro);
    setActiveHouse(false);
    setActiveFemmes(false);
    navigate('/electro-sales');
  };
  const [activeHouse, setActiveHouse] = useState(false);
  const handleClickHouse = () => {
    setActiveHouse(!activeHouse);
    setActiveShoes(false);
    setActiveMen(false);
    setActiveElectro(false);
    setActiveFemmes(false);
    navigate('/house-sales');
  };
  useEffect(() => {
    window.scrollTo(0, 0);

    ProductService.listProductSale()
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
    
      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" color="red" fontWeight={600} variant="h5">
          Soldes
        </Typography>
      </Box>
      <Box
        justifyContent="center"
        alignItems="center"
        component="nav"
        sx={{ flexGrow: 1, display: 'flex' }}
      >
        <SoldeList>
          <ListItem>
            <Box
            onClick={()=>[handleClickFemmes]}
            style={{ background: activeFemmes ? Colors.primary : Colors.white}}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              <Typography
              onClick={handleClickFemmes}
              style={{ color: activeFemmes ? Colors.white : Colors.black}}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                 
                }}
              >
                Femmes
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
            onClick={handleClickShoes}
            style={{ background: activeShoes ? Colors.primary : Colors.white}}
              sx={{
                border: 3,
                borderRadius: '16px',
                borderColor: 'primary.main',
                mx: 'auto',
                width: 150,
                m: 1,
                "&:hover": {
                  cursor: "pointer"
                }
              }}
            >
              <Typography
              onClick={handleClickShoes}
              style={{ color: activeShoes ? Colors.white : Colors.black}}
                sx={{
                  textAlign: 'center',
                  fontSize: '1.1rem',
                  fontWeight: '700',
                 
                }}
              >
              Chaussures
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
             onClick={handleClickMen}
             style={{ background: activeMen ? Colors.primary : Colors.white}}
               sx={{
                 border: 3,
                 borderRadius: '16px',
                 borderColor: 'primary.main',
                 mx: 'auto',
                 width: 150,
                 m: 1,
                 "&:hover": {
                  cursor: "pointer"
                }
               }}
             >
               <Typography
               onClick={handleClickMen}
               style={{ color: activeMen ? Colors.white : Colors.black}}
                 sx={{
                   textAlign: 'center',
                   fontSize: '1.1rem',
                   fontWeight: '700',
                  
                 }}
               >
              Hommes
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            {' '}
            <Box
               onClick={handleClickElectro}
               style={{ background: activeElectro ? Colors.primary : Colors.white}}
                 sx={{
                   border: 3,
                   borderRadius: '16px',
                   borderColor: 'primary.main',
                   mx: 'auto',
                   width: 150,
                   m: 1,
                   "&:hover": {
                    cursor: "pointer"
                  }
                 }}
               >
                 <Typography
                 onClick={handleClickElectro}
                 style={{ color: activeElectro ? Colors.white : Colors.black}}
                   sx={{
                     textAlign: 'center',
                     fontSize: '1.1rem',
                     fontWeight: '700',
                    
                   }}
                 >
              Electroniques
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box
              onClick={handleClickHouse}
              style={{ background: activeHouse ? Colors.primary : Colors.white}}
                sx={{
                  border: 3,
                  borderRadius: '16px',
                  borderColor: 'primary.main',
                  mx: 'auto',
                  width: 150,
                  m: 1,
                  "&:hover": {
                    cursor: "pointer"
                  }
                }}
              >
                <Typography
                onClick={handleClickHouse}
                style={{ color: activeHouse ? Colors.white : Colors.black}}
                  sx={{
                    textAlign: 'center',
                    fontSize: '1.1rem',
                    fontWeight: '700',
                   
                  }}
                >
              Maison
              </Typography>
            </Box>
          </ListItem>
        </SoldeList>
      </Box>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>
        </Box>
      </Container>

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

   
    </>
  );
}
