import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';
import { authHeader } from './base.service';


const API_URL_PLACE_ORDER = BASE_API_URL + '/api/order/orders';

const API_URL_GET_ORDER = BASE_API_URL + '/api/order/order?personEmail=';


class OrderService {

    placeOrder(order) {
        return axios.post(API_URL_PLACE_ORDER, order, {headers: authHeader()});
    }

    getOrderByPerson(email) {
        return axios.get(API_URL_GET_ORDER+email, {headers: authHeader()});
    }

  
}

export default new OrderService();