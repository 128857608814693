import {
  Box,
  FormControl,
  Container,
  CssBaseline,
  NativeSelect,
  Typography,
} from '@mui/material';
import { Colors } from '../../styles/theme';
import Appbar from '../appbar';

import Footer from '../footer';
import Products from '../products';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../services/product.service';

export default function BrandOne() {
  const [product, setProduct] = useState([]);
  const [productOutput, setProductOutput] = useState([]);


  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
    ProductService.listProductByName("Suslo Couture")
      .then((response) => {
        setProductOutput(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    if (selected === '10') {
      productOutput.sort((a, b) => {
        let fa = a.productName.toLowerCase(),
          fb = b.productName.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      setProduct(productOutput);
    } else if (selected === '20') {
      productOutput.sort((a, b) =>
        a.productName.toLowerCase() > b.productName.toLowerCase() ? -1 : 1
      );

      setProduct(productOutput);
    } else if (selected === '30') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? 1 : -1));

      setProduct(productOutput);
    } else if (selected === '40') {
      productOutput.sort((a, b) => (a.productPrice > b.productPrice ? -1 : 1));

      setProduct(productOutput);
    }
  }, [selected, navigate]);

  return (
    <>
      <Appbar />
      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
        <Typography align="center" fontWeight={600} variant="h5">
        Suslo Couture
        </Typography>
      </Box>
      <Box sx={{display: "flex",
  justifyContent: "center",

  alignItems: "center",
  padding: "20px 0px 20px 0px",
  overflow: "hidden",
  background: Colors.yellow_react,
  marginBottom: "10px",}}>
      <Container component="main" maxWidth="lg" sx={{mb:'10px'}}>
        <CssBaseline />
      <Typography align="center"  variant="body1">
      Suslo Couture est une marque américaine de vêtements de haute qualité extensibles pour hommes, coupe slim, qui feront la différence dans la façon dont vous vous habillez et augmenteront votre sex-appeal.
      Suslo Couture a été fondée il y a douze ans par les frères Halabieh ; Elie, Jack et Albert.
        </Typography>
        <Typography align="center"  variant="body1">
        <a href="https://suslocouture.com/" target="_blank">www.suslosouture.com</a>
        </Typography>
        </Container>
      </Box>
    
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          display="flex"
          mt={4}
          mb={5}
          justifyContent="center"
          alignItems="center"
        >
          <FormControl fullWidth>
            <NativeSelect
              value={selected}
              onChange={(event) => setSelected(event.target.value)}
            >
              <option value="">Trier Par: Fonctionnalités</option>
              <option value={10}>Marque : A à Z</option>
              <option value={20}>Marque : Z à A</option>
              <option value={30}>Prix : Du plus bas</option>
              <option value={40}>Prix : Du plus élevé</option>
            </NativeSelect>
          </FormControl>

        </Box>
   
      </Container>
  

      {!selected && <Products products={productOutput} />}
      {selected && <Products products={product} />}

      <Footer />
    </>
  );
}
