import React from 'react';
import { Box,Stack,Tooltip,IconButton} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useDialogModal from '../../hooks/useDialogModal';
import ShareIcon from '@mui/icons-material/Share';

import {
  Product, ProductAddToCart, ProductImage, ProductActionButton,
  ProductActionsWrapper
} from '../../styles/product';
import ProductDetail from '../productDetail';
import ProductMeta from './ProductMeta';

import { addToCart, getTotals } from '../../slices/cartSlice';

export default function SingleProduct({ product, matches }) {
  const cart = useSelector((state) => state.cart);
  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(ProductDetail);
  const [showOptions, setShowOptions] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleAddToCart = (product) => {
    {product.productSize.map((size) => (
      product.productSizeOrder=size.sizeName
  ))}
    if (cart.cartItems.length === 0) {
      dispatch(addToCart(product));
    } else {
      let prod = cart.cartItems.filter(
        (el) => el.productId === product.productId
      );
      if (prod.length === 0) {
        dispatch(addToCart(product));
      } else {
        prod.forEach((item) => {
          if (item.cartQuantity >= product.productQuantity) {
            toast.error('Dépassement de la quantité de produit en stock.', {
              position: 'bottom-left',
              autoClose: 2000,
            });
          } else {
            dispatch(addToCart(product));
          }
        });
      }
    }
  };

  const handleMouseEnter = () => {
    setShowOptions(true);
  };
  const handleMouseLeave = () => {
    setShowOptions(false);
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: '10px',
          display: 'flex',
          boxShadow: 3,
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Product
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ProductImage src={'/images/products/' + product.productImageUrl} onClick={() => showProductDetailDialog()}/>
        </Product>

        <ProductMeta product={product} matches={matches} />
      
        <ProductActionsWrapper>
          <Stack direction={matches ? 'row' : 'column'}>
          
          </Stack>
        </ProductActionsWrapper>

      </Box>
      <ProductDetailDialog product={product} />
    </>
  );
}
