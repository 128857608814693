
import { IconButton, List, Typography,SvgIcon} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import '@fontsource/aladin';
import { Colors, DrawerWidth } from '../theme';

export const SoldeContainerInnerBox = styled(Box)(({theme}) => ({
  display: 'flex',
  border: `1px solid ${Colors.primary}`,
  borderRadius: '16px',
  borderColor: Colors.primary,
  textAlign: 'center',
  fontSize: '1.1rem',
  fontWeight: '700',
  mx: 'auto',
  width: 150,
  margin: 1,
  [theme.breakpoints.down('md')]: {
    
   
  },
}));

export const SoldeList = styled(List)(({ style,theme }) => ({
  display: 'flex', 
  flexDirection: 'row',
 
  [theme.breakpoints.down('md')]: {
    flexDirection: 'row',
    overflow: "hidden",
    overflowX: "scroll",
  },

  [theme.breakpoints.down('sm')]: {
   flexDirection: 'row',
   overflow: "hidden",
   overflowX: "scroll",
  },
}));