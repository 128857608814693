import {
  Dialog,
  DialogTitle,
  Slide,
  Box,
  IconButton,
  DialogContent,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import TextField from '@mui/material/TextField';
import AuthenticationService from '../../services/authentication.service';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Person from '../../models/person';
import { Colors } from '../../styles/theme';
import LockResetIcon from '@mui/icons-material/LockReset';
import Avatar from '@mui/material/Avatar';
import { useDispatch } from 'react-redux';
import { clearCurrentPerson } from '../../store/actions/personAction';



function SlideTransition(props) {
  return <Slide direction="up" {...props} />;
}



export default function UpdatePassword({ open, onClose, person }) {
  const [personNow, setPersonNow] = useState(new Person('', '', '', '',''));
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [passErrorMessage, setPassErrorMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passIsValidateErrorMessage, setPassIsValidateErrorMessage] = useState('');
  const [passIsValidateRepeatErrorMessage, setPassIsValidateRepeatErrorMessage] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');


  function isValidPassword(password) {
    const strongRegex = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})'
    );
    return strongRegex.test(password);
  }

  const passwordBulletPointMsg =() =>{
    return (
      <ul>
        <li>6 à 100 caractères</li>
        <li>Lettres majuscules et minuscules</li>
        <li>Au moins un chiffre</li>
      </ul>
    );
  }
   //<input onChange=(event => handleChange(event))>
   const handleChange = (e) => {
    personNow.email=person.email;
    personNow.password=currentPassword;
    personNow.newPassword=newPassword;
    const { name, value } = e.target.value;
    setPersonNow((prevState) => {
      //E.g: prevState ({user: abc, pass: abc}) + newKeyValue ({user: abcd}) => ({user: abcd, pass: abc})
      return {
        ...prevState,
        [name]: value,

      };
    });
  };
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    setSubmitted(true);

    //validation
    if ( !isValidPassword(repeatPassword) || !isValidPassword(newPassword) || !currentPassword) {
      setPassIsValidateErrorMessage(passwordBulletPointMsg());
      setPassIsValidateRepeatErrorMessage(passwordBulletPointMsg());
      setPassErrorMessage("L'actual mot de passe est requis");
  
      return;
    } 

    if(newPassword !== repeatPassword){
      setErrorMessage("Les nouveaux mots de passe doivent être identiques.");
      return;
    }
 
    setLoading(true);
      AuthenticationService.updatePassword(personNow).then(response => {
        //clear the session.
      dispatch(clearCurrentPerson());
      navigate('/login');
    })
    .catch((error) => {
     
      if (error?.response?.status === 404) {
        setErrorMessage("Le mot de passe actuel n'est exist pas.");
      } else {
        navigate('/401');
       // setErrorMessage("Une erreur inattendue s'est produite.");
      }
      setLoading(false);
    });
    
 
  
};

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      variant="permanant"
      open={open}
      fullScreen
    >
      <DialogTitle
        sx={
          {
            background: Colors.pastel_red,
          }
        }
      >
        <Box
          display="flex"
          alignItems="center"
       
          justifyContent={"space-between"}
          //justifyContent={'flex-end'}
        >
          <Typography variant="h5" >Nouveau Mot de Passe</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>


      </DialogTitle>
      <DialogContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginBottom: 40,
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockResetIcon />
          </Avatar>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        <Box
            component="form"
            noValidate
            onSubmit={(e) => handleUpdatePassword(e)}
            className={submitted ? 'was-validated' : ''}
            sx={{ mt: 3 }}
          >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={!currentPassword ? true : false}
              required
              fullWidth
              name="currentPassword"
             // value={person.password}
              label="Actuel Mot de Passe"
              type="password"
              id="currentPassword"
              autoComplete="actual-password"
              onChange={(e) => handleChange(setCurrentPassword(e.target.value)) }
              helperText={!currentPassword && passErrorMessage}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={!isValidPassword(newPassword) ? true : false}
              required
              fullWidth
              name="newPassword"
             // value={!newPassword}
              label="Nouveau Mot de Passe"
              type="password"
              id="newPassword"
              autoComplete="new-password"
              //onChange={(e) => handleChange(e)}
              onChange={(e) => handleChange(setNewPassword(e.target.value)) }
              helperText={!isValidPassword(newPassword) && passIsValidateErrorMessage}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              error={!isValidPassword(repeatPassword) ? true : false}
              required
              fullWidth
              name="repeatPassword"
              value={repeatPassword}
              label="Nouveau Mot de Passe"
              type="password"
              id="repeatPassword"
              autoComplete="repeat-password"
              onChange={(e) => handleChange(setRepeatPassword(e.target.value))}
              helperText={!isValidPassword(repeatPassword) && passIsValidateRepeatErrorMessage}
            />
          </Grid>
       
        </Grid>
        
        <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{ mt: 3, mb: 2, borderRadius: '10px' }}
              color="primary"
            >
              Modifier Mot De Passe
            </Button>
            </Box>
            </Box>
        </Container>
      </DialogContent>
    </Dialog>
  );
}
