import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Paper,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useState } from 'react';
import useDialogModal from '../../hooks/useDialogModal';
import { Colors } from '../../styles/theme';
import ProductDetail from '../productDetail';

import {
  OrderHistoryContent,
  OrderHistoryImage,
  OrderHistoryTitle,
} from '../../styles/orderHistory';


function SlideTransition(props) {
  return <Slide direction="up" {...props} />;
}

export default function OrderHistory({
  open,
  onClose,
  privateAddressOrder,
  orderlist,
  user,
  recupPartner,
}) {
  const [prodDetail, setProdDetail] = useState();

  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(ProductDetail);
  return (
    <Dialog
      TransitionComponent={SlideTransition}
      variant="permanant"
      open={open}
      fullScreen
    >
      <DialogContent>
        <Box display="flex" alignItems="center" justifyContent={'flex-end'}>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Stack
          justifyContent={'center'}
          direction={{ xs: 'column', sm: 'row', md: 'row' }}
          spacing={2}
        >
          <Box>
            {orderlist.map((product) => (
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <ListItem divider>
                    <OrderHistoryImage
                      src={'/images/products/' + product.productImageUrl}
                      alt={product.productName}
                      onClick={() => [
                        setProdDetail(product),
                        showProductDetailDialog(),
                      ]}
                      sx={{ cursor: 'pointer' }}
                    />
                    <ListItemText
                      direction={{ xs: 'column' }}
                      primary={product.productName.toUpperCase()}
                      secondary={
                        <React.Fragment>
                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Taille:{product.productSizeOrder}
                          </Typography>

                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Couleur:{product.productColor}
                          </Typography>

                          <Typography
                            sx={{ display: 'block' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                          >
                            Prix: {product.productQuantitySell} X{' '}
                            {product.productPrice.toLocaleString()}F
                          </Typography>

                          <Typography
                            sx={{
                              display: 'block',
                              color: Colors.pastel_red,
                            }}
                            component="span"
                            variant="body2"
                          >
                            Total:
                            {(
                              product.productQuantitySell * product.productPrice
                            ).toLocaleString()}
                            F
                          </Typography>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                </Grid>
              </Grid>
            ))}
          </Box>

          <Box>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <OrderHistoryContent
                  borderTop={10}
                  sx={{
                    background: Colors.light_gray,
                    borderRadius: '10px',
                    boxShadow: 1,
                    borderColor: Colors.primary,
                    maxWidth: 500,
                  }}
                >
                  <OrderHistoryTitle variant="h2">Envoyé à</OrderHistoryTitle>
                  <Typography variant="h7">{user.lastName}</Typography>
                  <Typography variant="h7">{user.firstName}</Typography>
                  <Typography variant="h7">{user.email}</Typography>
                  <Typography variant="h7">{user.phoneNumber}</Typography>
                </OrderHistoryContent>
              </Grid>

              <Grid item xs={12}>
          
                  <OrderHistoryContent
                    borderTop={10}
                    sx={{
                      background: Colors.light_gray,
                      borderRadius: '10px',
                      boxShadow: 1,
                      borderColor: Colors.primary,
                      maxWidth: 500,
                    }}
                  >
                    <OrderHistoryTitle variant="h2">
                      Lieu de récup:
                    </OrderHistoryTitle>
                    {recupPartner !==null?  recupPartner.partners.map((item) => (
                    <>
                        <Typography variant="h7">{item.partnerAlias}</Typography>
                        <Typography variant="h7">{item.partnerAddress}</Typography>
                        <Typography variant="h7">
                          {item.partnerPhoneNumber}
                        </Typography>
                  </>
                      )) : <Typography variant="h7">{privateAddressOrder}</Typography>}
                 
                  </OrderHistoryContent>
              
              </Grid>

              <Grid item xs={12}>
                <OrderHistoryContent
                  borderTop={10}
                  sx={{
                    background: Colors.light_gray,
                    borderRadius: '10px',
                    boxShadow: 1,
                    borderColor: Colors.primary,
                    maxWidth: 500,
                  }}
                >
                  <OrderHistoryTitle variant="h2">Payé avec</OrderHistoryTitle>
                  <Typography variant="h7">{user.phoneNumber}</Typography>
                </OrderHistoryContent>
              </Grid>
            </Grid>
          </Box>
        </Stack>
      </DialogContent>
      <ProductDetailDialog product={prodDetail} />
    </Dialog>
  );
}
