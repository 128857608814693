import { Divider, ListItemButton, Badge } from '@mui/material';
import {
  ActionIconsContainerDesktop,
  ActionIconsContainerMobile,
  MyList,
} from '../../styles/appbar';
import PersonIcon from '@mui/icons-material/Person';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Colors } from '../../styles/theme';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import { useEffect } from 'react';

import { useUIContext } from '../../context/ui';

import { getTotals } from '../../slices/cartSlice';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import { useDispatch } from 'react-redux';

export default function Actions({ matches }) {
  const navigate = useNavigate();
  const currentPerson = useSelector((state) => state.person);

  const dispatch = useDispatch();

  const Component = matches
    ? ActionIconsContainerMobile
    : ActionIconsContainerDesktop;

  const { cart, setShowCart } = useUIContext();

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

  const { cartItems, cartTotalQuantity } = useSelector((state) => state.cart);

  return (
    <Component>
      <MyList type="row">
        <ListItemButton
          sx={{
            justifyContent: 'center',
          }}
        >
          {cartItems.length > 0 && (
            <Badge
              onClick={() => setShowCart(true)}
              badgeContent={cartTotalQuantity}
              color="secondary"
            >
              <ShoppingCartIcon
                onClick={() => setShowCart(true)}
                sx={{ color: !matches ? Colors.primary : Colors.white }}
              />
            </Badge>
          )}

          {cartItems.length === 0 && (
            <Badge
              badgeContent={cartItems && cartItems.length}
              color="secondary"
            >
              <ShoppingCartOutlinedIcon
                onClick={() => setShowCart(true)}
                sx={{ color: !matches ? Colors.primary : Colors.white }}
              />
            </Badge>
          )}
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
        {matches ? (
          <ListItemButton
            button
            component="a"
            href="https://wa.me/+237699972941"
            sx={{
              justifyContent: 'center',
            }}
          >
            <WhatsAppIcon
              sx={{color:Colors.whatsappColor }}
            />
          </ListItemButton>
        ) : (
          <ListItemButton
            button
            component="a"
            href="https://wa.me/+237699972941"
            target="_blank"
            sx={{
              justifyContent: 'center',
            }}
          >
            <WhatsAppIcon
              sx={{ color:Colors.whatsappColor}}
            />
          </ListItemButton>
        )}

        <Divider orientation="vertical" flexItem />

        <ListItemButton
          sx={{
            justifyContent: 'center',
          }}
        >
          {currentPerson?.id && (
            <PersonIcon
              onClick={() => navigate('/profile')}
              sx={{ color: !matches ? Colors.primary : Colors.white }}
            />
          )}

          {!currentPerson?.id && (
            <PermIdentityOutlinedIcon
              onClick={() =>
                navigate('/login', {
                  state: {
                    login: 'login',
                  },
                })
              }
              sx={{ color: !matches ? Colors.primary : Colors.white }}
            />
          )}
        </ListItemButton>
        <Divider orientation="vertical" flexItem />
      </MyList>
    </Component>
  );
}
