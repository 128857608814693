import { Typography, Box } from '@mui/material';
import { Colors } from '../../styles/theme';
import Footer from '../footer';
import Appbar from '../appbar';
import { useEffect } from 'react';
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
} from '../../styles/banner';

export default function TermesCondition() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Appbar />
      <Box sx={{ flexDirection: 'column',mb:'1em' }}>
        <Box sx={{ mt: '1em', }}>
          <BannerTitle align="center" variant="h4">
          Termes & Conditions
          </BannerTitle>
        </Box>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '88% 29% 20% 95% / 3% 9% 36% 4%',
              
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Communication et confidentialité
            </Typography>
            <BannerDescription variant="subtitle">
            En effectuant un achat en ligne sur www.237usa.com, 
            vous acceptez automatiquement les communications de 237usa et vous vous inscrivez automatiquement entant que membre, 
            confirmant ainsi que vous acceptez que la réception de communications par e-mail soit une condition 
            de participation continue. En outre, vous acceptez que 237usa puisse vous envoyer des courriers et des 
            communications par e-mail concernant votre compte et d'autres promotions. Si vous choisissez d'interrompre 
            les communications par e-mail, vous pouvez le faire en contactant le service clientèle ou en vous 
            désinscrivant via le lien "désabonnement" sur tous les 237usa. Cependant, vous acceptez que l'interruption 
            de la communication par e-mail puisse entraîner la perte des futures offres entant que membre, car le courrier 
            électronique est la principale méthode d'émission des offres des membres autre que l'offre pour les nouveaux membres. 
            Pour plus de détails sur l'inscription à 237usa , voir ci-dessous. 
            Les informations personnelles des membres seront conservées conformément à la politique de confidentialité 
            de 237usa, disponible à l'adresse : <a href='http://237usa.com/privacy-policy'>Termes & Conditions</a> Afin de protéger l'intégrité 
            des membres, nous filtrons régulièrement les activités liées aux comptes de nos membres et vous informerons 
            à l'aide de  l'adresse e-mail que vous avez fournie en lien avec votre compte si nous remarquons une activité suspecte.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '14% 9% 40% 24% / 10% 10% 85% 50%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Annuler votre adhésion
            </Typography>
            <BannerDescription variant="subtitle">
            Vous pouvez annuler votre adhésion entant que membre à tout moment en écrivant par e-mail au service client à info@237usa.com. 
            237usa se réserve le droit d'annuler votre adhésion à sa seule discrétion.
            </BannerDescription>
          </BannerContent>
          
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '28% 13% 84% 37% / 20% 60% 59% 50%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Propriété de la propriété intellectuelle
            </Typography>
            <BannerDescription variant="subtitle">
            Toutes les marques de commerce, marques de service et noms commerciaux sur ce site, y compris, 
            sans s'y limiter, le logo ,la bande de signature, tout nom de marque, 
            sont la propriété de 237usa. Aucune utilisation de ceux-ci ne peut être faite sans préalable, 
            autorisation écrite de 237usa. Les produits, logos, dessins, publicités et images décrits 
            et/ou affichés sur ce Site peuvent faire l'objet d'autres droits de propriété intellectuelle réservés par 
            237usa ou d'autres tiers. Aucune licence n'est concédée sur ces droits de propriété intellectuelle.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
             background: Colors.white,
             borderRadius: '65% 29% 9% 20% / 26% 85% 79% 91%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Informations sur nos produits
            </Typography>
            <BannerDescription variant="subtitle">
            237usa ne peut garantir que les produits et la sélection apparaissant sur ce site seront disponibles, 
            soit par l'intermédiaire de ce site. Toutes les descriptions de produits, 
            les spécifications, les couleurs et les prix peuvent être modifiés par 237usa à tout moment sans préavis. 
            Les prix affichés sur le Site sont indiqués en fcfa et ne sont valables et effectifs 
            qu'au Cameroun. Les spécifications et paramètres techniques particuliers de votre ordinateur 
            et son affichage des couleurs des produits proposés sur 
            ce Site peuvent avoir un impact sur votre avis ou votre perception des produits.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '48% 56% 88% 71% / 5% 47% 28% 83%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Achats
            </Typography>
            <BannerDescription variant="subtitle">
            Les produits proposés à la vente et les services disponibles sur le Site sont destinés à 
            un usage personnel uniquement. Nous nous réservons le droit de limiter 
            la quantité de produits achetés et de refuser ou d'annuler toute commande qui, à notre seule discrétion, 
            violerait l'une de nos pratiques commerciales, lois ou politiques applicables.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '4% 77% 32% 82% / 55% 96% 63% 39%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Exactitude des informations
            </Typography>
            <BannerDescription variant="subtitle">
            Les informations présentées sur ce Site ont été compilées par 237usa à partir de sources internes et externes. 
            Aucune représentation n'est faite ou garantie donnée quant à l'exhaustivité ou l'exactitude de ces informations. 
            Ce site peut contenir des erreurs typographiques, des informations incomplètes ou obsolètes, y compris, 
            mais sans s'y limiter, des informations sur les prix. 237usa se réserve le droit d'apporter des modifications 
            aux informations de ce site, ou aux services qui y sont décrits, ou de mettre à jour ces informations à tout 
            moment et sans préavis (y compris après que vous avez soumis votre commande), mais 237usa ne s'engage 
            pas à corriger ou mettre à jour ces informations.Si des informations sur les prix sont inexactes, 
           237usa se réserve le droit d'annuler tout achat effectué sur la foi d'un prix inexact.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '20% 72% 73% 86% / 44% 31% 45% 55%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Indemnité
            </Typography>
            <BannerDescription variant="subtitle">
            Vous acceptez de défendre, d'indemniser et d'exonérer 237usa de toute réclamation, 
            dommage, coût et dépense, y compris les honoraires d'avocat, découlant de ou liés à votre utilisation de ce site, 
            votre accès ou votre incapacité à accéder à ce site, 
            ou de votre confiance dans toute information contenue dans ce site.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
      </Box>

      <Footer />
    </div>
  );
}
