import * as React from 'react';
import { IconButton, Slide, TextField } from '@mui/material';
import { Box, styled } from '@mui/system';
import { Colors } from '../../styles/theme';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { useUIContext } from '../../context/ui';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchBoxContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: Colors.primary,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 99999,
  opacity: 0.9,
}));

const SearchField = styled(TextField)(({ theme }) => ({
  '.MuiInputLabel-root': {
    color: Colors.secondary,
  },
  '.MuiInput-root': {
    fontSize: '1rem',
    [theme.breakpoints.up('md')]: {
      fontSize: '2rem',
    },
    color: Colors.secondary,
  },
  '.MuiInput-root::before': {
    borderBottom: `1px solid ${Colors.secondary}`,
  },
  padding: '0 0 0 40px',
}));

export default function SearchBox() {
  const { showSearchBox, setShowSearchBox } = useUIContext();
  const navigate = useNavigate();
  const [textMsg, setTextMsg] = useState('');
  const handleChange = (event) => {
    setTextMsg(event.target.value);
  };
  const handleSearch = () => {
    if (textMsg.length === 0) {
      return;
    } else {
      navigate('/searching', {
        state: {
          searchKeyCriteria: textMsg,
        },
      });
    }
    setTextMsg('');
    setShowSearchBox(false);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setTextMsg(textMsg);
      if (textMsg.length === 0) {
        return;
      } else {
        navigate('/searching', {
          state: {
            searchKeyCriteria: textMsg,
          },
        });
      }
      setTextMsg('');
      setShowSearchBox(false);
    }
  };

  return (
    <Slide direction="down" in={showSearchBox} timeout={500}>
      <SearchBoxContainer>
        <SearchField
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          value={textMsg}
          color="secondary"
          variant="standard"
          fullWidth
          placeholder="chercher..."
        />
        <IconButton>
          <SearchIcon
            sx={{ fontSize: { xs: '2rem', md: '3rem' } }}
            color="secondary"
            onClick={() => [handleSearch(), setShowSearchBox(false)]}
          />
        </IconButton>
        <IconButton
          onClick={() => setShowSearchBox(false)}
          sx={{
            position: 'absolute',
            top: 10,
            right: 10,
          }}
        >
          <CloseIcon sx={{ fontSize: '4rem' }} color="secondary" />
        </IconButton>
      </SearchBoxContainer>
    </Slide>
  );
}
