import {
  Box,

  Container,
  CssBaseline,

  Typography,
  Card,CardActionArea,CardMedia,CardContent,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import Appbar from '../appbar';

import Footer from '../footer';
import Products from '../products';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProductService from '../../services/product.service';
import { Colors } from '../../styles/theme';
import {
  TitleSeperatorContainer,
  TitleSeperatorLeftContainer,

} from '../../styles/titleSeperator';

import {
 
  CarouselContainer,
 
} from '../../styles/innerMenu';

export default function ElectroPromo() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [product, setProduct] = useState(null);
  const [productOutput, setProductOutput] = useState([]);
  const [productHeadPhones, setProductHeadPhones] = useState([]);


  const [selected, setSelected] = useState('');
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: true,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
         
          slidesToShow: 1.4,
        },
      },
    ],
  }
  useEffect(() => {
    
    window.scrollTo(0, 0);
  
    handleProduct();
    handleAllHeadPhones();
    
  }, [selected,navigate]);

  const handleProduct= () =>{
    ProductService.getProductById("1686014b-f33b-4ad5-b891-be9f94db024e")
    .then((response) => {
      setProductOutput(response?.data);
      console.log(productOutput)
    })
    .catch((error) => {
      navigate('/404');
    });
  }
  const handleAllHeadPhones= () =>{
  ProductService.listProductCatAndSub("electronique","ecouteur")
      .then((response) => {
        setProductHeadPhones(response?.data);
      })
      .catch((error) => {
        navigate('/404');
      });
    }


  const handleProductCategory = (title) => {

    if(title==="Téléphones"){
      navigate("/electronic-phones")
      return
    }
    if(title==="Écouteurs/Speaker"){
      navigate("/electronic-headphones")
      return
    }
    if(title==="Selfie"){
      navigate("/electronic-selfie")
      return
    }
    if(title==="Chargeurs"){
      navigate("/electronic-chargers")
      return
    }
    if(title==="Ordinateurs"){
      navigate("/electronic-computers")
      return
    }
  };

  
  const categoryCards = [
    {
      imageSrc: 'https://www.237usa.com/images/products/Apple-iPhone-5c-16GB-Unlocked-Pink1.png',
      title: 'Téléphones',
    },
    {
      imageSrc: 'https://www.237usa.com/images/products/Core-Innovations-Notebook-Pink1.png',
      title: 'Ordinateurs',
     
    },
    {
      imageSrc: 'https://www.237usa.com/images/products/Tommy-wirelss-headphones-2.png',
      title: 'Écouteurs/Speaker',
  
    
    },
    {
      imageSrc: 'https://www.237usa.com/images/products/Halo-Light-8-Inch-Led.png',
      title: 'Selfie',
    },
  
    {
      imageSrc: 'https://www.237usa.com/images/products/Genkent-USB-Charging-Lighting1.png',
      title: 'Chargeurs',
    },

  
  ];

  return (
    <>
      <Appbar />
      <Box
        display="flex"
        mt={3}
        mb={3}
        justifyContent="center"
        alignItems="center"
      >
          {matches ? (
              <Typography align="center" fontWeight={650} variant="h4">Offre Spéciale</Typography>
            ) : (
              <Typography align="center" fontWeight={650} variant="h3">Offre Spéciale</Typography>
            )}

      
      </Box>
      <Box sx={{display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 0px 20px 0px",
  overflow: "hidden",
  background: Colors.shaft,
  marginBottom: "10px",}}>
      <Container component="main" maxWidth="lg" sx={{mb:'10px'}}>
        <CssBaseline />
      
       <Typography align="center" color={'black'} variant="body1">
       Bande réglable et pliable pour un rangement facile.      
       </Typography>
       <Typography align="center" color={'black'} variant="body1">
        POPCLIK JUMP vient avec son étui de transport. Compatible avec Android Smartphones et PC.</Typography>
        </Container>
        </Box>
      <Products products={productOutput} />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.border }}
      >
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 2 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">Plus d'électronique.</Typography>
            ) : (
              <Typography variant="h4">Plus d'électronique.</Typography>
            )}
          </TitleSeperatorLeftContainer>
          
        </TitleSeperatorContainer>
      </Box>

      <CarouselContainer>
      <Container component="main" maxWidth="lg">
        <CssBaseline />

        <Slider ref={setSliderRef} {...sliderSettings}>
        {categoryCards.map((card, index) => (
          <div key={index} >
           <Card
        sx={{
          borderRadius: '10px',
          //background:Colors.blue_react,
          //p: 1,
          m:2
        }}
      >
        <CardActionArea>
        
        <CardMedia
            component="img"
            with="80px"
            height="250px"
            image={card.imageSrc}
            alt={card.title}
            onClick={() => handleProductCategory(card.title)}
          />
          <CardContent>
          <Typography   fontWeight={600} variant='h6'>{card.title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
          
          
          </div>
        ))}
      </Slider>

      </Container>
    </CarouselContainer>

      <Footer />
    </>
  );
}
