export default class Person{
constructor(id,username,password,email,newPassword,firstName,lastName,userName,newsLetter,photos,enabled,gender,createdDate,role,phoneNumber,accessToken){
  this.id=id;
  this.username=username;
  this.password=password;
  this.email=email;
  this.newPassword=newPassword;
  this.firstName=firstName;
  this.lastName=lastName;
  this.userName=userName;
  this.newsLetter=newsLetter;
  this.photos=photos;
  this.enabled=enabled;
  this.gender=gender;
  this.createdDate=createdDate;
  this.role=role;
  this.phoneNumber=phoneNumber;
  this.accessToken=accessToken;
}
}