import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import { useTheme } from '@mui/material/styles';
import * as React from 'react';
import DiscountService from '../../services/discount.service';
import OrderService from '../../services/order.service';
import { toast } from 'react-toastify';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  ListItemButton,
  ListItemText,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,

  TextareaAutosize,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useDialogModal from '../../hooks/useDialogModal';
import Order from '../../models/order';
import OrderProduct from '../../models/orderProduct';
import Partner from '../../models/partner';
import PartnerOrder from '../../models/partnerOrder';

import RecupService from '../../services/recup.service';
import { clearCart, getTotals } from '../../slices/cartSlice';
import {
  PaymentContainer,
  PaymentContent,
  PaymentShopButton,
  PaymentTitle,
} from '../../styles/payment';
import { Colors } from '../../styles/theme';
import Appbar from '../appbar';
import DetailPartner from '../detailPartner';
import Product from '../../models/product';
import ProductDetail from '../productDetail';

export default function Payment() {
  const [prodDetail, setProdDetail] = useState();
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const cart = useSelector((state) => state.cart);
  const [openPanier, setOpenPanier] = useState(true);
  const [openLieu, setOpenLieu] = useState(false);
  const [locationValue, setLocationValue] = useState('');
  const [deliveryValue, setDeliveryValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [reductionValue, setReductionValue] = useState('');
  const [discountCodeValue, setDiscountCodeValue] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [locationMessageError, setLocationMessageError] = useState('');
  const [deliveryMessageError, setDeliveryMessageError] = useState('');
  const [reductionMessageError, setReductionMessageError] = useState('');
  const currentPerson = useSelector((state) => state.person);
  const [recup, setRecup] = useState([]);

  const navigate = useNavigate();
  const [partnerValue, setPartnerValue] = useState(
    new Partner('', '', '', '', '', '', '', '', '', '', '', '', '', '')
  );
  const [partnerOrderValue, setPartnerOrderValue] = useState(
    new PartnerOrder('', '', '')
  );
  const [order, setOrder] = useState(
    new Order('', '', '', '', '', '', '', '', '')
  );
  const [products, setProducts] = useState([]);
  const [paymentMethodMessageError, setPaymentMethodMessageError] = useState();
  const [payementMethodValue, setPayementMethodValue] = useState();
  const [payementEvent, setPayementEvent] = useState(0);
  const [valuePersonalAddress, setValuePersonalAddres] = useState();
  const [myAddressTime, setMyAddressTime] = React.useState('');

  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(DetailPartner);

  const [
    ProductDetailShowDialog,
    showProductDetailShowDialog,
    closeProductShowDialog,
  ] = useDialogModal(ProductDetail);

  useEffect(() => {
    if (!currentPerson?.id) {
      navigate('/login');
    } else {
      RecupService.listRecup()
        .then((response) => {
          setRecup(response?.data);
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            navigate('/login');
          } else {
            navigate('/404');
          }
        });
    }
    dispatch(getTotals());
  }, [cart, dispatch]);

  const handleClickLieuAkwa = () => {
    setOpenLieu(!openLieu);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const handleClickPanier = () => {
    setOpenPanier(!openPanier);
  };

  const handleClearCart = (emailConfirmation) => {
    navigate('/thanks', {
      state: {
        emailConfirmation: emailConfirmation,
      },
    });
    dispatch(clearCart());
  };

  const handleChangePersonalAddress = (event) => {
    setLocationMessageError('');
    setReductionMessageError('');
    setValuePersonalAddres(event.target.value);
    if (event.target.value === 'personal') {
      setDeliveryValue(1000);
    } else {
      setDeliveryValue(0);
    }
  };

  function totalPrice(p1, p2) {
    return (p1 * p2).toFixed(2);
  }

  function totalPriceShow(p1, p2) {
    return (p1 * p2).toLocaleString();
  }
  const handleChangePaymentMethod = (event) => {
    setPayementMethodValue(event.target.value);
    setPaymentMethodMessageError('');
  };
  const handleChangeReduction = (event) => {
    setReductionValue(event.target.value);
    setReductionMessageError('');
  };

  const handleReduction = (event) => {
    event.preventDefault();
    if (!reductionValue) {
      setReductionMessageError('Veuillez entrer un code valide.');
      return;
    }
    DiscountService.getCoupon(reductionValue)
      .then((response) => {
        setReductionMessageError('');
        let leCoupon = response?.data?.couponValue;
        let expectedSome = response?.data?.expectedSome;
        let theEmail = response?.data?.email;
        let cartTotal = cart.cartTotalAmount.toFixed(2);

        if (isValidEmail(theEmail) && theEmail !== currentPerson?.email) {
          setReductionMessageError(
            "Le code utilisé n'est associé à votre compte"
          );
          return;
        } else if (expectedSome !== 0 && cartTotal < expectedSome) {
          setReductionMessageError(
            'La somme total de votre commande est inférieur à la somme expectée qui est de: ' +
              response.data.expectedSome +
              'F'
          );
          return;
        } else if (cartTotal < leCoupon * 2) {
          setReductionMessageError(
            'La somme total de votre commande doit-être au moins le double de votre coupon qui est de: ' +
              leCoupon +
              'F'
          );
          return;
        } else {
          setDiscountCodeValue(leCoupon);
        }
        //setLoading(true);
      })
      .catch((error) => {
        if (error.response.data.message === 'The code it is no validate.') {
          setReductionMessageError('Veuillez entrer un code actif.');
        } else if (error.response.data.message === 'The code it is expired.') {
          setReductionMessageError('Veuillez entrer un code non expiré');
        } else {
          setReductionMessageError('Veuillez entrer un code valide.');
        }
        setLoading(false);
      });
  };

  const handlePayment = (event) => {
    event.preventDefault();
    setSubmitted(true);
    //validation
    if (cart.cartItems.length === 0) {
      toast.info('Votre panier est vide.', {
        position: 'top-right',
        autoClose: 1000,
      });
      navigate('/home');
    }
    if (!payementMethodValue) {
      setPaymentMethodMessageError(
        "Veuillez entrer votre numéro de paiement MTN ou Orange Cameroun sans l' indicatif 237."
      );
      return;
    } else if (
      payementMethodValue.length < 9 ||
      payementMethodValue.length > 9
    ) {
      setPaymentMethodMessageError(
        "Veuillez entrer votre numéro de paiement MTN ou Orange Cameroun sans l' indicatif 237."
      );
      return;
    } else if (isNaN(payementMethodValue)) {
      setPaymentMethodMessageError(
        "Veuillez entrer votre numéro de paiement MTN ou Orange Cameroun sans l' indicatif 237."
      );
      return;
    } else if (!payementMethodValue.startsWith(6)) {
      setPaymentMethodMessageError(
        "Veuillez entrer votre numéro de paiement MTN ou Orange Cameroun sans l' indicatif 237."
      );
      return;
    }
   
    if (!valuePersonalAddress) {
      setLocationMessageError('Veuillez choisir un mode de récup.');
      return;
    }
    if (valuePersonalAddress === '237usa') {
      if (!locationValue) {
        setLocationMessageError(
          'Veuillez choisir un partenaire  pour la récup.'
        );
        return;
      }
    } else if (valuePersonalAddress === 'personal') {
      if (!myAddressTime) {
        setLocationMessageError(
          'Veuillez fournir plus de détail pour votre adresse de récup.'
        );
        return;
      }
    }

    order.personEmail = currentPerson?.email;
    order.deliveryType = parseInt(deliveryValue);
    order.discountCode = reductionValue;
    order.partnerCity = partnerOrderValue.cartier;
    order.partnerLocation = partnerOrderValue.province;
    order.partnerPhoneNumber = partnerOrderValue.partnerPhoneNumber;
    order.userPaymentPhoneNumber = payementMethodValue;
    order.userOrderAddress = myAddressTime;
    cart.cartItems.map((i) =>
      products.push(
        new OrderProduct(
          i.productId,
          i.cartQuantity,
          totalPrice(i.cartQuantity, i.productPrice),
          i.productSizeOrder
        )
      )
    );
    order.products = products;
   
    OrderService.placeOrder(order)
      .then((response) => {
   
        handleClearCart(response.data);
      })
      .catch((error) => {
        navigate('/404');
      });

    setLoading(true);
  };

  const handlePersonalAddressTimeChange = (event) => {
    setLocationMessageError('');
    setMyAddressTime(event.target.value);
  };
  const personalAddressContent = () => {
    return (
      <Box
        sx={{
          minWidth: 120,
          padding: 1,
          alignItems: 'center',
        }}
      >
        <Typography variant="h6">Entrée Votre Adresse</Typography>
        <Typography sx={{ mt: '1em', mb: '1em' }} variant="body1">
          La récup à votre endroit préféré coûte 1000F.
        </Typography>
        <Box
          sx={{
            padding: 1,
            alignItems: 'center',
            background: Colors.white,
          }}
        >
          <TextareaAutosize
            maxRows={10}
            // aria-label="maximum height"
            placeholder="Veuillez nous donner plus de détail sur votre adresse et l'heure disponible."
            value={myAddressTime}
            style={{
              resize: 'none',
              height: matches ? 100 : 200,
              width: matches ? 200 : 300,
            }}
            onChange={handlePersonalAddressTimeChange}
          />
        </Box>
      </Box>
    );
  };

  const cartContent = cart.cartItems.map((cartItem) => (
    <Box
      key={cartItem.productId}
      sx={{
        mt: 2,
      }}
    >
      <Card
        sx={{
          borderRadius: '10px',
          p: 1,
        }}
      >
        <CardActionArea>
          <CardMedia
            backgroundRepeat="no-repeat"
            component="img"
            with="50%"
            height="50%"
            image={'/images/products/' + cartItem.productImageUrl}
            alt={cartItem.productName}
            onClick={() => [
              setProdDetail(
                new Product(
                  cartItem?.productId,
                  cartItem?.productName,
                  cartItem?.productLineDescription,
                  cartItem?.productColor,
                  cartItem?.productImageUrl,
                  cartItem?.productPrice,
                  cartItem?.productPriceSale,
                  cartItem?.productQuantity,
                  cartItem?.productDescription,
                  cartItem?.productSize,
                  cartItem?.productImage,
                  cartItem?.category,
                  cartItem?.subCategory,
                  cartItem?.subSubCategory
                )
              ),
              showProductDetailShowDialog(),
            ]}
          />

          <CardContent>
            <Grid mt={2} container justifyContent="flex-end">
              <Typography variant="h8">
                {cartItem.cartQuantity}x{cartItem.productPrice.toLocaleString()}
                F
              </Typography>
            </Grid>

            <Typography variant="h6">
              {cartItem.productName.toUpperCase()}
            </Typography>

            <Typography variant="subtitle2">
              {cartItem.productColor}|{cartItem.productSizeOrder}
            </Typography>

            <Grid mt={2} container justifyContent="flex-end">
              <Typography color={'primary'} fontWeight="600" variant="body1">
                {totalPriceShow(cartItem.cartQuantity, cartItem.productPrice)}F
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  ));

  return (
    <div>
      <Appbar />

      <PaymentContainer>
        <Grid justifyContent={'center'} container spacing={0}>
          <Grid item sx>
            <PaymentContent>
              <Box
                borderTop={10}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  boxShadow: 1,
                  padding: '7px',
                  background: Colors.light_gray,
                  maxWidth: 400,
                }}
              >
                <PaymentTitle variant="h8">Expédition</PaymentTitle>
                <Typography
                  endIcon={<ArrowDropUpIcon />}
                  mb={1}
                  mt={2}
                  fontWeight="600"
                  variant="h6"
                >
                  Destinataire:
                </Typography>
                <Typography variant="h7">{currentPerson?.lastName}</Typography>
                <Typography variant="h7">{currentPerson?.firstName}</Typography>
                <Typography variant="h7">
                  {currentPerson?.phoneNumber}
                </Typography>
                <Typography variant="h7">{currentPerson?.email}</Typography>
              </Box>

              <Box
                mt={2}
                borderTop={10}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  boxShadow: 1,
                  padding: '7px',
                  maxWidth: 400,
                  background: Colors.light_gray,
                }}
              >
                <PaymentTitle variant="h8">Paiement</PaymentTitle>

                <Typography mb={1} mt={2} fontWeight="600" variant="h6">
                  Votre numéro de paiement:
                </Typography>
                <Grid container spacing={1}>
                  <Grid item sm>
                    <TextField
                      sx={{ background: Colors.white }}
                      id="paymentMethod"
                      onChange={(e) => handleChangePaymentMethod(e)}
                      label="MTN ou Orange numéro"
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box
                borderTop={10}
                mt={2}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  boxShadow: 1,
                  padding: '7px',
                  maxWidth: 400,
                  background: Colors.light_gray,
                }}
              >
                <PaymentTitle variant="h8">Commande</PaymentTitle>

                <ListItemButton onClick={handleClickPanier}>
                  <ListItemText>
                    <Typography fontWeight="600" variant="h6">
                      Panier({cart.cartTotalQuantity})
                    </Typography>
                  </ListItemText>
                  {openPanier ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>

                <Collapse in={openPanier} timeout="auto" unmountOnExit>
                  <List sx={{ maxHeight: '100%', overflow: 'auto' }}>
                    <Paper
                      elevation={0}
                      sx={{ mt: 2, with: '100%', padding: 4 }}
                    >
                      {cartContent}
                    </Paper>
                  </List>
                </Collapse>
              </Box>
            </PaymentContent>
          </Grid>

          <Grid item sx>
            <PaymentContent
              component="form"
              noValidate
              onSubmit={(event) => handlePayment(event)}
              className={submitted ? 'was-validated' : ''}
            >
              <Box
                borderTop={10}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  boxShadow: 1,
                  padding: '7px',
                  background: Colors.light_gray,
                  maxWidth: 500,
                }}
              >
                <PaymentTitle variant="h2">Réduction</PaymentTitle>

                <Grid container spacing={1}>
                  <Grid item sx>
                    <TextField
                      sx={{ background: Colors.white }}
                      id="reduction"
                      onChange={(e) => handleChangeReduction(e)}
                      label="Entrée le code"
                    />
                    {reductionMessageError && (
                      <Alert severity="error">{reductionMessageError}</Alert>
                    )}
                  </Grid>
                  <Grid item sx>
                    <Button
                      sx={{ borderRadius: '10px' }}
                      onClick={(event) => handleReduction(event)}
                      variant="contained"
                    >
                      Appliquer
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              <Box
                mt={2}
                mb={2}
                borderTop={10}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  boxShadow: 1,
                  padding: '7px',
                  background: Colors.light_gray,
                  maxWidth: 500,
                }}
              >
                <PaymentTitle mb={2} variant="h2">
                  Lieu de Récup
                </PaymentTitle>

                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <Typography fontWeight={600} variant="h8">
                      Où voulez vous la récup?
                    </Typography>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="personal-radio-buttons-group-label"
                    value={valuePersonalAddress}
                    name="personal-buttons-group"
                    onChange={handleChangePersonalAddress}
                  >
                    <FormControlLabel
                      value="personal"
                      control={<Radio />}
                      label="Mon Adresse"
                    />
                    <FormControlLabel
                      value="237usa"
                      control={<Radio />}
                      label="Les Partenaires 237usa"
                    />
                  </RadioGroup>
                </FormControl>
                {valuePersonalAddress === '237usa' && (
                  <>
                    <ListItemButton onClick={handleClickLieuAkwa}>
                      <ListItemText>
                        <Typography variant="h6">Les Partenaires</Typography>
                      </ListItemText>
                      {openLieu ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openLieu} timeout="auto" unmountOnExit>
                      {recup.map((key) => (
                        <Paper
                          elevation={0}
                          sx={{ mt: 2, with: '100%', padding: 3 }}
                        >
                          <FormControl component="fieldset" mt={2}>
                            <FormLabel component="legend">
                              <Typography variant="h5">
                                {key.location}({key.partners.length})
                              </Typography>
                            </FormLabel>
                            {key.partners.map((i) => (
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                //defaultValue="female"
                                name="radio-buttons-group"
                                // value={locationValue}
                                value={locationValue}
                                onChange={(e) => [
                                  setPartnerOrderValue(
                                    new PartnerOrder(
                                      key.location,
                                      key.city,
                                      i.partnerPhoneNumber
                                    )
                                  ),
                                  setLocationValue(e.target.value),
                                  setLocationMessageError(''),
                                  setReductionMessageError(''),
                                ]}
                              >
                                <Grid container spacing={1}>
                                  <Grid item sx>
                                    <FormControlLabel
                                      value={i.partnerAlias}
                                      control={<Radio />}
                                      label={i.partnerAlias}
                                      type="submit"
                                    ></FormControlLabel>
                                  </Grid>
                                  <Grid item sx>
                                    <Button
                                      endIcon={<NavigateNextIcon />}
                                      onClick={() => [
                                        setPartnerValue(
                                          new Partner(
                                            i.partnerAvatar,
                                            i.partnerGoogleMapLink,
                                            key.location,
                                            key.city,
                                            i.partnerDescription,
                                            i.partnerAddress,
                                            i.partnerFirstName,
                                            i.partnerLastName,
                                            i.partnerAlias,
                                            i.partnerPhoneNumber,
                                            i.partnerPhoto,
                                            i.partnerLocationImage,
                                            i.dayOfWeek,
                                            i.timeOfWeek,
                                            i.pertnerOffer
                                          )
                                        ),
                                        showProductDetailDialog(),
                                      ]}
                                      variant="text"
                                    >
                                      Voir détail
                                    </Button>
                                  </Grid>
                                </Grid>
                              </RadioGroup>
                            ))}
                          </FormControl>
                        </Paper>
                      ))}
                    </Collapse>
                  </>
                )}
                {valuePersonalAddress === 'personal' &&
                  personalAddressContent()}
              </Box>
              <Box
                borderTop={10}
                sx={{
                  flexDirection: 'column',
                  display: 'flex',
                  boxShadow: 1,
                  padding: '7px',
                  background: Colors.light_gray,
                  marginBottom: '10px',
                  borderRadius: '10px',
                  borderColor: Colors.primary,
                  maxWidth: 500,
                }}
              >
                <PaymentTitle variant="h8">Récapitulatif</PaymentTitle>

                <Grid mt={2} container spacing={1}>
                  <Grid item sm>
                    <Typography variant="h6">
                      Total d'articles({cart.cartTotalQuantity}):
                    </Typography>
                  </Grid>
                  <Grid item sm>
                    <Typography variant="h6">
                      {cart.cartTotalAmount.toLocaleString()}F
                    </Typography>
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={1}>
                  <Grid item sm>
                    <Typography variant="h6">Expédition:</Typography>
                  </Grid>
                  <Grid item sm>
                    {deliveryValue === 0 ? (
                      <Typography variant="h6">Gratuit</Typography>
                    ) : (
                      <Typography variant="h6">
                        {deliveryValue.toLocaleString()}F
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                {discountCodeValue !== 0 && (
                  <Grid mt={2} container spacing={1}>
                    <Grid item sm>
                      <Typography variant="h6">Réduction:</Typography>
                    </Grid>
                    <Grid item sm>
                      <Typography variant="h6">
                        {discountCodeValue.toLocaleString()}F
                      </Typography>
                    </Grid>
                  </Grid>
                )}

                {valuePersonalAddress === '237usa' && locationValue && (
                  <Grid mt={2} container spacing={1}>
                    <Grid item sm>
                      <Typography variant="h6">Lieu de Récup:</Typography>
                    </Grid>
                    <Grid item sm>
                      <Typography variant="h6">{locationValue}</Typography>
                    </Grid>
                  </Grid>
                )}
                {valuePersonalAddress === 'personal' && (
                  <Grid mt={2} container spacing={1}>
                    <Grid item sm>
                      <Typography variant="h6">Lieu de Récup:</Typography>
                    </Grid>
                    <Grid item sm>
                      <Typography variant="h6">Mon Addrese</Typography>
                    </Grid>
                  </Grid>
                )}

                <Grid mt={2} container spacing={1}>
                  <Grid item sm>
                    <Typography fontWeight="600" variant="h6">
                      Total:
                    </Typography>
                  </Grid>
                  <Grid item sm>
                    {discountCodeValue !== 0 ? (
                      <Typography fontWeight="600" variant="h6">
                        {(
                          cart.cartTotalAmount +
                          parseInt(deliveryValue) -
                          discountCodeValue
                        ).toLocaleString()}
                        F
                      </Typography>
                    ) : (
                      <Typography fontWeight="600" variant="h6">
                        {(
                          cart.cartTotalAmount + deliveryValue
                        ).toLocaleString()}
                        F
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                {locationMessageError && (
                  <Alert
                    sx={{ maxWidth: matches ? 180 : 300 }}
                    severity="error"
                  >
                    {locationMessageError}
                  </Alert>
                )}
                {deliveryMessageError && (
                  <Alert
                    sx={{ maxWidth: matches ? 180 : 300 }}
                    severity="error"
                  >
                    {deliveryMessageError}
                  </Alert>
                )}
                {paymentMethodMessageError && (
                  <Alert
                    sx={{ maxWidth: matches ? 180 : 300 }}
                    severity="error"
                  >
                    {paymentMethodMessageError}
                  </Alert>
                )}
              </Box>

              <PaymentShopButton
                type="submit"
                color="primary"
                disabled={loading}
              >
                Placer la Commande
              </PaymentShopButton>
            </PaymentContent>
          </Grid>
        </Grid>
      </PaymentContainer>
      <ProductDetailShowDialog product={prodDetail} />
      <ProductDetailDialog partner={partnerValue} />
    </div>
  );
}
