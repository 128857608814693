
import { Typography,Box} from "@mui/material";
import { ProductMetaWrapper } from "../../styles/product";

export default function ProductMeta({ product, matches }) {

    return (
      <ProductMetaWrapper>
        <Box display='flex' justifyContent={'center'} alignItems="center">
        <Typography fontWeight={600} variant={matches ? "h6" : "h5"} lineHeight={2}>
          {product.productName.toUpperCase()}
        </Typography>
        </Box>
        <Box  display='flex' justifyContent={'center'} alignItems="center">
        <Typography  variant={matches ? "caption" : "body1"}>
       {product.productLineDescription.substring(0,36)+'...'}
         
        </Typography>
        </Box>
     
        <Box mb={3}  display='flex' flexDirection={"column"} justifyContent={'center'} alignItems="center">

        {product.productPriceSale ===0 ?  
       <Typography fontWeight={600} color={'primary'} variant={matches ? "h6" : "h5"} >
          {product.productPrice.toLocaleString()}F
        </Typography>  
        :
        <>
         <Typography fontWeight={600} color={'red'} variant={matches ? "h6" : "h5"} >
         {product.productPrice.toLocaleString()}F
       </Typography>
       <Typography fontWeight={600} color={'black'} variant={matches ? "h6" : "h5"} >
       <del> {product.productPriceSale.toLocaleString()}F </del>
        </Typography>
       </>
      }
        </Box>
     
        
      </ProductMetaWrapper>
    );
}