export default class Product{
  constructor(productId,productName,productLineDescription,productColor,productImageUrl,productPrice,productPriceSale,productQuantity,productDescription,productSize,productImage,category,subCategory,subSubCategory){
 this.productId=productId;
 this.productName=productName;
  this.productLineDescription=productLineDescription;
  this.productColor=productColor;
  this.productImageUrl=productImageUrl;
  this.productPrice=productPrice;
  this.productPriceSale=productPriceSale;
  this.productQuantity=productQuantity;
  this.productDescription=productDescription; 
 this.productSize=productSize;
 this.productImage=productImage;
 this.category=category;
 this.subCategory=subCategory;
 this.subSubCategory=subSubCategory;
    
  }
  }