import * as React from 'react';
import { Typography, Box,Divider,Button } from '@mui/material';
import { Colors } from '../../styles/theme';
import Footer from '../footer';
import Appbar from '../appbar';
import { useLocation } from 'react-router-dom';
import {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
} from '../../styles/banner';

export default function ThankYouPanel() {
  const {state} = useLocation();
  const {emailConfirmation} = state; 
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [state]);
 
  const numberPayBulletPointMsg =() =>{
    return (
      <ul>
        <li>MTN: 650-08-87-90</li>
        <li>Orange: 656-96-95-14</li>
      </ul>
    );
  }
  return (
    <div>
      <Appbar />
      <Box sx={{ flexDirection: 'column',mb:'1em' }}>
        <Box sx={{ mt: '1em', }}>
          <BannerTitle align="center" variant="h4">
           Commande
          </BannerTitle>
        </Box>
        <BannerContainer >
          <BannerContent
            sx={{
              
              background: Colors.white,
              borderRadius: '88% 29% 20% 95% / 3% 9% 36% 4%',
              borderColor: 'secondary.main' ,

              border:5
            }}
          >
            <Typography mb={'1em'}  fontWeight={600} variant="h6">
            Merci d'avoir fait vos courses chez nous!
            </Typography>
            <BannerDescription variant="body1">
            Nous avons reçu votre commande! Nous vous avons envoyé un e-mail de confirmation à votre adresse e-mail : <Typography mb={'1em'}  fontWeight={600} variant="h7">{emailConfirmation}</Typography>
           
            </BannerDescription>
            <Divider variant="middle" sx={{m:'2em'}} />
            <BannerDescription variant="body1">
            Vous pouvez nous payer dès maintenant ou à la récupération de votre commande. Veuillez utiliser le numéro de téléphone associé à votre commande pour nous payer à l'un des numéros suivant
            {numberPayBulletPointMsg()}
            </BannerDescription>
            <Button  component={RouterLink} to="/profile">
            <Typography   fontWeight={600} variant="h7"> Voir ma commande</Typography>
           
            </Button>
          </BannerContent>
        </BannerContainer>
        
      </Box>

      <Footer />
    </div>
  );
}
