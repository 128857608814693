import { useTheme, Button, Box, Typography,Paper,Card,CardActionArea,CardMedia,CardContent, autocompleteClasses} from '@mui/material';
import { Colors } from '../../styles/theme';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { NavLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Slider from 'react-slick';
import * as React from 'react';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import {
 
  CarouselContainer,
 
} from '../../styles/innerMenu';

export default function CarouselPanel() {
  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState(null);

  const sliderSettings = {
    arrows: true,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
         
          slidesToShow: 1.4,
        },
      },
    ],
  }


  const categoryCards = [
    {
      imageSrc: '/images/banner/women.png',
      title: 'Femmes',
      titleSearch: 'femme',
   
    },
    {
      imageSrc: '/images/banner/shoes.png',
      title: 'Chaussures',
      titleSearch: 'chaussure',
    
    },
    {
      imageSrc: '/images/banner/men1.png',
      title: 'Hommes',
      titleSearch: 'homme',
     
    },
  
    {
      imageSrc: '/images/banner/house.png',
      title: 'Maison',
      titleSearch: 'maison',
    
    },

    {
      imageSrc: '/images/banner/electronic.png',
      title: 'Electroniques',
      titleSearch: 'electronique',
    },
  ];

  const handleProductCategory = (category,title) => {

    if(category==="femme"){
      navigate("/women-categories")
      return
    }
    if(category==="chaussure"){
      navigate("/shoes-categories")
      return
    }
    if(category==="homme"){
      navigate("/men-categories")
      return
    }
    if(category==="maison"){
      navigate("/house-categories")
      return
    }
    if(category==="electronique"){
      navigate("/electronic-categories")
      return
    }
  };
  
  return (
    <CarouselContainer>
      <Container component="main" maxWidth="lg">
        <CssBaseline />

      
        <Slider ref={setSliderRef} {...sliderSettings}>
        {categoryCards.map((card, index) => (
          <div key={index} >
           <Card
        sx={{
          borderRadius: '10px',
          //p: 1,
          m:2
        }}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            with="100%"
            height="100%"
            image={card.imageSrc}
            alt={card.title}
            onClick={() => handleProductCategory(card.titleSearch,card.title)}
          />

          <CardContent>
           

          <h2>{card.title}</h2>

        
          </CardContent>
        </CardActionArea>
      </Card>
          
          
          </div>
        ))}
      </Slider>

      </Container>
    </CarouselContainer>
  );
}
