import { useTheme } from '@mui/material/styles';
import {
  Drawer,
  useMediaQuery,
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  CardActionArea,
  CardActions,
  IconButton,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useUIContext } from '../../context/ui';
import { Colors } from '../../styles/theme';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import {
  addToCart,
  decreaseCart,
  getTotals,
} from '../../slices/cartSlice';
import useDialogModal from '../../hooks/useDialogModal';
import ProductDetail from '../productDetail';

import Product from '../../models/product';

export default function Cart() {
  const dispatch = useDispatch();
  const { setShowCart, showCart } = useUIContext();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [prodDetail, setProdDetail] = useState();

  const [currentProdSize, setCurrentProdSize] = useState();

  const [currentSizeOrder, setCurrentSizeOrder] = useState();

  const [ProductDetailDialog, showProductDetailDialog, closeProductDialog] =
    useDialogModal(ProductDetail);

  const cart = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(getTotals());
  }, [cart, dispatch]);

 
  const handleAddToCart = (product) => {
    const prod = cart.cartItems.filter((el) => el.productId === product.productId && el.productSizeOrder === product.productSizeOrder);
    prod.forEach((item) => {
      const result = item.productSize.find(({ sizeName }) => sizeName === item.productSizeOrder)
      if (item.cartQuantity>=result.sizeQuantity){
        setCurrentProdSize(result.sizeQuantity)
        setCurrentSizeOrder(result.sizeName)
        console.log(result.sizeQuantity)
        toast.error('Dépassement de la quantité du produit de taille '+item.productSizeOrder+' en stock.', {
          position: 'top-right',
          autoClose: 3000,
        });
      } else{
        dispatch(addToCart(product));
      }
  
    });
   
  };
   

  const handleDecreaseCart = (product) => {
    dispatch(decreaseCart(product));
  };

 

  function totalPrice(p1, p2) {
    return (p1 * p2).toLocaleString();
  }

  const cartContent = cart.cartItems.map((cartItem) => (

  
    <Box
      key={cartItem.productId}
      sx={{
        mt: 2,
        maxWidth:200,
      }}
    >
      <Card
        sx={{
          borderRadius: '10px',
          //p: 1,
        }}
      >
        <CardActionArea>
       
          <CardMedia
            component="img"
            sx={{flex: 1, width: '100%'}}
            image={'/images/products/' + cartItem.productImageUrl}
            alt={cartItem.productName}
            onClick={() => [setProdDetail(new Product(cartItem?.productId,cartItem?.productName,cartItem?.productLineDescription,cartItem?.productColor,cartItem?.productImageUrl,cartItem?.productPrice,cartItem?.productPriceSale,cartItem?.productQuantity,cartItem?.productDescription,cartItem?.productSize,cartItem?.productImage,cartItem?.category,cartItem?.subCategory,cartItem?.subSubCategory)),showProductDetailDialog()]}
          />

          <CardContent>
            <Grid mt={1} container justifyContent="flex-end">
              <Typography variant="h8">
                {cartItem.cartQuantity}x{cartItem.productPrice.toLocaleString()}F
              </Typography>
            </Grid>

            <Typography variant="h6">
              {cartItem.productName.toUpperCase()}
            </Typography>

            {!matches && (
              <Typography variant="subtitle2">
                {cartItem.productName.toUpperCase()}
              </Typography>
            )}
            <Grid  container justifyContent="flex-end">
              <Typography variant="body1">
                {cartItem.productColor}|{cartItem.productSizeOrder}
              </Typography>
            </Grid>
            <Grid mt={1} container justifyContent="flex-end">
              <Typography color={'primary'} fontWeight="600" variant="body1">
                {totalPrice(cartItem.cartQuantity, cartItem.productPrice)}F
              </Typography>
            </Grid>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Box
            display="flex"
            flexDirection={'row'}
            borderRadius={10}
            border={1}
          >
            <IconButton
              sx={{
                borderRadius: 0,
                height: 10,
              }}
            >
              <RemoveIcon onClick={() => handleDecreaseCart(cartItem)}>
                -
              </RemoveIcon>
            </IconButton>

            {cartItem.cartQuantity >= currentProdSize && cartItem.productSizeOrder===currentSizeOrder ? (
              <Typography
                sx={{
                  // border: `1px solid ${Colors.secondary}`,
                  justifyContent: 'center',
                  fontSize: 15,
                  height: 10,
                  mb: 1,
                }}
                variant="h8"
              >
                {currentProdSize}
              </Typography>
            ) : (
              <Typography
                sx={{
                  // border: `1px solid ${Colors.secondary}`,
                  justifyContent: 'center',
                  fontSize: 15,
                  height: 10,
                  mb: 1,
                }}
                variant="h8"
              >
                {cartItem.cartQuantity}
              </Typography>
            )}

            {cartItem.cartQuantity >= currentProdSize && cartItem.productSizeOrder===currentSizeOrder ? (
              <IconButton
                disabled
                sx={{
                  borderRadius: 0,
                  height: 10,
                }}
              >
                <AddIcon onClick={() => handleAddToCart(cartItem)}>+</AddIcon>
              </IconButton>
            ) : (
              <IconButton
                sx={{
                  borderRadius: 0,
                  height: 10,
                }}
              >
                <AddIcon onClick={() => handleAddToCart(cartItem)}>+</AddIcon>
              </IconButton>
            )}
          </Box>
        </CardActions>
      </Card>
    </Box>
    
  ));

  return (
    <Drawer
      open={showCart}
      onClose={() => setShowCart(false)}
      anchor="right"
      PaperProps={{
        sx: {
          with: matches ? '100%' : '100%',
          background: Colors.light_gray,
          borderRadius: 0,
        },
      }}
    >
      <Grid mt={2} container justifyContent="flex-end">
        <IconButton
          sx={{
            borderRadius: 0,
          }}
          alt="icon"
          fontSize="large"
          onClick={() => setShowCart(false)}
        >
          <CloseIcon />
        </IconButton>
      </Grid>

      {cart.cartItems.length > 0 ? (
        <Box
          sx={{ p: 4 }}
          display="flex"
          justifyContent={'center'}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h3" color={Colors.black}>
            Mon Panier
          </Typography>
          <Typography variant="boby2" color={Colors.black}>
            AllCacou,Stay Fashion!
          </Typography>
          <Paper elevation={0} sx={{ mt: 2, with: '90%', padding: 4 }}>
            <Typography fontWeight="600" variant="h6">
              Panier({cart.cartTotalQuantity})
            </Typography>
            {cartContent}
            <Typography sx={{ mt: 2 }} fontWeight="600" variant="h6">
              Total: {cart.cartTotalAmount.toLocaleString()}F
            </Typography>
          </Paper>
          <Button href="/payment" sx={{ mt: 4 }} variant="contained">
            Procéder au placement
          </Button>
        </Box>
      ) : (
        <Box
          sx={{ p: 4 }}
          display="flex"
          justifyContent={'center'}
          flexDirection="column"
          alignItems={'center'}
        >
          <Typography variant={matches ? 'h5' : 'h3'} color={Colors.black}>
            Votre panier est vide.
          </Typography>
        </Box>
      )}
      <Button onClick={() => [setShowCart(false)]}>Fermer</Button>
      <ProductDetailDialog  product={prodDetail} />
    </Drawer>
  );
}
