import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { Typography,Link } from '@mui/material';
import Banner from '../banner';
import Appbar from '../appbar';
import Promotions from '../promotions';
import InnerMenu from '../innerMenu';
import PeoplePannel from '../peoplePannel/index';
import BeautyPanel from '../beautyPanel';
import ElectroPanel from '../electroPanel';
import HomePanel from '../homePanel';
import Products from '../products';
import Footer from '../footer';
import Carousel from '../carouselPanel';
import { Colors } from '../../styles/theme';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ProductService from '../../services/product.service';
import { useDispatch,useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  TitleSeperatorContainer,
  TitleSeperatorLeftContainer,
  TitleSeperatorRightContainer,
} from '../../styles/titleSeperator';




export default function Home() {
  const dispatch = useDispatch();
  const [product, setProduct] = useState([]);
  const cart = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const handleProductCategory = (category,title) => {
    if(category==="femme"){
      navigate("/women-categories")
      return
    }
    if(category==="chaussure"){
      navigate("/shoes-categories")
      return
    }
    if(category==="homme"){
      navigate("/men-categories")
      return
    }
    if(category==="maison"){
      navigate("/house-categories")
      return
    }
    if(category==="electronique"){
      navigate("/electronic-categories")
      return
    }
     
  };
 
  useEffect(() => {
  
    ProductService.listProduct().then(response => {
     
      setProduct(response?.data);
    })
    .catch((error) => {
     
      if (error?.response?.status === 409) {
       
      } 
      
    });
  }, [cart, dispatch]);


  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
  
     
      <>
       <Appbar />
      <Banner />
      <Promotions />
      <InnerMenu />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.border }}
      >
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 2 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">Achetez par Catégorie.</Typography>
            ) : (
              <Typography variant="h4">Achetez par Catégorie.</Typography>
            )}
          </TitleSeperatorLeftContainer>
          
        </TitleSeperatorContainer>
      </Box>

      <Carousel />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.yellow_react }}
      >
        {' '}
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 4 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">Homme de la tête aux pieds.</Typography>
            ) : (
              <Typography variant="h4">Homme de la tête aux pieds.</Typography>
            )}
          </TitleSeperatorLeftContainer>
          <TitleSeperatorRightContainer>
            <Link sx={{cursor:"pointer"}}  underline="none" onClick={() => handleProductCategory('homme','Hommes')} >Tout découvrir {'\u2192'}</Link>
          </TitleSeperatorRightContainer>
        </TitleSeperatorContainer>
      </Box>

      <PeoplePannel />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor:  Colors.pastel_pink }}
      >
    
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 4 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">Cosmétiques pour mesdames.</Typography>
            ) : (
              <Typography variant="h4">Cosmétiques pour mesdames.</Typography>
            )}
          </TitleSeperatorLeftContainer>
          <TitleSeperatorRightContainer>
            <Link sx={{cursor:"pointer"}}  underline="none" onClick={() => handleProductCategory('femme','Femmes')} >Tout découvrir {'\u2192'}</Link>
          </TitleSeperatorRightContainer>
        </TitleSeperatorContainer>
      </Box>
      <BeautyPanel />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.pastel_green }}
      >
     
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 4 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">
                Incroyables articles pour la maison.
              </Typography>
            ) : (
              <Typography variant="h4">
                Incroyables articles pour la maison.
              </Typography>
            )}
          </TitleSeperatorLeftContainer>
          <TitleSeperatorRightContainer>
            <Link sx={{cursor:"pointer"}}  underline="none" onClick={() => handleProductCategory('maison','Maisons')} >Tout découvrir {'\u2192'}</Link>
          </TitleSeperatorRightContainer>
        </TitleSeperatorContainer>
      </Box>
      <HomePanel />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.blue_react }}
      >
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 4 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">
                Électroniques de dernière génération.
              </Typography>
            ) : (
              <Typography variant="h4">
                Électroniques de dernière génération.
              </Typography>
            )}
          </TitleSeperatorLeftContainer>
          <TitleSeperatorRightContainer>
            <Link sx={{cursor:"pointer"}}  underline="none" onClick={() => handleProductCategory('electronique','Electroniques')} >Tout découvrir {'\u2192'}</Link>
          </TitleSeperatorRightContainer>
        </TitleSeperatorContainer>
      </Box>
      <ElectroPanel />
      <Box
        maxWidth="xxl"
        sx={{ mt: 5, borderTop: 15, borderColor: Colors.pastel_red }}
      >
        <TitleSeperatorContainer maxWidth="xl" sx={{ p: 4 }}>
          <TitleSeperatorLeftContainer>
            {matches ? (
              <Typography variant="h5">
                Dernière chance.Il en reste qu' une piece.
              </Typography>
            ) : (
              <Typography variant="h4">
                Dernière chance.Il en reste qu' une piece.
              </Typography>
            )}
          </TitleSeperatorLeftContainer>
       
        </TitleSeperatorContainer>
      </Box>
{
  
 <Products products={product} />
}
     
      <Footer />
      </>
  );
}
