import * as React from 'react';
import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Footer from '../footer';
import Appbar from '../appbar';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AuthenticationService from '../../services/authentication.service';
import Alert from '@mui/material/Alert';
import { setCurrentPersonPassword } from '../../store/actions/passwordPersonAction';
import { toast } from "react-toastify";

export default function RecoveryPasswordRequest() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  
  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  //<input onChange=(event => handleChange(event))>
  const handleChange = (e) => {
    setErrorMessage('');
    setEmailValue(e.target.value);
  };

  const handlePasswordRequest = (e) => {
    e.preventDefault();
    setSubmitted(true);

    setErrorMessage('');
    //validation
    if (!isValidEmail(emailValue)) {
      setEmailErrorMessage("L'addresse email est requise");

      return;
    }
    setLoading(true);
    AuthenticationService.passwordRecover(emailValue)
      .then((response) => {
        //set user in session.
        dispatch(setCurrentPersonPassword(response.data));
        navigate("/confirmPasswordRecovery",{
         state:{
          id:emailValue
         }
        });
      })
      .catch((error) => {
      
        if (error?.response?.status === 404) {
          setErrorMessage("Il n'y a pas de compte associé à cette email.");
        } else if (error?.response?.status === 423) {
          toast.error("Ce compte n'a été pas validé. Veuillez le recréer.", {
            position: "top-right", autoClose:5000
          });
          navigate("/register")
        } else{
          navigate("/404")
        }
        setLoading(false);
      });
  };
  return (
    <>
      <Appbar />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            mb: 40,
            mt: 5,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            borderRadius: '10px',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOpenIcon />
          </Avatar>
          <Typography align="center" component="h1" variant="h5">
            Réinitialiser le Mot de Passe
          </Typography>
          <Typography mt={1} align="center"  variant="body2">
            Entrez votre email et nous vous enverrons un code pour réinitialiser
            votre mot de passe.
          </Typography>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Box
            component="form"
            noValidate
            onSubmit={(event) => handlePasswordRequest(event)}
            className={submitted ? 'was-validated' : ''}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={!isValidEmail(emailValue) ? true : false}
                  required
                  fullWidth
                  id="email"
                  value={emailValue}
                  label="Adresse E-mail"
                  name="email"
                  autoComplete="email"
                  //autoFocus
                  onChange={(e) => handleChange(e)}
                  helperText={!isValidEmail(emailValue) && emailErrorMessage}
                />
              </Grid>
              <Grid item xs={12}>
              <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{  borderRadius: '10px' }}
              color="primary"
            >
              Envoyer
            </Button>
              </Grid>
            </Grid>

     
          </Box>
        </Box>
      </Container>

      <Footer />
    </>
  );
}
