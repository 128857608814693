import { styled } from '@mui/material/styles';
import { Box,Button,List, Typography } from '@mui/material';
import { Colors } from '../theme';
import "@fontsource/aladin";

export const PaymentContainer = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0px 0px',
 marginBottom:'30px'
  //background: Colors.light_gray,


}));

export const PaymentContent = styled(Box)(({theme }) => ({
  //maxWidth: 500,
  padding: "30px",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
 // width: "100%",
}));

export const PaymentImage = styled("img")(({ src, theme }) => ({

  src: `url(${src})`,
  cursor: "pointer",
  // backgroundImage: `url(${src})`,
   backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "500px",
  height: "500px",
  [theme.breakpoints.down("md")]: {
    width: "500px",
    height: "450px",
  
  },
  [theme.breakpoints.down("sm")]: {
    width: "280px",
    height: "320px",
  },
}));
  

export const PaymentTitle = styled(Typography)(({ matches, theme }) => ({
  lineHeight: 1.5,
  fontSize: "30px",
 // marginBottom: "20px",
  fontFamily: '"Aladin", "cursive"',
  [theme.breakpoints.down('md')]: {
    fontSize: '30px',    
  }
}));

export const PaymentDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: "3em",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
  },
}));

export const PaymentShopButton = styled(Button, {

  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "color",
  name: "MyShopButton",
  slot: "Root",
  // We are specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "primary" && styles.primary,
    props.color === "secondary" && styles.secondary,
  ],
})(({ theme }) => ({
  padding: "20px 0px",
  color: Colors.white,
  fontWeight: "bold",
  fontSize: "16px",
  borderRadius: '10px',
  [theme.breakpoints.down("sm")]: {
    padding: "10px 0px",
    fontSize: "14px",
    borderRadius: '10px',
  },
}));



