import { IconButton, List, Typography,SvgIcon} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import '@fontsource/aladin';
import { Colors, DrawerWidth } from '../theme';


export const AppbarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
 // marginTop: 4,
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 8px',

}));
export const AppbarContainerSticker = styled(Box)(() => ({
  flexGrow: 1 ,
  position: 'sticky',
  top: 0,
  //width: '100%',
  justifyContent: 'center',
 alignItems: 'center',
  zIndex: 99,
 borderTop: `1px solid ${Colors.primary}`,
 background:Colors.primary
}));

export const AppbarHeader = styled(Typography)(({ theme }) => ({
  textDecoration: 0,
  alignItems: 'center',
  flexGrow: 1,
  fontSize: '3em',
  fontWeight: 'bold',
  fontFamily: '"Aladin", "cursive"',
  fontWeight: 600,
  color: Colors.secondary,
 // '&:hover': {
  //  textDecoration: 0,
    //animation: `${textPopUpTop} 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`,
 // },

  [theme.breakpoints.down('md')]: {
    fontSize: '3em',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '2em',
  },
}));

export const LogoImage = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  //width: '200px',
  width: '80px',
  height: '80px',
  
  '&:hover': {
    textDecoration: 0,
   // animation: `${textPopUpTop} 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both`,
  },

  [theme.breakpoints.down('md')]: {
    width: '30px',
    height: '30px',
  },

  [theme.breakpoints.down('sm')]: {
   // width: '100px',
   width: '80px',
    height: '30px',
  },
}));

export const ActionIconsContainerMobile = styled(Box)(() => ({
  display: 'flex',
  background: Colors.primary,
  position: 'fixed',
  bottom: 0,
  left: 0,
  width: '100%',
  alignItems: 'center',
  zIndex: 99,
  borderTop: `1px solid ${Colors.border}`,
}));

export const ActionIconsContainerDesktop = styled(Box)(() => ({
  flexGrow: 0,
}));

export const MyList = styled(List)(({ type }) => ({
  display: type === 'row' ? 'flex' : 'block',
  flexGrow: 3,

  justifyContent: 'center',
  alignItems: 'center',
}));
export const MyListTop = styled(List)(({ type }) => ({
  display: type === 'row' ? 'flex' : 'block',
  flexGrow: 3,
  justifyContent: 'right',
  alignItems: 'right',
}));
export const MyListCenter = styled(List)(({ type }) => ({
  display: type === 'row' ? 'flex' : 'block',
  flexGrow: 3,

  justifyContent: 'center',
  alignItems: 'center',
}));

export const ProfilePerButton = styled(SvgIcon)(({ isPerUp, theme }) => ({
  color: isPerUp ? Colors.pastel_red : Colors.light,  

}));

export const DrawerCloseButton = styled(IconButton)(() => ({
  //position: 'absolute',
  left: DrawerWidth,
  zIndex: 1999,
  top: 0,
 
}));

export const MenuBoxItems = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  flexDirection: 'row',
}));

export const InnerMenuBoxItems = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export const MenuBoxItemsTitle = styled(Typography)(({ matches, theme }) => ({
  lineHeight: 1.5,
  fontSize: "10px",
  marginBottom: "1em",
  fontFamily: '"Aladin", "cursive"',

}));
