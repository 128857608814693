import CloseIcon from '@mui/icons-material/Close';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  Slide,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect } from 'react';
import ReactImageMagnify from 'react-image-magnify';
import { useDispatch, useSelector } from 'react-redux';
import Slider from 'react-slick';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Colors } from '../../styles/theme';

import { addToCart } from '../../slices/cartSlice';
import { ProductImageDetail, ProductImageMobile } from '../../styles/product';

function SlideTransition(props) {
  return <Slide direction="up" {...props} />;
}

export default function ProductDetail({ open, onClose, product }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const cart = useSelector((state) => state.cart);
  const imageBaseUrl = '/images/products/';
  const [imageDetailList, setImageDetailList] = useState();
  const [sizeSelected, setSizeSelected] = useState();
  const [sizeErrorMessage, setSizeErrorMessage] = useState('');
  const [sliderRef, setSliderRef] = useState(null);
  const [buttonActivation, setButtonActivation] = useState(false);
  const [mapSize, setMapSize] = useState([]);

  useEffect(() => {
   
    const sizeFilter = product.productSize.filter(
      (size) => size.sizeQuantity !== 0
    );
    setMapSize(sizeFilter);
   
  }, [sizeSelected]);

  const sliderSettings = {
    arrows: false,
    dots: true,
    slidesToShow: 2.2,
    slidesToScroll: 1,
    infinite: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.2,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleClickOnImageDetail = (event) => {
  
    
  };

  const addItemToCart = (product) => {
    if (cart.cartItems.length === 0) {
      dispatch(addToCart(product));
    } else {
      const prod = cart.cartItems.filter(
        (el) =>
          el.productId === product.productId &&
          el.productSizeOrder === product.productSizeOrder
      );
      if (prod.length === 0) {
        dispatch(addToCart(product));
      } else {
        prod.forEach((item) => {
          const result = item.productSize.find(
            ({ sizeName }) => sizeName === item.productSizeOrder
          );
          if (item.cartQuantity >= product.productQuantity) {
            toast.error('Dépassement de la quantité de produit en stock.', {
              position: 'top-right',
              autoClose: 3000,
            });
          } else if (item.cartQuantity >= result.sizeQuantity) {
            toast.error(
              'Dépassement de la quantité du produit de taille ' +
                item.productSizeOrder +
                ' en stock.',
              {
                position: 'top-right',
                autoClose: 3000,
              }
            );
          } else {
            dispatch(addToCart(product));
          }
        });
      }
    }
  };

  const handleAddToCart = (product) => {
    if (!product.productActived) {
      toast.error(
        'Ce produit a été sollicité par bon nombres.Il est en repture de stock.',
        {
          position: 'top-right',
          autoClose: 3000,
        }
      );
      setButtonActivation(true);
      return;
    }

    if (!sizeSelected) {
      {
        matches
          ? toast.error('Veuillez choisir une taille.', {
              position: 'top-left',
              autoClose: 2000,
            })
          : setSizeErrorMessage('Veuillez choisir une taille.');
      }
    } else {
      product.productSizeOrder = sizeSelected;
      addItemToCart(product);
    }
    
  };

  const handleSizeChange = (event, newAlignment) => {
    setSizeSelected(newAlignment);
    setSizeErrorMessage('');
  };

  return (
    <Dialog
      TransitionComponent={SlideTransition}
      variant="permanant"
      open={open}
      fullScreen
    >
      <DialogTitle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent= {matches?"space-between":"flex-end"}
          //justifyContent={'flex-end'}
        >
          {matches && <Typography color={Colors.pastel_blue
          }variant='subtitle1'>Maintainez sur l'image pour zoomer</Typography>}
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      {matches ? (
        <DialogContent>
          <Box sx={{ justifyContent: 'center', maxWidth: '280px' }}>
            <Slider ref={setSliderRef} {...sliderSettings}>
              {product.productImage.map((item, index) => (
                <div key={index}>
                  {!imageDetailList && (
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: `{product.productImageUrl}`,
                          isFluidWidth: true,
                          src: `${imageBaseUrl}${item.imageName}?fit=crop&auto=format&width=632px&height=632px`,
                        },
                        largeImage: {
                          alt: '',
                          src: `${imageBaseUrl}${item.imageName}`,
                          width: 1000,
                          height: 1600,
                        },
                        enlargedImagePosition: 'over',
                        isHintEnabled: false,
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}

                  {imageDetailList && (
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: `{product.productImageUrl}`,
                          isFluidWidth: true,
                          src: `${imageBaseUrl}${imageDetailList}?fit=crop&auto=format&width=632px&height=632px`,
                        },
                        largeImage: {
                          alt: '',
                          src: `${imageBaseUrl}${imageDetailList}`,
                          width: 1200,
                          height: 1000,
                        },
                        enlargedImagePosition: 'over',
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}
                </div>
              ))}
            </Slider>
          </Box>

          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography mt={4} fontWeight={600} variant="h4">
              {product.productName.toUpperCase()}
            </Typography>
          </Box>
          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography fontSize={20} variant="body1">
              {product.productLineDescription}
            </Typography>
          </Box>

          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
         

            {product.productPriceSale ===0 ?  
       <Typography fontWeight={600} mt={2} variant="h5" >
          {product.productPrice.toLocaleString()}F
        </Typography>  
        :
        <>
         <Typography fontWeight={600} color={'red'} mt={2} variant="h5" >
         {product.productPrice.toLocaleString()}F
       </Typography>
       <Typography fontWeight={600} mt={2} variant="h5" >
       <del> {product.productPriceSale.toLocaleString()}F </del>
        </Typography>
       </>
      }
          </Box>

          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ mt: 1 }} fontSize={20} variant="body">
              Couleur: {product.productColor}
            </Typography>
          </Box>

          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ mt: 1 }} fontSize={20} variant="body">
              Taille:{sizeSelected}
            </Typography>
            <ToggleButtonGroup
              sx={{ flexWrap: 'wrap' }}
              size="large"
              exclusive
              value={sizeSelected}
              onChange={handleSizeChange}
              aria-label="Platform"
            >
              {mapSize.map((s) => (
                <ToggleButton
                  variant="scrollable"
                  scrollButtons
                  value={s.sizeName}
                >
                  {s.sizeName}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Box
            sx={{
              padding: '1px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Typography sx={{ mt: 1 }} fontSize={20} variant="h6">
              Description:
            </Typography>
            <ul>
              {product.productDescription.map((desc) => (
                <li>{desc.descName}</li>
              ))}
            </ul>
          </Box>

          <DialogActions
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <Box
              sx={{ width: '98%', borderRadius: '10px' }}
              position="absolute"
              bottom="0px"
              background={Colors.dove_gray}
            >
              <Button
                //autoFocus
                disabled={buttonActivation}
                startIcon={
                  <ShoppingBagOutlinedIcon
                    sx={{ fontSize: 600, color: Colors.white }}
                  />
                }
                sx={{ width: '98%', borderRadius: '10px' }}
                onClick={() => [handleAddToCart(product)]}
                color="primary"
                variant="contained"
              >
                Ajouter
              </Button>
            </Box>
          </DialogActions>
        </DialogContent>
      ) : (
        <>
          <DialogContent>
            <Container component="main" maxWidth="xl">
              <CssBaseline />
              <Grid container direction="row" spacing={0}>
                <Grid sx={{ width: '15%' }} item>
                  <List cols={1}>
                    {product.productImage.map((item) => (
                      <Box key={item.imageName}>
                        <ProductImageDetail
                          src={`${imageBaseUrl}${item.imageName}?fit=crop&auto=format`}
                          srcSet={`${imageBaseUrl}${item.imageName}?fit=crop&auto=format`}
                          alt={item.imageName}
                          loading="lazy"
                          onClick={(event) =>
                            handleClickOnImageDetail(
                              event,
                              setImageDetailList(item.imageName)
                            )
                          }
                        />
                      </Box>
                    ))}
                  </List>
                </Grid>
                <Grid item sx={{ width: '50%' }}>
                  {!imageDetailList && (
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: `{product.productImageUrl}`,
                          isFluidWidth: true,
                          src: `${imageBaseUrl}${product.productImageUrl}?fit=crop&auto=format`,
                        },
                        largeImage: {
                          alt: '',
                          src: `${imageBaseUrl}${product.productImageUrl}`,
                          width: 2000,
                          height: 1000,
                        },
                        enlargedImagePosition: 'over',
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}

                  {imageDetailList && (
                    <ReactImageMagnify
                      {...{
                        smallImage: {
                          alt: `{product.productImageUrl}`,
                          isFluidWidth: true,
                          src: `${imageBaseUrl}${imageDetailList}?fit=crop&auto=format`,
                        },
                        largeImage: {
                          alt: '',
                          src: `${imageBaseUrl}${imageDetailList}`,
                          width: 1200,
                          height: 1000,
                        },
                        enlargedImagePosition: 'over',
                        isHintEnabled: true,
                        shouldHideHintAfterFirstActivation: false,
                      }}
                    />
                  )}
                </Grid>
                <Grid item sx={{ width: '35%' }}>
                  <Box
                    sx={{
                      padding: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography fontWeight={600} variant="h3">
                      {product.productName.toUpperCase()}
                    </Typography>
                    <Typography fontSize={25} variant="body1">
                      {product.productLineDescription}
                    </Typography>
                   
                    {product.productPriceSale ===0 ?  
       <Typography fontWeight={600} mt={2} variant="h4" >
          {product.productPrice.toLocaleString()}F
        </Typography>  
        :
        <>
         <Typography fontWeight={600} color={'red'} mt={2} variant="h4" >
         {product.productPrice.toLocaleString()}F
       </Typography>
       <Typography fontWeight={600} mt={2} variant="h4" >
       <del> {product.productPriceSale.toLocaleString()}F </del>
        </Typography>
       </>
      }
                    <Typography fontSize={20} mt={2} variant="body1">
                      Couleur: {product.productColor}
                    </Typography>

                    <Typography fontSize={20} mt={2} variant="body1">
                      Taille:{sizeSelected}
                    </Typography>
                    {sizeErrorMessage && (
                      <Alert severity="error">{sizeErrorMessage}</Alert>
                    )}
                    <ToggleButtonGroup
                      sx={{ flexWrap: 'wrap' }}
                      size="large"
                      exclusive
                      value={sizeSelected}
                      onChange={handleSizeChange}
                      aria-label="Platform"
                    >
                      {mapSize.map((s) => (
                        <ToggleButton
                          variant="scrollable"
                          scrollButtons
                          value={s.sizeName}
                        >
                          {s.sizeName}
                        </ToggleButton>
                      ))}
                    </ToggleButtonGroup>

                    <Button
                      disabled={buttonActivation}
                      startIcon={
                        <ShoppingBagOutlinedIcon
                          sx={{ fontSize: 600, color: Colors.white }}
                        />
                      }
                      sx={{ mt: 4, mb: 4, borderRadius: '10px' }}
                      onClick={() => [handleAddToCart(product)]}
                      color="primary"
                      variant="contained"
                    >
                      Ajouter
                    </Button>
                    <Box
                      sx={{
                        padding: '1px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Typography fontSize={20} sx={{ mt: 1 }} variant="h6">
                        Description:
                      </Typography>
                      <ul>
                        {product.productDescription.map((desc) => (
                          <li>{desc.descName}</li>
                        ))}
                      </ul>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
