import store from '../store';
import axios from 'axios';
import { history } from '../common/history';

//equal to function authHeader();
export const authHeader = () => {
    const currentPerson = store.getState().person;
    
    return {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + currentPerson?.accessToken,
    };
};

export function handleResponseWithLoginCheck() {
    axios.interceptors.response.use(
        response => response,
        error => {
            const currentPerson = store.getState().person;
            const isLoggedIn = currentPerson?.accessToken;
            const status = error?.response?.status;
  
            if (isLoggedIn && [401, 403].includes(status)) {
                store.dispatch(currentPerson());
                //router?
                history.push('/login');
            }
  
            return Promise.reject(error);
        }
    );
  };



