import { CLEAR_CURRENT_PERSON_PASSWORD, SET_CURRENT_PERSON_PASSWORD } from '../actions/action-types/password-recover-action';


const personPasswordReducer = (state = {}, action) => {
    switch (action?.type) {
        case SET_CURRENT_PERSON_PASSWORD:
            localStorage.setItem('currentPersonPassword', JSON.stringify(action?.payload));
            return action?.payload;
        case CLEAR_CURRENT_PERSON_PASSWORD:
            localStorage.removeItem('currentPersonPassword');
            return null;
        default:
            return JSON.parse(localStorage.getItem('currentPersonPassword'));
    };
};

export default personPasswordReducer;
