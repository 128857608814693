import { Typography, Box } from '@mui/material';
import { Colors } from '../../styles/theme';
import Footer from '../footer';
import Appbar from '../appbar';
import { useEffect } from 'react';
import {
  BannerContainer,
  BannerContent,
  BannerDescription,
  BannerTitle,
} from '../../styles/banner';

export default function PrivatePolicy() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const collectedInfoBulletPoint = () => {
    return (
      <ul>
        <li>Passer une commande sur le Site. </li>
        <li>Retourner un article ou faire un échange. </li>
        <li>Se renseigner sur nos services.</li>
        <li>Créer un compte sur le Site.</li>
        <li>S'inscrire pour recevoir les mises à jour par e-mail de la société.</li>
        <li>Nous sont référés par le biais d'une promotion marketing.</li> 
        <li>Participer à une enquête marketing, à une promotion ou à un événement de l'entreprise.</li>
      </ul>
    );
  };
  const usa237ContactBulletPoint = () => {
    return (
      <ul>
        <li>Téléphone/Whatsapp: +237-658-68-88-95</li>
        <li>Email: info@237usa.com</li>
      </ul>
    );
  };

  return (
    <div>
      <Appbar />
      <Box sx={{ flexDirection: 'column',mb:'1em' }}>
        <Box sx={{ mt: '1em' }}>
          <BannerTitle align="center" variant="h4">
            Politique de Confidentialité
          </BannerTitle>
        </Box>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '88% 29% 20% 95% / 3% 9% 36% 4%',
              
            }}
          >
            <Typography fontWeight={600} variant="h6">
              Informations que nous recueillons et suivons
            </Typography>
            <BannerDescription variant="subtitle">
              237usa collecte et conserve deux types d'informations sur les
              visiteurs du Site : (i) des informations personnelles fournies
              volontairement par des visiteurs individuels lors de leurs
              interactions sur le Site, ou à certaines autres occasions, telles
              que via des formulaires ou des e-mails ; et (ii) les données de
              suivi, qui sont automatiquement collectées auprès de chaque
              visiteur du Site et enregistrées dans nos NewsLetters. Les
              informations que nous collectons peuvent inclure votre nom, prénom
              et adresse, numéro de téléphone et adresse e-mail,ainsi que
              d'autres éléments d'informations personnelles. En outre, nous
              pouvons conserver un enregistrement de toute correspondance, y
              compris toute information qu'elle contient.
              {usa237ContactBulletPoint()}
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '14% 9% 40% 24% / 10% 10% 85% 50%'
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Comment nous utilisons vos informations
            </Typography>
            <BannerDescription variant="subtitle">
            Les informations que nous recueillons sont utilisées à diverses fins commerciales, 
            y compris, mais sans s'y limiter, la conservation des informations sur les transactions et les commandes, 
            le traitement et le suivi des commandes, vous contacter au sujet de l'état d'une commande, 
            fournir des informations concernant les rappels de produits ou les produits que vous avez achetés , 
            répondre à vos demandes ou correspondances et trouver des moyens d'améliorer le Site. 
            Nous pouvons également utiliser vos informations pour vous informer des activités, promotions, 
            produits, services et événements à venir qui, selon nous, pourraient vous intéresser. 
            Vos informations peuvent être analysées pour nous permettre de vous servir de la 
            meilleure manière possible grâce à des caractéristiques et fonctions améliorées sur le site.
            Nous ne divulguerons aucune information personnelle à des tiers, sauf (i) aux parties qui 
            fournissent un service pour nous, tels que les fournisseurs de services de messagerie, 
            l'hébergement Web, le traitement des cartes de crédit, les services d'expédition et 
            d'autres services similaires ; (ii) lorsque nous estimons qu'une telle divulgation est 
            requise par la loi ; (iii) pour faire respecter la présente politique ou les conditions 
            d'utilisation ; (iv) pour protéger les droits, la propriété, la sécurité ou la sûreté de la Société, 
            des utilisateurs du Site ou du public ; (v) pour répondre à une urgence ; ou (vi) 
            comme indiqué autrement dans la présente politique. En outre, nous pouvons divulguer des informations 
            à certains tiers à votre demande ou autorisation.Nous fournissons certaines informations à 
            des prestataires de services tiers qui exécutent des fonctions spécifiques en notre nom. 
            Certains fournisseurs de services (c'est-à-dire l'hébergement Web, le traitement des cartes de crédit, 
            l'expédition, le suivi des commandes, la gestion du catalogue et autres) 
            peuvent avoir accès à tout ou partie des informations que nous conservons. 
            Toutes ces informations fournies à des tiers sont limitées aux informations nécessaires à l'exécution de 
            leurs fonctions. Nous cherchons à limiter l'utilisation des informations par des tiers ; 
            cependant, nous ne sommes pas responsables de l'utilisation des informations par des tiers non 
            conformes à la présente politique.
            </BannerDescription>
          </BannerContent>
          
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '28% 13% 84% 37% / 20% 60% 59% 50%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Utilisation des cookies
            </Typography>
            <BannerDescription variant="subtitle">
            Nous utilisons des cookies pour améliorer votre expérience lors de l'utilisation du site. 
            Les « cookies » sont de petits éléments d'information qu'un site Web transfère sur le disque dur de 
            votre ordinateur à des fins d'archivage. Les cookies facilitent la navigation sur le Web en exécutant 
            certaines fonctions, telles que l'enregistrement des mots de passe et des préférences personnelles, 
            en relation avec votre utilisation d'un site Web particulier. Votre navigateur est très probablement 
            configuré pour accepter les cookies, mais vous êtes toujours libre de refuser nos cookies si votre 
            navigateur le permet. Si vous choisissez de paramétrer votre navigateur pour interdire les cookies, 
            il est possible que certains des Services fournis sur le Site ne fonctionnent pas correctement. 
            Nous pouvons également conclure des contrats avec d'autres sociétés qui utilisent des cookies ou 
            d'autres outils en ligne tels que des balises pixel pour mesurer la performance d'un effort marketing 
            en notre nom. Nous interdisons à ces sociétés d'utiliser ces informations pour leur propre compte ou 
            de partager ces informations avec quiconque autre que la Société.
            Transfert d'informations à des tiers lors de la vente ou du changement de contrôle
            Nous nous réservons le droit de transférer ou de céder toutes les informations dans 
            le cadre d'une fusion, d'une vente, d'une réorganisation, d'un achat ou d'une autre 
            transaction relative à la société, ses sociétés affiliées ou leurs actifs, si le cessionnaire ou 
            l'acheteur accepte de respecter les termes de la présente politique.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
             background: Colors.white,
             borderRadius: '65% 29% 9% 20% / 26% 85% 79% 91%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Protection des informations
            </Typography>
            <BannerDescription variant="subtitle">
            Nous prenons des précautions raisonnables pour protéger vos informations contre la perte, 
            l'utilisation abusive ou l'altération. Nous maintenons des protections physiques, électroniques et 
            procédurales conformes aux réglementations pour protéger vos informations non publiques. 
            Vous devez cependant reconnaître qu'il n'existe pas de "sécurité parfaite" sur Internet et nous ne pouvons 
            pas garantir la sécurité de vos informations. En raison de la nature non sécurisée des communications Internet, 
            il est possible que les informations qui nous sont fournies ou les informations auxquelles vous accédez via 
            votre utilisation du Site soient interceptées pendant la transmission. Nous ne sommes pas responsables de 
            toute violation de nos systèmes ou de l'interception de notre transmission, et nous déclinons par la présente, 
            dans toute la mesure prévue par la loi, toute responsabilité associée à toute 
            réclamation relative à une violation de la sécurité, des données ou des informations.
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
        <BannerContainer >
          <BannerContent
            sx={{
              background: Colors.white,
              borderRadius: '48% 56% 88% 71% / 5% 47% 28% 83%',
            }}
          >
            <Typography fontWeight={600} variant="h6">
            Mettre à jour ou corriger vos informations et contacter 237usa
            </Typography>
            <BannerDescription variant="subtitle">
            Veuillez contacter notre service clientèle si vous avez des questions ou des préoccupations concernant le site, 
            cette politique ou si vous souhaitez mettre à jour ou corriger vos informations. 
            Veuillez envoyer vos questions ou commentaires concernant ce Site à :
              {usa237ContactBulletPoint()}
            </BannerDescription>
          </BannerContent>
        </BannerContainer>
      </Box>

      <Footer />
    </div>
  );
}
