import { createTheme } from '@mui/material/styles';
import { darken, lighten } from 'polished';

export const DrawerWidth = 500;

export const Colors = {
  primary: '#6e2d91',
  secondary: '#FF6961',
  christmasGreen: '#146B3A',
  whatsappColor: '#25D366',
  christmasRed: '#BB2528',
  secondaryIcon: '#fff',
  success: '#4CAF50',
  info: '#00a2ff',
  danger: '#FF5722',
  warning: '#FFC107',
  dark: '#0e1b20',
  light: '#aaa',
  muted: '#abafb3',
  border: '#DDDFE1',
  inverse: '#2F3D4A',
  shaft: '#e0d6ff',
  pastel_yellow: '#FDFD96',
  pastel_green: '#77DD77',
  yellow_react: '#fdd835',
  red_react: '#D32F2F',
  blue_react: '#00e5ff',
  indigo_react: '#1A237E',
  bg_green: '#165816',
  //usaSymbol:'#BF0A30',
  usaSymbol: '#e18d96',
  very_light_gray: '#F2F2F2',
  pastel_purple: '#e0d6ff',
  pastel_red: '#FF6961',
  pastel_pink: '#F8C8DC',
  pastel_blue: '#A7C7E7',
  buttonFont: '#000',
  react_green: '#69f0ae',
  carousel_background: '#3feee6',
  christmas_hex_color: '#e2f481',
  //shaft: "#333",
  ///////////////
  // Grays
  ///////////////
  dim_grey: '#696969',
  dove_gray: '#d5d5d5',
  body_bg: '#f3f6f9',
  //light_gray: "rgb(230,230,230)",
  light_gray: '#ECECEC',

  ///////////////
  // Solid Color
  ///////////////
  white: '#fff',
  black: '#000',
};

const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
    },
    secondary: {
      main: Colors.secondary,
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          background: Colors.primary,
        },
        arrow: {
          color: Colors.primary,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          // width: DrawerWidth,
          background: Colors.white,
          color: Colors.black,
          borderRadius: '0px 100px 0px 0px',
          borderRight: `5px solid ${Colors.primary}`,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: lighten(0.2, Colors.primary),
        },
      },
    },
    MyShopButton: {
      styleOverrides: {
        root: {
          color: Colors.white,
        },
        primary: {
          background: Colors.primary,
          '&:hover': {
            background: lighten(0.05, Colors.primary),
          },
        },
        secondary: {
          background: `${Colors.secondary}`,
          '&:hover': {
            background: lighten(0.05, Colors.secondary),
          },
        },
      },
    },
  },
});
export default theme;
