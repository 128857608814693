import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useDialogModal from "../../hooks/useDialogModal";
import { clearCurrentPerson } from '../../store/actions/personAction';
import { Colors } from '../../styles/theme';
import Appbar from '../appbar';
import Footer from '../footer';
import OrderHistory from "../orderHistory";
import UpdatePassword from "../updatePassword";


import OrderHistoryEntity from '../../models/orderHistoryEntity';
import OrderService from '../../services/order.service';
import {
  ProfileContainer,
  ProfileContent,
  ProfileTitle
} from '../../styles/profile';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.pastel_red,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));





export default function Profile() {
 
  const currentPerson = useSelector((state) => state.person);
  //const [person, setPerson] = useState(new Person('','','','',''));

  const [person, setPerson] = useState();

  const[personOrder,setPersonOrder] =useState();

  const[partnerOrder,setPartnerOrder] =useState();

  const[privateAddressOrder,setPrivateAddressOrder] =useState();

  const [orderHistoryEntity,setOrderHistoryEntity] = useState(new OrderHistoryEntity('','',''));

  const [orderList,setOrderList] =useState([]);

  const [productsOrder,setProductsOrder] =useState([]);

  const [UpdatePasswordDialog, showUpdatePasswordDialog, closeUpdatePasswordDialog] =
    useDialogModal(UpdatePassword);

    const [OrderHistoryDialog, showOrderHistoryDialog, closeOrderHistoryDialog] =
    useDialogModal(OrderHistory);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchBusinesses = useCallback(() => {
    if (!currentPerson?.id) {
      navigate('/login');
    } else{
      const fetchData = async () =>{
        setLoading(true);
        OrderService.getOrderByPerson(currentPerson?.email).then((response) => {
     
          setOrderList(response.data);
       
        })
        .catch((error) => {
          tokenExpired();
         
        });
        setLoading(false);
      }
  
      fetchData();
    }
  }, [])

   //mounted
   useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    fetchBusinesses()
  }, [fetchBusinesses]);

  const logout = () => {
    dispatch(clearCurrentPerson());
    navigate('/home');
  };

  const tokenExpired = () => {
    dispatch(clearCurrentPerson());
    navigate('/login');
  };

  return (
    <>
      <Appbar />

      <Container component="main" maxWidth="lg">
        <CssBaseline />

        <ProfileContainer sx={{ mt: 4, mb: 10 }}>
          <ProfileContent>
            <ProfileTitle variant="h2">
              Salut, {currentPerson?.firstName}!
            </ProfileTitle>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <Typography  variant="h6">
                  {currentPerson?.firstName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography  variant="h6">
                  {currentPerson?.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography  variant="h6">
                  {currentPerson?.phoneNumber}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6">{currentPerson?.email}</Typography>
              </Grid>
              <Grid sx={{ mt: 1}} item xs={12}>
       
                <Button endIcon={<NavigateNextIcon/>} variant="text" onClick={() => [setPerson(currentPerson),showUpdatePasswordDialog()]}>Modifiez Votre Mot de Passe</Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  startIcon={<ExitToAppIcon sx={{ color: Colors.white }} />}
                  sx={{ mt: 4, mb: 4, borderRadius: '10px' }}
                  variant="contained"
                  onClick={() => {
                    logout();
                  }}>               
                  Se Déconnecter
                </Button>
              </Grid>
            </Grid>
          </ProfileContent>

          {orderList.length>0 && <ProfileContent>
        
        <Typography variant="h5">Historique des Commandes</Typography>
        <TableContainer component={Paper} >
          <Table  aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell >Commandes</StyledTableCell>
                <StyledTableCell align="right">Date</StyledTableCell>
                <StyledTableCell align="right">Paiement</StyledTableCell>
                <StyledTableCell align="right">Exécution</StyledTableCell>
                <StyledTableCell align="right">Total</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
          
            {( orderList
      ).map((row) => (
                <StyledTableRow key={row.orderCode}>
                  <StyledTableCell component="th" scope="row">
                    <Link   component="button"
                  variant="text" onClick={() => [setPrivateAddressOrder(row.userOrderAddress),setPartnerOrder(row.recupLocation),setPersonOrder(row.person),orderHistoryEntity.orderNumber=row.orderCode,orderHistoryEntity.orderDate=row.createdDate,orderHistoryEntity.orderTotal=row.someTotalOrder,setOrderHistoryEntity(orderHistoryEntity),setProductsOrder(row.products),showOrderHistoryDialog()]}>{row.orderCode} </Link>
                  </StyledTableCell>

                  <StyledTableCell align="right">
                    {new Date(row.createdDate).toLocaleString()}
                   
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.orderPhoneNumber}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.orderStatus.orderStatusName}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    {row.someTotalOrder.toLocaleString()}F
                  </StyledTableCell>
                </StyledTableRow>
              ))}
     
            </TableBody>
            <TableFooter >
      <TableRow >
  
      </TableRow>
    </TableFooter>
          </Table>
        </TableContainer>
   
        <OrderHistoryDialog orderHistoryEntity={orderHistoryEntity} orderlist={productsOrder} user={personOrder} recupPartner={partnerOrder} privateAddressOrder={privateAddressOrder}/>   
       
      </ProfileContent> }
          
        </ProfileContainer>
      </Container>
      <UpdatePasswordDialog person={person} />   
      <Footer />
    </>
  );
}
