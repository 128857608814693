import '@fontsource/aladin';
import { Button, CardActionArea, CardActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Colors } from '../../styles/theme';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useEffect, useState } from 'react';


import {
  HomePanelCardImage, HomePanelContainer,
  HomePanelContainerInner, HomePanelSubContainer
} from '../../styles/homePanel';

export default function HomePanel() {
  const navigate = useNavigate();


  const handleProduct = (category,subCategory,title) => {
    if(category==="maison" && subCategory==="interieur"){
      navigate("/house-in")
      return
    }
    if(category==="maison" && subCategory==="cuisine"){
      navigate("/house-kitchen")
      return
    }
     
  };

  function myCuisineText() {
    return "N'attendez plus, découvrez tous nos ustensiles de cuisine et électroménager pour vos besion quotident.".substring(0,102)+'...';
  }

  function myInterieurText() {
    return "Cherchez vous des idées pour l’aménagement de votre intérieur? Découvrez nos articles pour un aménagement intérieur réussi.".substring(0,102)+'...';
  }

  return (
    <div>
      <HomePanelContainer>
        <HomePanelSubContainer maxWidth="lg">
          <HomePanelContainerInner>
            <Card
              sx={{
                borderRadius: '10px',
                boxShadow: 3,
                ':hover': {
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
            >
              <CardActionArea>
                <CardMedia>
             
                  <HomePanelCardImage
                    src="/images/banner/maison4.png"
                    alt="house"
                    onClick={() => handleProduct('maison','interieur','Maison|Intérieur')} 
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    L' intérieur
                  </Typography>
                  <Typography variant="body1" >
                  {myInterieurText()}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
               onClick={() => handleProduct('maison','interieur','Maison|Intérieur')} 
                sx={{ mt: 4, mb: 4 , borderRadius: '10px'}}
                variant="contained"
                >
                  Achetez Maintenant
                </Button>
              </CardActions>
            </Card>
          </HomePanelContainerInner>

          <HomePanelContainerInner>
            <Card
              sx={{
               
                borderRadius: '10px',
                boxShadow: 3,

                ':hover': {
                  boxShadow: 6,
                  border: 1,
                  borderColor: Colors.primary,
                },
              }}
            >
              <CardActionArea>
                <CardMedia>
             
                  <HomePanelCardImage
                    src="/images/banner/plats-home1.png"
                    alt="house"
                    onClick={() => handleProduct('maison','cuisine','Maison|Cuisine')} 
                  />
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    La cuisine
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {myCuisineText()}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
               onClick={() => handleProduct('maison','cuisine','Maison|Cuisine')} 
              sx={{ mt: 4, mb: 4 , borderRadius: '10px'}}
              variant="contained"
                >
                  Achetez Maintenant
                </Button>
              </CardActions>
            </Card>
          </HomePanelContainerInner>
          
        </HomePanelSubContainer>
      </HomePanelContainer>
    </div>
  );
}
