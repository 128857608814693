import { styled } from '@mui/material/styles';
import { Box,Button,List, Typography } from '@mui/material';
import { Colors } from '../theme';
import "@fontsource/aladin";



export const BannerContainerCarousel = styled(Box)(({ matches, theme }) => ({
  justifyContent: "center",
  width: "500px",
//  height: "100%",

  [theme.breakpoints.down("sm")]: {
    width: "300px",
  },
  
  [theme.breakpoints.down("md")]: {
    width: "280px",
  },
  // backgroundImage: `url(/images/banner/banner.png)`,
  // backgroundRepeat: "no-repeat",
  // backgroundPosition: "center",
}));

export const BannerContainer = styled(Box)(({ matches, theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  padding: "0px 0px",
  flexDirection: "row",
  background: Colors.light_gray,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

}));

export const BannerContent = styled(Box)(({theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  maxWidth: 500,
  padding: "30px",
  
}));

export const BannerImage = styled("img")(({ src, theme }) => ({

  src: `url(${src})`,

  // backgroundImage: `url(${src})`,
   backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  width: "500px",
  height: "500px",
  [theme.breakpoints.down("md")]: {
    width: "500px",
    height: "450px",
  
  },
  [theme.breakpoints.down("sm")]: {
    width: "280px",
    height: "320px",
  },
}));
  

export const BannerTitle = styled(Typography)(({ matches, theme }) => ({
  lineHeight: 1.5,
  fontSize: "72px",
  marginBottom: "20px",
  fontFamily: '"Aladin", "cursive"',
  [theme.breakpoints.down('sm')]: {
    fontSize: '42px',    
  }
}));

export const BannerDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 1.25,
  marginBottom: "3em",
  [theme.breakpoints.down("md")]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    marginBottom: "1.5em",
  },
}));

export const BannerShopButton = styled(Button, {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) => prop !== "color",
  name: "MyShopButton",
  slot: "Root",
  // We are specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "primary" && styles.primary,
    props.color === "secondary" && styles.secondary,
  ],
})(({ theme }) => ({
  padding: "20px 0px",
  color: Colors.white,
  fontWeight: "bold",
  fontSize: "16px",
  borderRadius: '10px',
  [theme.breakpoints.down("sm")]: {
    padding: "10px 0px",
    fontSize: "14px",
    borderRadius: '10px',
  },
}));



