import { styled } from '@mui/material/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Colors } from '../theme';
import '@fontsource/aladin';

export const ElectroPanelContainer = styled(Box)(({ matches, theme }) => ({
  marginBottom: '20px',
  display: 'flex',
 justifyContent: 'center',
 alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: '0px 0px',

}));
export const ElectroPanelInnerContainer = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
 justifyContent: 'center',
  alignItems: 'center',
  width: '90%',
  height: '90%',
  [theme.breakpoints.down('sm')]: {
    width: '98%',
    height: '98%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [theme.breakpoints.down('md')]: {
    width: '98%',
    height: '98%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));
export const ElectroPanelContainerInner = styled(Box)(({ matches, theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  borderRadius: '10px',

}));
export const ElectroPanelContainerInnerBorder = styled(Box)(
  ({ matches, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    margin: '10px 10px',
  })
);

export const ElectroPanelImagePresa = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  width: '95%',
  height: '250px',
  borderRadius: '20% 50%',
  [theme.breakpoints.down('md')]: {
    width: '95%',
    height: '250px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '95%',
    height: '150px',
  },
}));
export const ElectroPanelImageBottom = styled('img')(({ src, theme }) => ({
  src: `url(${src})`,
  width: '50%',
  height: '150px',

  [theme.breakpoints.down('md')]: {
    width: '50%',
    height: '150px',
  },

  [theme.breakpoints.down('sm')]: {
    width: '50%',
    height: '100px',
  },
}));

export const ElectroPanelTitle = styled(Typography)(({ matches, theme }) => ({
  lineHeight: 1.1,
  fontSize: '25px',
  marginTop: '0.5em',
  marginBottom: '0.5em',
 
}));

export const ElectroPanelPrice = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  letterSpacing: 2.25,
  fontSize: '40px',
  color: Colors.primary,
  fontFamily: '"Aladin", "cursive"',
  [theme.breakpoints.down('md')]: {
    lineHeight: 1.15,
    letterSpacing: 1.15,
    fontSize: '30px',
  },
}));
