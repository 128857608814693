import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Colors } from '../../styles/theme';
import { borders } from '@mui/system';

import {
  BannerContainer,
  BannerContainerCarousel,
  BannerContent,
  BannerDescription,
  BannerImage,
  BannerShopButton,
  BannerTitle,
} from '../../styles/banner';

export default function Banner() {
  const theme = useTheme();
  const imageURL =
    'https://s3.amazonaws.com/www.237usa.com/images/products/chrismas-frame-border-4.png';

  const navigate = useNavigate();
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,

    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  var items = [
    {
      image: '/images/products/banner-jeans-1-removebg-preview.png',
      title: 'US Jeans',
    },
    {
      image: '/images/products/banner-jeans-2-removebg-preview.png',
      title: 'US Jeans',
    },
    {
      image: '/images/products/banner-jeans-3-removebg-preview.png',
      title: 'US Jeans',
    },
    {
      image: '/images/products/banner-jeans-4-removebg-preview.png',
      title: 'US Jeans',
    },
    {
      image: '/images/products/banner-jeans-5-removebg-preview.png',
      title: 'US Jeans',
    },
  ];

  const handleProduct = () => {
    navigate('/men-jeans-pants');
  };

  return (
    <div>
      <BannerContainer
        sx={{
          background: Colors.christmas_hex_color,
          borderBottom: 10,
          borderTop: 10,
          //borderLeft: 10,
          //borderRight: 10,
          borderImageSource: `url(${imageURL})`,
          borderStyle: 'dotted',
          borderImageRepeat: 'repeat',
          borderImageSlice: 100,
        }}
      >
        <BannerContainerCarousel>
          <Slider ref={setSliderRef} {...sliderSettings}>
            {items.map((card, index) => (
              <div key={index}>
                <BannerImage src={card.image} onClick={() => handleProduct()} />
              </div>
            ))}
          </Slider>
        </BannerContainerCarousel>
        <BannerContent>
          <Typography variant="h6">Bonne fête</Typography>
          <BannerTitle sx={{ color: Colors.christmasRed }} variant="h2">
            US Jeans
          </BannerTitle>
          <BannerDescription variant="subtitle">
            Découvrez nos jeans pour ces fêtes de fin d'année 2023. AKADEMIKS,
            SOLUTUS, M. SOCIETY, ENCRYPTED, ROCAWEAR, SOUTH POLE, ECKO.
          </BannerDescription>
          <BannerShopButton
            onClick={() => handleProduct()}
            sx={{ backgroundColor: Colors.christmasRed }}
          >
            Achetez Maintenant
          </BannerShopButton>
        </BannerContent>
      </BannerContainer>
    </div>
  );
}
