import { BASE_API_URL } from '../common/Constants';
import axios from 'axios';
import { authHeader } from './base.service';


const BASE_URL = BASE_API_URL + '/api/recup';

class RecupService {

   

  listRecup() {
      return axios.get(BASE_URL + '/all-recup', {headers: authHeader()});
  }

  

}

export default new RecupService();