import { useTheme } from '@mui/material/styles';
import { Colors } from '../../styles/theme';
import { Typography, Divider,Button  } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import StoreIcon from '@mui/icons-material/Store';

import { Link as RouterLink, MemoryRouter } from 'react-router-dom';
import Footer from '../footer';
import Appbar from '../appbar';
import {
  UnauthorizedContainer,
  UnauthorizedInnerContainer,
  UnauthorizedContent,
  UnauthorizedDescription,
  UnauthorizedImage,
} from '../../styles/unauthorized';

export default function Unauthorized() {
  const theme = useTheme();

  // const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Appbar />
      <UnauthorizedContainer sx={{marginBottom: 30,
  marginTop: 10}}>
        <UnauthorizedInnerContainer>
          <UnauthorizedImage src="/images/banner/401.png"/>
          <UnauthorizedContent>
            <UnauthorizedDescription variant="subtitle">
            Non Autorisé! L'accès à cette ressource est refusé.
            </UnauthorizedDescription>
            <Button  component={RouterLink} to="/">
            Retour à la page d'accueil
            </Button>
          </UnauthorizedContent>
        </UnauthorizedInnerContainer>
      </UnauthorizedContainer>
      <Footer />
    </>
  );
}
