export default class Order{
  constructor(deliveryType,personEmail,partnerCity,partnerLocation,partnerPhoneNumber,discountCode,userPaymentPhoneNumber,userOrderAddress,products){
  this.deliveryType=deliveryType;
  this.personEmail=personEmail;
  this.partnerCity=partnerCity;
  this.partnerLocation=partnerLocation;
  this.discountCode=discountCode;
  this.partnerPhoneNumber=partnerPhoneNumber;
  this.products=products;
  this.userPaymentPhoneNumber=userPaymentPhoneNumber;
  this.userOrderAddress=userOrderAddress;
  }
  }