import { CLEAR_CURRENT_PERSON,SET_CURRENT_PERSON } from './action-types/person-action'

export const setCurrentPerson = (person) => {
    return {
        type: SET_CURRENT_PERSON,
        payload: person,
    };
};
export const clearCurrentPerson = () => {
    return {
        type: CLEAR_CURRENT_PERSON,
    };
};